<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">{{shortStr(emailData.subject,0,100)}}</h3> 
                        <p><strong>Sent By :</strong> {{emailData.added_by}}. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-8 sm:col-12 md:col-8">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                    <h5><strong>Subject</strong></h5> 
                                    <div class="full-width" style="font-size:12px;">{{emailData.subject}}</div>
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                    <h5><strong>Send To</strong></h5> 
                                    <div class="full-width" style="font-size:12px;">{{emailData.send_to}}</div>
                                </div>
                                <div v-if="emailData.cc_to" class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                    <h5><strong>CC</strong></h5> 
                                    <div class="full-width" style="font-size:12px;">
                                        <span v-for="(email,key) in emailData.cc_to" :key="key"><span v-if="key > 0"> , </span>{{email}}</span>
                                    </div>
                                </div>
                                <div v-if="emailData.bcc_to" class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                    <h5><strong>BCC</strong></h5> 
                                    <div class="full-width" style="font-size:12px;">{{emailData.bcc_to}}</div>
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                    <h5><strong>Message</strong></h5> 
                                    <div class="full-width" v-html="emailData.message"></div>
                                </div>  
                                <div v-if="emailData.attachments" class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                    <div v-for="(attachment,key) in emailData.attachments" :key="key" class="col-12 lg:col-3 sm:col-6 mb-2"  style="float:left">
                                        <a :href="attachment.path" target="_blank">
                                            <div v-if="images.includes(attachment.path.split('.')[attachment.path.split('.').length-1])">
                                                <div style="width:100px; height:100px;"><span style=" font-size:100px; color:#ccc;" class="pi pi-image"></span></div>
                                                <div style="word-break: break-all;"><span>{{attachment.path.split('/')[attachment.path.split('/').length-1]}}</span></div>
                                            </div>
                                            <div v-else-if="files.includes(attachment.path.split('.')[attachment.path.split('.').length-1])">
                                                <div style="width:100px; height:100px;"><span style=" font-size:100px; color:#ccc;" class="pi pi-file"></span></div>
                                                <div style="word-break: break-all;"><span>{{attachment.path.split('/')[attachment.path.split('/').length-1]}}</span></div>
                                            </div>
                                            <div v-else-if="attachment.path.split('.')[attachment.path.split('.').length-1] == 'pdf'">
                                                <div style="width:100px; height:100px;"><span style=" font-size:100px; color:#ccc;" class="pi pi-file-pdf"></span></div>
                                                <div style="word-break: break-all;"><span>{{attachment.path.split('/')[attachment.path.split('/').length-1]}}</span></div>
                                            </div>
                                            <div v-else>
                                                <div style="width:100px; height:100px;"><span style=" font-size:100px; color:#ccc;" class="pi pi-file"></span></div>
                                                <div style="word-break: break-all;"><span>{{attachment.path.split('/')[attachment.path.split('/').length-1]}}</span></div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>  
                        </div> 
                        <div class="col-12 lg:col-4 sm:col-12 md:col-4">
                            <div>
                                <Panel class="mb-3 full-width" header="Email Logs" :toggleable="true"  style="float:left" >   
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                        <h5><strong>Sent By</strong></h5> 
                                        <div class="full-width">{{emailData.added_by}}</div>
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                        <h5><strong>Send Time</strong></h5> 
                                        <div class="full-width">{{formatDate(emailData.date_added)}}</div>
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                        <h5><strong>From Name</strong></h5> 
                                        <div class="full-width">{{emailData.from_name}}</div>
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                        <h5><strong>From Email</strong></h5> 
                                        <div class="full-width">{{emailData.from_email}}</div>
                                    </div> 
                                </Panel> 
                            </div>  
                        </div>  
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <!-- <Button v-if="checkPermission(moduleId,'delete')" type="submit" label="Delete and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/> -->
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
        </Sidebar> 
    </BlockUI>
    
</template>
<script>  

export default {
        data() {
                return {   
                    module_name:'Order',  
                    isSaving:false,  
                    saveAndClose:false, 
                    recId:0,
                    loadingComponent:true,
                    formVisible:false,  
                    emailData:{},
                    moduleId:44, 
                    images:['webp','jpg','png','gif','jpeg'],
                    files:['doc','xls','xlsx','docx','txt'],
                    
                }
        }, 
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
            orderId :{
                type : Number,
            },
        },
		created() {   
		},
        computed: { 
        },
        components:{ 
        },
		mounted() {   
            this.formVisible = this.showForm; 
            this.recId = this.recordId;   
            this.getAllRequestData();  
		},
		methods: {  
            onReady( editor )  {
                // Insert the toolbar before the editable area.
                editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                );
            }, 
             getAllRequestData(){   
                this.$axios.all([this.updateRecord()])    
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            }, 
            
            resetForm(){
                this.form.reset();
            },
          
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            }, 
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getAllOrderEmails',{'id': this.recId})
                    .then( (response) => {  
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            if(response.data[0].attachments){
                                response.data[0].attachments = JSON.parse(response.data[0].attachments); 
                            } 
                            if(response.data[0].cc_to){
                                response.data[0].cc_to = JSON.parse(response.data[0].cc_to);
                            }
                            if(response.data[0].bcc_to){  
                                response.data[0].bcc_to = JSON.parse(response.data[0].bcc_to);  
                            }
                            Vthis.emailData = response.data[0];
                        }

                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },   
            
            
		},
        watch : {
             
        }
}
</script>
 
