<template>
    <div class="grid">  
		<div class="col-12"> 
			<div class="card"> 
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start> 
                        <h3>Manage {{module_name}}</h3>  
                    </template>
					<template v-slot:end>  
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" /> 
                    </template> 
				</Toolbar> 
                
                <!-- <Toolbar class="pl-0 pr-0">
					<template v-slot:start> 
                        <Button v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Add New'" :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" /> 
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" /> 
                        <Button v-if="checkPermission(moduleId,'export')" v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSV($event)" />
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Remove Filters'" type="button" icon="pi pi-filter-slash" :label="showBtnLabels ? 'Remove Filters' : ''" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  @click="clearFilter()"/> 
                        <Button v-if="checkPermission(moduleId,'delete')" v-tooltip.top="'Delete Selected'" :label="showBtnLabels ? 'Delete Selected' : ''" icon="pi pi-trash" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-danger mr-2"  @click="deleteRecord" :disabled="!selectedRecords || !selectedRecords.length" />
					</template> 
                    <template v-slot:end> 
                        <Button  v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Helping Material'" :label="showBtnLabels ? 'Help' : ''" @click="showHelp = true" icon="pi pi-question" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  /> 
                    </template> 
				</Toolbar>  -->
				<DataTable :reorderableColumns="true" @columnReorder="onColReorder" :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="records" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters" filterDisplay="menu" :loading="loading" :filters="filters" responsiveLayout="scroll"
					:globalFilterFields="['invoice_number','start_time','end_time', 'is_canceled','is_traveled','added_by', 'date_added', ]" :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords" selectionMode="multiple"
                    :scrollable="true" scrollHeight="400px">
                    
					<template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">  
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                            </span>
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                placeholder="Select Columns" style="width: 20em"/>
                        </div>
                    </template>
                    
                    <template #empty> 
                        <div class="table-message"> 
                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                        </div>
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>  
                    <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                    <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;':'max-width:5rem;flex-grow:1; flex-basis:160px;'"> 
                        <template  #body="{data}">
                            <img  v-if="col.field == 'company' && data.logo" :alt="data.company" :src="filesUrl+'companies/'+data.logo" class="tableProfileImg mr-2" />
                            <img  v-if="col.field == 'company' && !data.logo" :alt="data.company" :src="filesUrl+'defaults/default-logo.png'" class="tableProfileImg mr-2" />
                            <span v-if="col.field == 'company'">{{data.company}}</span>
                            <span v-if="col.field == 'invoice_number'">{{data.invoice_number}}
                                <Tag v-if="data.invoice_status == 'Canceled'" severity="danger">Canceled</Tag>
                                <Tag v-else-if="data.invoice_status" severity="info">{{data.invoice_status}}</Tag>
                            </span>   
                            <span v-if="col.field == 'traveling_status'">
                                <Tag v-if="data.traveling_status == 'Canceled'" severity="danger">Canceled</Tag>
                                <Tag v-else-if="data.traveling_status == 'Pending'" severity="info">Pending</Tag>
                                <Tag v-else severity="success">{{data.traveling_status}}</Tag>
                            </span> 
                            <span v-if="col.field == 'start_time'">{{formatDateTime(data.start_time)}}</span> 
                            <span v-if="col.field == 'end_time'"><span v-if="data.end_time">{{formatDateTime(data.end_time)}}</span><span v-else>N/A</span></span>   
                            <span v-else-if="col.field == 'date_added'">{{formatDate(data.date_added)}}</span>
                            <span v-else-if="col.field == 'added_by'">{{ data.added_by }}</span>
                             
                            <span v-else-if="col.field == 'action'">
                                <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                    <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                </div> 
                            </span>
                        </template>
                        <template #filter="{filterModel}">
                            <span v-if="col.field == 'company'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by company name"/>
                            </span> 
                            <span v-if="col.field == 'invoice_number'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Invoice Number"/>
                            </span>   
                            <span v-if="col.field == 'start_time'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Start Time"/>
                            </span>   
                             <span v-if="col.field == 'end_time'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by End Time"/>
                            </span>   
                            <span v-else-if="col.field == 'is_canceled'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Cancelation Status"/>
                            </span> 
                            <span v-else-if="col.field == 'is_traveled'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Traveling Status"/>
                            </span> 
                            <span v-if="col.field == 'added_by'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Sender"/>
                            </span>  
                            <span v-else-if="col.field == 'date_added'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Sent Date"/>
                            </span> 
                        </template>   
                    </Column>   
				</DataTable>
			</div>
		</div>
        <Sidebar v-model:visible="showStatusChangedForm" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="true" @hide="showStatusChangedForm=false;removeViewFromURL('change-schedule-status');" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form  @submit.prevent="login" @keydown="orderTravelingStatusForm.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Order#{{orderId}}</h3> 
                        <p>Update traveling status with reason </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                   <h4>Traveling status of this schedule is being changed to <span style="color:red">"{{orderTravelingStatusForm.traveling_status}}"</span></h4>
                                </div>  
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><h5>Status Change Description</h5></label>
                                    <Textarea placeholder="Description" v-model="orderTravelingStatusForm.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="orderTravelingStatusForm" field="description" />
                                </div>  
                            </div>  
                        </div>  
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Change Status" :loading="isStatusSaving" icon="pi pi-check" @click="changeOrderTravelingStatus()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" :loading="isStatusSaving" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="showStatusChangedForm=false;removeViewFromURL('change-schedule-status');"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
        <Toast />
        <ConfirmDialog group="dialog" />
        <!-- form loading -->
        <ModuleHelp v-on:closeHelp="showHelp =false" v-if="showHelp  && checkPermission(moduleId,'view')" :moduleId="moduleId"/>
        
    </div>
</template>
<script> 
import {FilterMatchMode,FilterOperator} from 'primevue/api';  
export default {
    data() {
			return { 
                orderTravelingStatusForm: new this.Form({ 
                    id:0,
                    description:'',    
                    traveling_status:'',
                }),
                module_name:'Order Traveling', 
                actions: [], 
                order_id:0,
                moduleId:54,
                showHelp : false, 
                isSaving:false, 
				records: null, 
				filters: null, 
				loading: true,  
				selectedRecords: null,
				columns: null,
                selectedColumns: null, 
                showForm:false, 
                selectedRecordId: 0,  
                isStatusSaving:false,
                saveAndClose:false, 
                showStatusChangedForm:false,
			}
		}, 
        props:{ 
            orderId :{
                type : Number,
            },
        }, 
        components:{
            
        },
		created() { 
            if(this.checkPermission(this.moduleId,'update')){
                // this.actions.push({
				// 		label: 'Change Invoice',
				// 		icon: 'pi pi-ticket',
                //         command: () => {
                //             this.getClickedRecord('change-invoice');
                //         }
				// 	});
                this.actions.push({
						label: 'Completed',
						icon: 'pi pi-check',
                        command: () => {
                            this.getClickedRecord('completed');
                        }
					});
                this.actions.push({
						label: 'Pending',
						icon: 'pi pi-eye-slash',
                        command: () => {
                            this.getClickedRecord('pending');
                        }
					});
                this.actions.push({
						label: 'Canceled',
						icon: 'p-menuitem-icon pi pi-times',
                        command: () => {
                            this.getClickedRecord('canceled');
                        }
					});
                
            }
            // if(this.checkPermission(this.moduleId,'delete')){
            //     this.actions.push({
			// 			label: 'Delete',
			// 			icon: 'pi pi-times',
            //             command: () => {
            //                 this.getClickedRecord('delete');
            //             }
			// 		});
            // }   
			this.initFilters();
            this.columns = [
                {field: 'action', header: 'Action'},
                {field: 'company', header: 'Company'},  
                {field: 'invoice_number', header: 'Invoice#'},   
                {field: 'start_time', header: 'Start Time'},   
                {field: 'end_time', header: 'End Time'}, 
                {field: 'traveling_status', header: 'Traveling Status'}, 
                {field: 'date_added', header: 'Added Date'},
                {field: 'added_by', header: 'Added By'},  
            ];
            this.selectedColumns = this.columns;
		},
        computed: { 
        },
		mounted() { 

            //THIS CODE IS TO TABLE COLUMNS SETTINGS
            this.axios.post('getAllModuleColumnSettings', { 'module_id': this.moduleId })
            .then((response) => {
                if(response.data){
                    if(Object.keys(response.data).length){
                        this.columns =[]; 
                        this.selectedColumns = [];
                        response.data.forEach( (column) => { 
                            this.columns.push({'field':column.column_field, 'header': column.column_head});
                            if(column.display){
                                this.selectedColumns.push({'field':column.column_field, 'header': column.column_head});
                            } 
                        }); 
                    }
                }
            })
            .catch(() => {
            })
            .finally(() => {   
            })
            //END

            this.order_id = this.orderId;
			this.getRecords();  
            if(this.$route.query.view){
                this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
                if(Object.keys(this.urlParams).length){
                    this.urlParams.forEach( (parameter) => {
                        if(parameter.view == 'add-schedule'){
                            this.selectedRecordId = parameter.id;
                            this.showForm = true;
                        }  
                        else if(parameter.view == 'change-schedule-status'){ 
                            this.selectedRecordId = parameter.id;
                            this.orderTravelingStatusForm.reset();
                            this.showStatusChangedForm = true; 
                            this.orderTravelingStatusForm.traveling_status = parameter.schedule_traveling_status; 
                        } 
                    })
                }
            }
		},
		methods: {
            onColReorder(event){ 
                this.columns = this.tableColumnOrderChange(event,this.columns);  
                this.saveTableColumnOrderChange(this.moduleId,this.columns,this.selectedColumns);
            },
            onToggle(value) {  
                //this.selectedColumns = this.columns.filter(col => value.includes(col)); 
                this.selectedColumns = []; 
                let newColumns = []; 
                this.columns.filter((col) => {
                    if(value.find(x => x.field === col.field)){
                        newColumns.push(col);
                        return col;
                    } 
                }); 
                this.selectedColumns = newColumns; 
                this.saveTableColumnOrderChange(this.moduleId,this.columns,this.selectedColumns);
            },   
            getClickedRecord(action){
                if(action == 'delete'){ 
                    this.deleteRecord(); 
                }
                else if(action == 'canceled'){
                    this.orderTravelingStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.orderTravelingStatusForm.traveling_status = 'Canceled';
                    this.addViewInURL({view: 'change-schedule-status', id: this.selectedRecordId, schedule_traveling_status:'Canceled'}); 
                    
                }
                else if(action == 'completed'){ 
                    this.orderTravelingStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.orderTravelingStatusForm.traveling_status = 'Completed'; 
                    this.addViewInURL({view: 'change-schedule-status', id: this.selectedRecordId, schedule_traveling_status:'Completed'});
                }
                else if(action == 'pending'){ 
                    this.orderTravelingStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.orderTravelingStatusForm.traveling_status = 'Pending'; 
                    this.addViewInURL({view: 'change-schedule-status', id: this.selectedRecordId, schedule_traveling_status:'Pending'});
                }
                else if(action == 'view'){
                    this.showViewForm = true; 
                }  
                else if(action == 'update'){
                    this.showForm = true; 
                }
            },
            async changeOrderTravelingStatus(){    
                var Vthis = this; 
                Vthis.orderTravelingStatusForm.id = Vthis.selectedRecordId;
                this.isStatusSaving = true;
                await this.orderTravelingStatusForm
                .post(Vthis.$baseurl+'api/changeTravelingStatus', Vthis.orderTravelingStatusForm)
                .then((response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        // this.form.reset();    
                        Vthis.showMessage('Saved successfully','success'); 
                        this.showStatusChangedForm = false;
                        this.getRecords();
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isStatusSaving = false; 
                }) 
            },   
            toggleRecordMenu(event) {
                this.selectedRecordId = event.currentTarget.getAttribute('recordId');  
				this.$refs.menu.toggle(event);
			},
            loadForm(){ 
                this.selectedRecordId = 0;
                this.showForm = true;
                this.addViewInURL({view: 'add-schedule', id: this.selectedRecordId});
            },
            closeForm(){ 
                if(!this.selectedRecordId){
                    this.getRecords();
                }
                this.showForm = false; 
                this.removeViewFromURL('add-schedule');
            }, 
            getRecords(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllOrderTravelings',{'order_id': this.orderId})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){
                        this.records = response.data;   
                        this.loading = false;
                    } 
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            }, 
             
            // deleteRecord(){ 
            //     var Vthis = this;
            //     this.$confirm.require({
            //         group: 'dialog',
            //         header: 'Confirmation',
            //         message: 'Are you sure you want to delete?',
            //         icon: 'pi pi-exclamation-triangle',
            //         accept: () => { 
            //             Vthis.loading = true;  
            //             let DeleteAbleRecords = [];
                         
            //             if(Vthis.selectedRecords){
            //                 Vthis.selectedRecordId = '';
            //                 Vthis.selectedRecords.forEach((data) => {
            //                     DeleteAbleRecords.push(data.id);
            //                 })
            //             } 
            //             else{
            //                 DeleteAbleRecords.push(Vthis.selectedRecordId);
            //             } 
                         
            //             // let filterdRecords = Vthis.records.filter(val => Vthis.selectedRecords.includes(val)); 
            //             // filterdRecords.forEach((data) => {
            //             //     DeleteAbleRecords.push(data.id);
            //             // })  

            //             Vthis.axios.post('deleteOrderEmail',{id:DeleteAbleRecords})
            //             .then( () => {   
            //                 Vthis.getRecords();
            //                 Vthis.showMessage('Record deleted successfully','success'); 
            //             })
            //             .catch((error) => {   
            //                 let message = Vthis.customError(error.response ); 
            //                 Vthis.showMessage(message,'error'); 
            //             })
            //             .finally(() => { 
            //                 Vthis.loading = false;
            //             })
            //         },
            //         reject: () => {
            //             // this.loading = false;
            //         }
            //     }); 
            // },
            exportCSV() {
                this.$refs.dt.exportCSV();
            },
            
            
            initFilters() {
				this.filters = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'invoice_number': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'start_time': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'end_time': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'traveling_status': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'company': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'added_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
				 	'date_added': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}, 
				}
			},
			clearFilter() {
				this.initFilters();
			}, 
            isRTL() {
				return this.$appState.RTL;
			}
		},
        watch : { 
        }
}
</script>
 
