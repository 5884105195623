<template>
    <div class="grid">  
		<div class="col-12"> 
			<div class="card"> 
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start> 
                        <h3>Manage {{module_name}}</h3>  
                    </template>
					<template v-slot:end>
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" />   
                        <Button v-if="checkPermission(moduleId,'insert') && !checkUserCoreRole(['Customer'])" v-tooltip.top="'Generate New'" :label="showBtnLabels ? 'Generate New' : ''" @click="loadForm" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" />  
                    </template> 
				</Toolbar>  
                <!-- <Toolbar class="pl-0 pr-0">
					<template v-slot:start> 
                        <Button v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Add New'" :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" /> 
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" /> 
                        <Button v-if="checkPermission(moduleId,'export')" v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSV($event)" />
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Remove Filters'" type="button" icon="pi pi-filter-slash" :label="showBtnLabels ? 'Remove Filters' : ''" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  @click="clearFilter()"/> 
                        <Button v-if="checkPermission(moduleId,'delete')" v-tooltip.top="'Delete Selected'" :label="showBtnLabels ? 'Delete Selected' : ''" icon="pi pi-trash" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-danger mr-2"  @click="deleteRecord" :disabled="!selectedRecords || !selectedRecords.length" />
					</template> 
                    <template v-slot:end> 
                        <Button  v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Helping Material'" :label="showBtnLabels ? 'Help' : ''" @click="showHelp = true" icon="pi pi-question" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  /> 
                    </template> 
				</Toolbar>  -->
				<DataTable :reorderableColumns="true" @columnReorder="onColReorder" :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="records" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters" filterDisplay="menu" :loading="loading" :filters="filters" responsiveLayout="scroll"
					:globalFilterFields="['name','company', 'quotation_number','amount','sent_date','quotation_date','due_date','quotation_status']" :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords" selectionMode="multiple"
                    :scrollable="true" scrollHeight="400px"> 
					<template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">  
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                            </span>
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                placeholder="Select Columns" style="width: 20em"/>
                        </div>
                    </template> 
                    <template #empty> 
                        <div class="table-message"> 
                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                        </div>
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template> 
                    
                    <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                    <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;':'max-width:5rem;flex-grow:1; flex-basis:160px;'"> 
                        <template  #body="{data}">
                            <img  v-if="col.field == 'company' && data.logo" :alt="data.company" :src="filesUrl+'companies/'+data.logo" class="tableProfileImg mr-2" />
                            <img  v-if="col.field == 'company' && !data.logo" :alt="data.company" :src="filesUrl+'defaults/default-logo.png'" class="tableProfileImg mr-2" />
                            <span v-if="col.field == 'company'">{{data.company}}</span>
                            <span v-if="col.field == 'quotation_number'">{{data.quotation_number}}</span> 
                            <span v-if="col.field == 'amount'">
                                <span v-if="data.amount">{{data.currency ? data.currency : currency}} {{Number(data.amount).toFixed(priceRounding)}}</span>
                                <span v-else>{{data.currency ? data.currency : currency}} 0.00</span>
                            </span>  
                            <span v-if="col.field == 'due_date'">{{formatDate(data.due_date)}}</span> 
                            <span v-if="col.field == 'quotation_date'">{{formatDate(data.quotation_date)}}</span> 
                            <span v-if="col.field == 'sent_date'">
                                <span v-if="data.sent_date">{{formatDate(data.sent_date)}}</span>
                                <span v-else>
                                     <Tag :class="'mr-2 status-Blocked'" icon="pi pi-check" :severity="'danger'" :value="'Not Sent'"></Tag>
                                </span>
                            </span> 
                            <span v-if="col.field == 'id'"> 
                                <Button label="Download Quotation" class="p-button-raised p-button-secondary" :loading="downloadingQuotation[data.id]" @click="downloadQuotation(data.id)"/>
                            </span> 
                            <span v-if="col.field == 'quotation_status'">
                                <Tag class="mr-2" :icon="quotationStatuses[data.quotation_status].icon" :severity="quotationStatuses[data.quotation_status].color" :value="data.quotation_status"></Tag>
                                <!-- <Chip severity="success" label="Action" :class="'p-button-raised p-button-'+quotationStatuses[data.quotation_status].color+' mr-2 mb-2'"/> -->
                                <!-- <SplitButton :quotationId="data.id" @click="changeSelectedQuotationId($event)" :label="data.quotation_status" :icon="quotationStatuses[data.quotation_status].icon" :model="quotationAvailableStatuses" :class="'p-button-raised p-button-'+quotationStatuses[data.quotation_status].color+' mr-2 mb-2'"></SplitButton> -->
                            </span>  
                            
                            <span v-else-if="col.field == 'action'">
                                <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                    <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                </div>  
                            </span>
                        </template>
                        <template #filter="{filterModel}">
                            <span v-if="col.field == 'company'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by company name"/>
                            </span> 
                            <span v-if="col.field == 'quotation_number'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Quotation Number"/>
                            </span>   
                            <span v-else-if="col.field == 'amount'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Amount"/>
                            </span> 
                            <span v-if="col.field == 'quotation_date'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search Quotation Date"/>
                            </span> 
                            <span v-if="col.field == 'due_date'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search Due Date"/>
                            </span> 
                            <span v-if="col.field == 'sent_date'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search Sent Date"/>
                            </span>  
                            <span v-else-if="col.field == 'status'">
                                <Dropdown v-model="filterModel.value" :options="quotationStatuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                    <template #value="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                        <span v-else>{{slotProps.placeholder}}</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                    </template>
                                </Dropdown>
                            </span>  
                        </template>   
                    </Column>   
				</DataTable>
			</div>
		</div>
        <Sidebar v-model:visible="showStatusChangedForm" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="true" @hide="showStatusChangedForm=false;removeViewFromURL('change-order-quotation-status');" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form  @submit.prevent="login" @keydown="quotationStatusForm.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Quotation#{{selectedRecordId}}</h3> 
                        <p>Update quotation status with reason </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                   <h4>Quotation status is being changed to <span style="color:red">"{{quotationStatusForm.status}}"</span></h4>
                                </div>  
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><h5>Status Change Description</h5></label>
                                    <Textarea placeholder="Description" v-model="quotationStatusForm.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="quotationStatusForm" field="description" />
                                </div>  
                            </div>  
                        </div>  
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Change Status" :loading="isStatusSaving" icon="pi pi-check" @click="changeOrderQuotationStatus()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" :loading="isStatusSaving" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="showStatusChangedForm=false;removeViewFromURL('change-order-quotation-status');"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
         
        <Toast />
        <ConfirmDialog group="dialog" />
        <ConfirmDialog group="dialog1" ></ConfirmDialog>
        <ConfirmDialog group="dialog2" ></ConfirmDialog>
        <!-- form loading -->
        <OrderInvoiceForm v-on:formClose="showInvoiceForm=false;removeViewFromURL('send-invoice');" v-if="showInvoiceForm  && (permissions[43].includes('insert') || permissions[43].includes('update'))" :orderId="orderId" :quotationId="selectedRecordId" :showForm="true" /> 
        <OrderQuotationForm v-on:formClose="closeForm" v-if="showForm  && (checkPermission(moduleId,'insert') || checkPermission(moduleId,'update'))" :orderId="orderId" :recordId="selectedRecordId" :showForm="true" /> 
        <ModuleHelp v-on:closeHelp="showHelp =false" v-if="showHelp  && checkPermission(moduleId,'view')" :moduleId="moduleId"/>
        <UserTransections v-on:closeTransectionPage="closeTransectionPage" v-if="showTransectionPage  && (permissions[65] && permissions[65].includes('view') )" :user_id=" orderUserData.user_id" :showTransectionSidebar="true" /> 
        <QuotationHistory v-on:closeHistoryPage="showHistoryPage=false;removeViewFromURL('quotation-history');" v-if="showHistoryPage  && (permissions[52] && permissions[52].includes('view') )" :quotation_id="selectedRecordId" :showHistorySidebar="true" /> 
    </div>
</template>
<script>
 
import UserTransections from '../AccessManagement/UserTransections.vue';
import QuotationHistory from '../Orders/QuotationHistory.vue'; 
import OrderInvoiceForm from '../../components/Orders/OrderInvoiceForm.vue'; 
import OrderQuotationForm from '../../components/Orders/OrderQuotationForm.vue'; 
import {FilterMatchMode,FilterOperator} from 'primevue/api';  
export default {
    data() {
			return {  
                quotationStatusForm: new this.Form({ 
                    id:0,
                    description:'',    
                    status:'',
                    quotation_for_id:0,
                    quotation_for:'order'
                }), 
                module_name:'Order Quotations', 
                priceRounding:2,
                actions: [], 
                moduleId:51,
                showInvoiceForm:false,
                showHistoryPage:false,
                showHelp : false, 
                isSaving:false, 
                isStatusSaving:false,
				records: null, 
				filters: null, 
				loading: true,  
				selectedRecords: null,
				columns: null,
                selectedColumns: null, 
                showForm:false,
                selectedRecordId: 0,  
                totalPaid:0,
                saveAndClose:false,  
                savingQuotationPayment:false,
                showTransectionPage:false,
                quotationStatuses: [],
                downloadingQuotation:[],
                quotationPayments:[],
                quotationData:[],
                orderUserData:[],
                showStatusChangedForm:false,
                showQuotationPaymentForm:false,
                selectedPaymentId:0,
                currency: this.userData['company_currency_code'] ? this.userData['company_currency_code'] : 'USD',
                distanceUnit: this.userData['company_distance_unit_code'] ? this.userData['company_distance_unit_code'] : 'km',
			}
		}, 
        props:{ 
            orderId :{
                type : Number,
            },
        }, 
        components:{
            OrderQuotationForm, 
            UserTransections,
            OrderInvoiceForm,
            QuotationHistory
        },
		created() {   
            if(this.permissions[this.moduleId] && this.checkPermission(this.moduleId,'update')){
                this.actions.push(
                    {label:'Pending', icon:'pi pi-eye-slash',command: () => { this.changeQuotationStatus('Pending'); }},
                    {label:'Seen', icon:'pi pi-eye',command: () => { this.changeQuotationStatus('Seen'); }},
                    {label:'Accepted', icon:'pi pi-check',command: () => { this.changeQuotationStatus('Accepted'); }},
                    {label:'Canceled', icon:'pi pi-times',command: () => { this.changeQuotationStatus('Canceled'); }},
                    {separator:true},
                    {label:'Resend Email', icon:'pi pi-envelope',command: () => { this.changeQuotationStatus('ResendMail'); }},
                    {label:'Create Copy', icon:'pi pi-copy',command: () => { this.changeQuotationStatus('Copy'); }},
                    {separator:true},
                    {label:'Send as invoice', icon:'pi pi-money-bill',command: () => { this.changeQuotationStatus('SendInvoice'); }},
                    {separator:true},
                    {label:'View History', icon:'pi pi-history',command: () => { this.changeQuotationStatus('ViewHistory'); }},
                );
            }
            // if(this.checkPermission(this.moduleId,'delete')){
            //     this.actions.push({
			// 			label: 'Delete',
			// 			icon: 'pi pi-times',
            //             command: () => {
            //                 this.getClickedRecord('delete');
            //             }
			// 		});
            // }   
			this.initFilters();
            this.columns = [
                {field: 'action', header: 'Action'}, 
                {field: 'quotation_number', header: 'Quotation #'},    
                {field: 'amount', header: 'Total Payable'},   
                {field: 'quotation_status', header: 'Quotation Status'},  
                {field: 'quotation_date', header: 'Quotation Date'},    
                {field: 'due_date', header: 'Due Date'},  
                {field: 'sent_date', header: 'Sent Date'},  
                {field: 'id', header: 'Download'},  
            ];
            this.selectedColumns = this.columns;
		},
        computed: { 
        },
		mounted() { 

            //THIS CODE IS TO TABLE COLUMNS SETTINGS
            this.axios.post('getAllModuleColumnSettings', { 'module_id': this.moduleId })
            .then((response) => {
                let responseStatus = this.printResponseResult(response,false); 
                if(responseStatus == 200){
                    if(response.data){
                        if(Object.keys(response.data).length){
                            this.columns =[]; 
                            this.selectedColumns = [];
                            response.data.forEach( (column) => { 
                                this.columns.push({'field':column.column_field, 'header': column.column_head});
                                if(column.display){
                                    this.selectedColumns.push({'field':column.column_field, 'header': column.column_head});
                                } 
                            }); 
                        }
                    }
                }
            })
            .catch((error) => {
                this.printResponseResult(error);
            })
            .finally(() => {   
            })
            //END


            this.priceRounding = this.userData['company_price_rounding'] ? this.userData['company_price_rounding'] : 2,

            this.quotationStatuses['Pending'] = {label:'Pending', icon:'pi pi-eye-slash', color:'warning'}; 
            this.quotationStatuses['Seen'] = {label:'Seen', icon:'pi pi-eye', color:'primary'};
            this.quotationStatuses['Accepted'] = {label:'Accepted', icon:'pi pi-check', color:'info'}; 
            this.quotationStatuses['Canceled'] =  {label:'Canceled', icon:'pi pi-times', color:'danger'};
			this.getRecords();   

            if(this.$route.query.view){
                this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
                if(Object.keys(this.urlParams).length){
                    this.urlParams.forEach( (parameter) => {
                        if(parameter.view == 'add-quotation'){
                            this.selectedRecordId = parameter.id;
                            this.showForm = true;
                        } 
                        else if(parameter.view == 'send-invoice'){
                            this.selectedRecordId = parameter.id;
                            this.showInvoiceForm = true;
                        } 
                        else if(parameter.view == 'quotation-history'){
                            this.selectedRecordId = parameter.id;
                            this.showHistoryPage = true;
                        }
                        else if(parameter.view == 'change-order-quotation-status'){ 
                            this.selectedRecordId = parameter.id;
                            this.quotationStatusForm.reset();
                            this.showStatusChangedForm = true; 
                            this.quotationStatusForm.status = parameter.quotation_status;
                        } 
                    })
                }
            }
		},
		methods: {   
            onColReorder(event){ 
                this.columns = this.tableColumnOrderChange(event,this.columns);  
                this.saveTableColumnOrderChange(this.moduleId,this.columns,this.selectedColumns);
            },
            onToggle(value) {  
                //this.selectedColumns = this.columns.filter(col => value.includes(col)); 
                this.selectedColumns = []; 
                let newColumns = []; 
                this.columns.filter((col) => {
                    if(value.find(x => x.field === col.field)){
                        newColumns.push(col);
                        return col;
                    } 
                }); 
                this.selectedColumns = newColumns; 
                this.saveTableColumnOrderChange(this.moduleId,this.columns,this.selectedColumns);
            },
            closeTransectionPage(){  
                this.showTransectionPage = false;
                this.getQuotationPayments(); 
            },  

            async changeOrderQuotationStatus(){    
                var Vthis = this; 
                Vthis.quotationStatusForm.id = Vthis.selectedRecordId;
                Vthis.quotationStatusForm.quotation_for_id = Vthis.orderId;
                this.isStatusSaving = true;
                await this.quotationStatusForm
                .post(Vthis.$baseurl+'api/changeQuotationStatus', Vthis.quotationStatusForm)
                .then((response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){
                        Vthis.getRecords();
                        Vthis.quotationStatusForm.reset();  
                        Vthis.records.filter( (record) => {
                            if(record.id == Vthis.quotationStatusForm.id){
                                record.quotation_status = Vthis.quotationStatusForm.status;
                            } 
                        })  
                        Vthis.showMessage('Saved successfully','success'); 
                        Vthis.showStatusChangedForm = false;
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isStatusSaving = false; 
                }) 
            }, 
 
            
            async downloadQuotation($id){
                this.downloadingQuotation[$id] = true;
                var Vthis = this; 
                await this.axios.post('downloadQuotation',{quotation_for_id:this.orderId,quotation_for:'order', id:$id})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){
                        window.open(Vthis.$baseurl+response.data.link, '_blank').focus();  
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                   this.downloadingQuotation[$id] = false;
                })  
            },
 
            async changeQuotationStatus(status){ 
                if(status == 'Pending'){
                    this.quotationStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.quotationStatusForm.status = 'Pending';
                    this.addViewInURL({view: 'change-order-quotation-status', id: this.selectedRecordId, quotation_status:'Pending'});
                }
                else if(status == 'Seen'){
                    this.quotationStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.quotationStatusForm.status = 'Seen';
                    this.addViewInURL({view: 'change-order-quotation-status', id: this.selectedRecordId, quotation_status:'Seen'});
                }
                else if(status == 'Accepted'){
                    this.quotationStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.quotationStatusForm.status = 'Accepted';
                    this.addViewInURL({view: 'change-order-quotation-status', id: this.selectedRecordId, quotation_status:'Accepted'});
                }
                 
                else if(status == 'Canceled'){
                    this.quotationStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.quotationStatusForm.status = 'Canceled';
                    this.addViewInURL({view: 'change-order-quotation-status', id: this.selectedRecordId, quotation_status:'Canceled'});
                }  
                else if(status == 'ResendMail'){
                    this.resendQuotationMail(); 
                }
                else if(status == 'Copy'){ 
                    this.showForm = true;
                    this.addViewInURL({view: 'add-quotation', id: this.selectedRecordId});
                }
                else if(status == 'SendInvoice'){ 
                    this.showInvoiceForm = true;
                    this.addViewInURL({view: 'send-invoice', id: this.selectedRecordId});
                }
                else if(status == 'ViewHistory'){ 
                    this.showHistoryPage = true;
                    this.addViewInURL({view: 'quotation-history', id: this.selectedRecordId});
                }

                
            },

           
            resendQuotationMail(){ 
                var Vthis = this;  
                this.$confirm.require({
                    group: 'dialog2',
                    header: 'Confirmation',
                    message: 'Are you sure you want to proceed?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => { 
                        let message = 'Sending mail...'
                        Vthis.showMessage(message,'info',55000);  
                        Vthis.axios.post('resendQuotationMail',{id:Vthis.selectedRecordId})
                        .then( (returnedData) => { 
                            let responseStatus = this.printResponseResult(returnedData,false); 
                            if(responseStatus == 200){
                                Vthis.$toast.removeAllGroups();
                                if(returnedData.response){  
                                    if(returnedData.response.data){
                                        Vthis.showMessage(returnedData.response.data.message,'info');  
                                    }
                                    else if(returnedData.message){
                                        Vthis.showMessage(returnedData.message,'info');  
                                    }
                                }
                                else if(returnedData){ 
                                    let message = 'Email sent successfully.'
                                    Vthis.showMessage(message,'success');  
                                }
                                else{
                                    let message = 'Email not sent. Please try again later'
                                    Vthis.showMessage(message,'error');  
                                }
                            }
                        })
                        .catch((error) => {    
                            this.printResponseResult(error);
                        })
                        .finally(() => {  
                        })  
                    },
                    reject: () => {
                        // this.loading = false;
                    }
                }); 

                
                
                 
            },
             
            
            getClickedRecord(action){
                if(action == 'delete'){ 
                    this.deleteRecord(); 
                }
                else if(action == 'view'){
                    this.viewRecord();
                }  
                else if(action == 'update'){
                    this.showForm = true; 
                }
            },

            toggleRecordMenu(event) {
                this.selectedRecordId = event.currentTarget.getAttribute('recordId');  
				this.$refs.menu.toggle(event);
			},
            loadForm(){ 
                this.selectedRecordId = 0;
                this.showForm = true;
                this.addViewInURL({view: 'add-quotation', id:this.selectedRecordId}); 
            }, 
            closeForm(){ 
                if(!this.selectedRecordId){
                    this.getRecords();
                }
                this.showForm = false;
                this.removeViewFromURL('add-quotation');
            }, 
            getRecords(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllOrderQuotations',{quotation_for_id:this.orderId,quotation_for:'order'})
                .then( (response) => {
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){  
                        this.records = response.data;  
                        this.loading = false; 
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            }, 
            getQuotationRecord(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllOrderQuotations',{id:this.selectedRecordId})
                .then( (response) => {  
                    this.printResponseResult(response,false);  
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            },
            deleteRecord(){ 
                var Vthis = this;
                this.$confirm.require({
                    group: 'dialog',
                    header: 'Confirmation',
                    message: 'Are you sure you want to delete?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => { 
                        Vthis.loading = true;  
                        let DeleteAbleRecords = [];
                         
                        if(Vthis.selectedRecords){
                            Vthis.selectedRecordId = '';
                            Vthis.selectedRecords.forEach((data) => {
                                DeleteAbleRecords.push(data.id);
                            })
                        } 
                        else{
                            DeleteAbleRecords.push(Vthis.selectedRecordId);
                        } 
                         
                        // let filterdRecords = Vthis.records.filter(val => Vthis.selectedRecords.includes(val)); 
                        // filterdRecords.forEach((data) => {
                        //     DeleteAbleRecords.push(data.id);
                        // })   

                        Vthis.axios.post('deleteOrderQuotation',{id:DeleteAbleRecords})
                        .then( (response) => {   
                            let responseStatus = this.printResponseResult(response,false); 
                            if(responseStatus == 200){
                                Vthis.getRecords();
                                Vthis.showMessage('Record deleted successfully','success'); 
                            }
                        })
                        .catch((error) => {   
                            this.printResponseResult(error);
                        })
                        .finally(() => { 
                            Vthis.loading = false;
                        })
                    },
                    reject: () => {
                        // this.loading = false;
                    }
                }); 
            },
            exportCSV() {
                this.$refs.dt.exportCSV();
            },
          
            initFilters() {
				this.filters = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'quotation_number': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'amount': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'sent_date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'due_date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'quotation_date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'quotation_status': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'company': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
				}
			},
			clearFilter() {
				this.initFilters();
			}, 
            isRTL() {
				return this.$appState.RTL;
			}
		},
        watch : { 
        }
}
</script>
 
