<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{module_name}}</h3> 
                        <p>You can add or update {{module_name}} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="name" /> 
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" v-if="checkPermission(16,'dropdown')"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Company</label>
                                    <Dropdown :filter="true" :showClear="true"  v-model="form.company_id" :options="companies" optionLabel="name" optionValue="id" placeholder="Select Company" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="company_id" />
                                </div>  
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Email Sender Name</label>
                                    <InputText id="sender_email_name" placeholder="Email Sender Name" v-model="form.sender_email_name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="sender_email_name" /> 
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Sender Email Address</label>
                                    <InputText id="sender_email_address" placeholder="Email Sender Email Address" v-model="form.sender_email_address"  type="email" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="sender_email_address" /> 
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Phone</label>
                                    <InputText id="phone" placeholder="Phone" v-model="form.phone" min="0" step="1" type="number" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="phone" /> 
                                </div>  
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Address</label>
                                    <InputText id="address" placeholder="Address" v-model="form.address" type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="address" /> 
                                </div>
                                
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Status</label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name" optionValue="id" placeholder="Select Status" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="status" />
                                </div>  
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>About/Description</label>
                                    <Textarea placeholder="Description" v-model="form.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="form" field="description" />
                                </div> 
                                 
                            </div>  
                        </div> 
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>  
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" v-if="checkPermission(13,'dropdown')"> 
                                    <label>Country</label>
                                    <Dropdown CountriesLoading @change="getStates($event)" v-model="form.country" :filter="true" :showClear="true" :options="countries" optionLabel="name" optionValue="id" :placeholder="CountriesLoading ? 'Loading...' : 'Select Country'" class="full-width" />
                                    <HasError class="p-error" :form="form" field="country" /> 
                                </div> 
                                <div v-if="form.country > 0 && checkPermission(14,'dropdown')" class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>State</label>
                                    <Dropdown StatesLoading @change="getCities($event)" v-model="form.state" :filter="true" :showClear="true" :options="states" optionLabel="name" optionValue="id" :placeholder="StatesLoading ? 'Loading...' : 'Select State'" class="full-width" />
                                    <HasError class="p-error" :form="form" field="city" /> 
                                </div> 
                                <div v-if="form.state > 0 && checkPermission(15,'dropdown')" class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>City</label>
                                    <Dropdown CitiesLoading v-model="form.city" :filter="true" :showClear="true" :options="cities" optionLabel="name" optionValue="id" :placeholder="CitiesLoading ? 'Loading...' : 'Select City'" class="full-width" />
                                    <HasError class="p-error" :form="form" field="country" /> 
                                </div>   
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Postal Address</label>
                                    <InputText id="postal_address" placeholder="Postal Address" v-model="form.postal_address" type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="postal_address" /> 
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Postal Code/ZipCode</label>
                                    <InputText id="zipcode" placeholder="Zipcode" v-model="form.zipcode" type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="zipcode" /> 
                                </div>  
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Latitude</label>
                                    <InputText id="latitude" placeholder="Latitude" v-model="form.latitude" type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="latitude" /> 
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Longitude</label>
                                    <InputText id="longitude" placeholder="Longitude" v-model="form.longitude" type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="longitude" /> 
                                </div>
                                
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" > 
                                    <label>Terms & Conditions</label>
                                    <ckeditor  @ready="onReady" :editor="editor"  v-model="form.terms" :config="editorConfig" :rows="5"/> 
                                </div>    
                            </div>   
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/>
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
    </BlockUI>
    
</template>
<script> 
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
			return {  
                form: new this.Form({ 
                    id: '', 
                    company_id: 0, 
                    name: '', 
                    sender_email_name:'',
                    sender_email_address:'', 
                    phone:0, 
                    terms:'',
                    country:0,
                    state:0,
                    city:0,
                    zipcode:'',
                    postal_address:'',
                    address: '',  
                    latitude:'',
                    longitude:'',
                    status:1, 
                    description: '',    
                }), 
                editor: ClassicEditor,
                    editorData: '<p>Content of the editor.</p>',
                    editorConfig: {
                        ckfinder: {
                            uploadUrl: 'http://www.mypage.com/api/uploadckeditor'
                        },
                        toolbar: {
                            items: [
                                'heading',
                                '|',
                                'fontSize',
                                'fontFamily',
                                'fontColor',
                                'fontBackgroundColor',
                                'imageInsert',
                                '|',
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                                'highlight',
                                'removeFormat',
                                '|',
                                'alignment',
                                '|',
                                'numberedList',
                                'bulletedList',
                                '|',
                                'indent',
                                'outdent',
                                '|',
                                'todoList',
                                'link',
                                'blockQuote',
                                'imageUpload',
                                'insertTable',
                                'mediaEmbed',
                                '|',
                                'undo',
                                'redo',
                                'CKFinder'
                            ]
                        },
                        language: 'cs',
                        image: {
                            toolbar: [
                                'imageTextAlternative',
                                'imageStyle:full',
                                'imageStyle:side',
                                'linkImage'
                            ]
                        },
                    },
                CitiesLoading:false,
                StatesLoading:false,
                CountriesLoading:false,
                module_name:'Branch',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false,
                countries:[],
                states:[],
                cities:[],
                branches:[],
                companies:[],
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
        },
		created() {   
		},
        computed: { 
        },
		mounted() {  
            this.resetForm();
            this.formVisible = this.showForm; 
            this.recId = this.recordId;   
            this.getAllRequestData();  
		},
		methods: {   
             getAllRequestData(){   
                this.$axios.all([this.updateRecord()])    
                .then(() => {  
                    this.getCountries();    
                }) 
                .then(() => {  
                    this.getStates();    
                }) 
                .then(() => {  
                    this.getCities();  
                })   
                .then(() => {  
                    this.getCompanies();  
                }) 
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },
            async getCompanies(){ 
                var Vthis = this; 
                await this.axios.post('getAllCompanies',{dropdown:true})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.companies = response.data;   
                        if(this.form.company_id==0){
                            this.form.company_id = response.data[0].id;
                        }
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            }, 
            async getRecords(){  
                await this.axios.post('getAllBranches')
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.branches = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            }, 
            async getCountries(){  
                this.CountriesLoading = true; 
                var Vthis = this;  
                await Vthis.axios.post('getAllCountries',{dropdown:true})
                .then( (response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){   
                        this.countries = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                    this.CountriesLoading = false; 
                }) 
                 
            },

            async getStates($event=''){  
               
                var country = 0;
                if(this.recId){ 
                    country = this.form.country;
                }
                else if($event.value){
                     country = $event.value;
                }
                
                if(country){ 
                    this.StatesLoading = true;
                    var Vthis = this;  
                    await Vthis.axios.post('getAllStates',{country:country,dropdown:true})
                    .then( (response) => { 
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){   
                            this.states = response.data;   
                        }
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => {  
                        this.StatesLoading = false;
                    }) 
                }
            },

            async getCities($event=''){  

                var state = 0;
                if(this.recId){ 
                    state = this.form.state;
                }
                else if($event.value){
                     state = $event.value;
                }
                if(state){ 
                    this.CitiesLoading = true;
                    var Vthis = this;  
                    await Vthis.axios.post('getAllCities',{state:state,dropdown:true})
                    .then( (response) => {   
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            this.cities = response.data;   
                        }
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => {  
                        this.CitiesLoading = false;
                    }) 
                }
            },

            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                await this.form
                .post(Vthis.$baseurl+'api/saveBranch', Vthis.form)
                .then((response) => { 
                    let responseStatus = this.printResponseResult(response); 
                    if(responseStatus == 200){  
                        if(this.saveAndClose){   
                            this.saveAndClose = false;
                            setTimeout( () => {this.closeForm();},1000);
                        }    
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false; 
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            },
            myUploader(event){   
                this.form.logo = event.files;
            },  
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getAllBranches',{id:this.recId})
                    .then( (response) => {    
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            if(!response.data[0].terms){
                                response.data[0].terms = '';
                            }
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            Vthis.form.fill(response.data[0]);
                            Vthis.logo = false;
                            if(response.data[0].logo){
                                Vthis.logo = Vthis.filesUrl+"companies/"+response.data[0].logo;
                            }  
                        }
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },   
            async removeLogo(){ 
                var Vthis = this;  
                await Vthis.axios.post('deleteSubsidairyLogo',{id:this.form.id})
                .then( (response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){   
                        if(response){
                            Vthis.showMessage('Deleted Successfully','success'); 
                            Vthis.logo = false;
                        }
                        else{ 
                            Vthis.showMessage('Not deleted! Please try later','error'); 
                        }
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {   
                }) 
            },
            confirmFileDeletion($event){
                this.$confirm.require({
                    target: $event.currentTarget,
                    message: 'Are you sure you want to proceed?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => { 
                        this.removeLogo();
                    },
                    reject: () => {
                        
                    }
                });
            }
		},
        watch : {
             
        }
}
</script>
 
