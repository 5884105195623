<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{module_name}}</h3> 
                        <p>You can add or update {{module_name}} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="name" /> 
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Email</label>
                                    <InputText id="email" placeholder="Email" v-model="form.email"  type="email" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="email" /> 
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Phone</label>
                                    <InputText id="phone" placeholder="Phone" v-model="form.phone" min="0" step="1" type="number" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="phone" /> 
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" v-if="addCustomer"> 
                                    <label>Customer Company</label>
                                    <InputText id="customer_company" placeholder="Customer Company" v-model="form.customer_company"   class="full-width"/>
                                    <HasError class="p-error" :form="form" field="customer_company" /> 
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  v-if="checkPermission(4,'dropdown') && !addCustomer"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Roles <span v-if="checkPermission(4,'insert')" class="supporting-btn-link" @click="showRoleForm=true;">+ Add New</span></label>
                                    <MultiSelect @change="getAssignAblePermissions()" :filter="true" v-model="form.roles" :options="roles" optionLabel="company_role_name" optionValue="id" placeholder="Select Roles" display="chip" class="full-width" />
                                    <HasError class="p-error" :form="form" field="roles" /> 
                                </div>
                                <!-- <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Access Levels<span v-if="permissions[12].includes('insert')" class="supporting-btn-link" @click="showAccessLevelForm=true;">+ Add New</span></label>
                                    <MultiSelect :filter="true" v-model="form.access_levels" :options="accessLevels" optionLabel="name" optionValue="id" placeholder="Select Access Levels" display="chip" class="full-width" />
                                    <HasError class="p-error" :form="form" field="access_levels" /> 
                                </div> --> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  v-if="checkPermission(64,'dropdown') && !addCustomer"> 
                                    <label> User Category<span v-if="checkPermission(64,'insert')" class="supporting-btn-link" @click="showUserCategoryForm=true;">+ Add New</span></label>
                                    <Dropdown UserCategoriesLoading :filter="true" :showClear="true"  v-model="form.user_category" :options="UserCategories" optionLabel="name" optionValue="id"  :placeholder="UserCategoriesLoading ? 'Loading...' : 'Select Category'" class="full-width" />
                                    <HasError class="p-error" :form="form" field="user_category" />
                                </div>   
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  v-if="checkPermission(16,'dropdown') && !addCustomer"> 
                                    <label>Company <span v-if="checkPermission(16,'insert')" class="supporting-btn-link" @click="showCompanyForm=true;">+ Add New</span></label>
                                    <Dropdown v-model="form.company_id" :filter="true" :showClear="true" :options="companies" optionLabel="name" optionValue="id" placeholder="Select a Company" @change="selectCompany($event)" class="full-width" />
                                    <HasError class="p-error" :form="form" field="company_id" /> 
                                </div> 
                                <!-- <div v-if="form.company_id > 0" class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Subsidiary Company<span v-if="permissions[7].includes('insert')" class="supporting-btn-link" @click="showSubsidiaryForm=true;">+ Add New</span></label>
                                    <Dropdown @change="getBranches()" :filter="true" v-model="form.subsidiary_id" :showClear="true" :options="subsidiaries" optionLabel="name" optionValue="id" placeholder="Select Subsidiary Company" class="full-width" />
                                    <HasError class="p-error" :form="form" field="subsidiary_id" /> 
                                </div> -->
                                <div v-if="form.company_id > 0 && checkPermission(8,'dropdown') && !addCustomer" class="col-12 lg:col-12 sm:col-12 mb-2"  > 
                                    <label>Branch<span v-if="checkPermission(8,'insert')" class="supporting-btn-link" @click="showBranchForm=true;">+ Add New</span></label>
                                    <Dropdown v-model="form.branch_id" :filter="true" :showClear="true" :options="branches" optionLabel="company_branch_name" optionValue="id" placeholder="Select Branch" class="full-width" />
                                    <HasError class="p-error" :form="form" field="branch_id" /> 
                                </div> 
                                <div v-if="form.company_id > 0 && checkPermission(9,'dropdown') && !addCustomer" class="col-12 lg:col-12 sm:col-12 mb-2" > 
                                    <label>Department<span v-if="checkPermission(9,'insert')" class="supporting-btn-link" @click="showDepartmentForm=true;">+ Add New</span></label>
                                    <Dropdown v-model="form.department_id" :filter="true" :showClear="true" :options="departments" optionLabel="company_dep_name" optionValue="id" placeholder="Select Department" class="full-width" />
                                    <HasError class="p-error" :form="form" field="department_id" /> 
                                </div> 
                                <div v-if="form.company_id > 0 && checkPermission(11,'dropdown') && !addCustomer" class="col-12 lg:col-12 sm:col-12 mb-2" > 
                                    <label>Designation<span v-if="checkPermission(11,'insert')" class="supporting-btn-link" @click="showDesignationForm=true;">+ Add New</span></label>
                                    <Dropdown v-model="form.designation_id" :filter="true" :showClear="true" :options="designations" optionLabel="company_designation_name" optionValue="id" placeholder="Select Designation" class="full-width" />
                                    <HasError class="p-error" :form="form" field="designation_id" /> 
                                </div>  
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" v-if="!addCustomer"> 
                                    <label>Status</label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name" optionValue="id" placeholder="Select Status" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="status" />
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>About this user</label>
                                    <Textarea placeholder="User Description" v-model="form.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="form" field="description" />
                                </div> 
                                 
                            </div>
                            <Panel class="mb-3 full-width" :header="'Settings'" :toggleable="true"  style="float:left" >  
                                <div> 
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left">  
                                        <label>Email Sending </label>
                                        <Dropdown v-model="form.email_sending" :options="EmailSendingOptions" optionLabel="name" optionValue="id" placeholder="Select Option" class="full-width"/>
                                    </div> 
                                </div> 
                            </Panel>  
                        </div> 
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div> 
                                <Panel v-if="profilePic" class="mb-3" header="Current Profile Picture" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2 profileCardImg"> 
                                        <div>
                                            <img alt="Profile Picture" :src="profilePic" >
                                        </div>
                                        <div v-if="checkPermission(moduleId,'delete')">
                                            <ConfirmPopup group="popup"></ConfirmPopup>
                                            <Button ref="popup" @click="confirmFileDeletion($event)" icon="pi pi-times" label="Remove" class="p-button-danger p-button-raised p-button-md" style="margin-left: .5em" />
                                        </div>   
                                    </div>
                                </Panel>
                                <Panel class="mb-3" header="Profile Picture" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                        <FileUpload name="profile_pic" :showUploadButton="false" :customUpload="true" @select="myUploader" :multiple="false" accept="image/*" :maxFileSize="1000000"> </FileUpload> 
                                    </div> 
                                </Panel> 
                                <Panel  v-if="availablePermissions.length && !addCustomer" class="mb-3" header="Additional Permissions" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2">  
                                        <Tree :filter="true" :value="availablePermissions" selectionMode="checkbox" v-model:selectionKeys="form.additionalPermissions"></Tree>    
                                        <!-- <label  v-if="!availablePermissions.length" class="text-center">No Permissions to assign</label> -->
                                    </div> 
                                </Panel> 
                                <!-- <Panel class="mb-3" header="Vehicle Types" :toggleable="true">  
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2" v-for="(type,key) in form.vehicleTypes" :key="key"> 
                                       <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                {{type.name}}
                                            </span>
                                            <InputNumber v-model="form.vehicleTypes[key].quantity" placeholder="Number of Vehicles" class="full-width" />
                                            <HasError class="p-error" :form="form" field="vehicleTypes" /> 
                                        </div> 
                                    </div> 
                                </Panel> -->
                                <Panel class="mb-3" header="Vehicle types that user can drive" :toggleable="true" v-if="checkPermission(36,'dropdown') && !addCustomer">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2">   
                                        <div class="field-checkbox">
                                            <Checkbox @click="selectAllVehicleTypes()" value="true" v-model="AllVehicleTypes" :binary="true" />
                                            <label >Select All</label>
                                        </div>
                                        <HasError class="p-error" :form="form" field="vehicleTypes" /> 
                                        <Listbox v-model="form.vehicleTypes" filterPlaceholder="Select Vehicle Type" checkbox="true" :multiple="true" :filter="true" :showClear="true" :options="vehicleTypes" optionLabel="name"  optionValue="id" class="full-width" />
                                    </div> 
                                </Panel>
                                <div class=""> 
                                    <div v-for="(contact,key) in form.userContacts" :key="key"> 
                                        <Panel class="mb-3 full-width" :header="'Contact Person - '+ (key+1) " :toggleable="true"  style="float:left" >  
                                            <div>
                                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                    <label>First Name</label>
                                                    <InputText id="user_contact_names" placeholder="First Name" v-model="form.userContacts[key].first_name" :value="contact.first_name"  type="text" class="full-width"/>
                                                </div>
                                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                    <label>Surname</label>
                                                    <InputText id="user_contact_surnames" placeholder="Surname" v-model="form.userContacts[key].surname" :value="contact.surname"  type="text" class="full-width"/>
                                                </div> 
                                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                    <label>Email</label>
                                                    <InputText id="user_contact_email" placeholder="Email" v-model="form.userContacts[key].email" :value="contact.email"  type="email" class="full-width"/>
                                                </div>
                                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                    <label>Phone</label>
                                                    <InputText id="user_contact_phone" placeholder="Phone" v-model="form.userContacts[key].phone" :value="contact.phone"  type="text" class="full-width"/>
                                                </div>
                                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                    <label>Mobile</label>
                                                    <InputText id="user_contact_Mobile" placeholder="Mobile" v-model="form.userContacts[key].mobile" :value="contact.mobile"  type="text" class="full-width"/>
                                                </div>
                                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                    <label>Position</label>
                                                    <InputText id="user_contact_Position" placeholder="Position" v-model="form.userContacts[key].position" :value="contact.position"  type="text" class="full-width"/>
                                                </div>
                                                <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left">  
                                                    <label>Address</label>
                                                    <InputText id="customer_address" placeholder="Address" v-model="form.userContacts[key].address" :value="contact.address"  type="text" class="full-width"/>
                                                </div>
                                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                    <label>Show on Job Sheet</label>
                                                    <Dropdown v-model="form.userContacts[key].show_on_job_sheet" :options="ShowOnJobSheetOptions" optionLabel="name" optionValue="id" placeholder="Select Option" class="full-width"/>
                                                </div>
                                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                    <label>Is it billing contact?</label>
                                                    <Dropdown v-model="form.userContacts[key].billing_contact" :options="BillingContactOptions" optionLabel="name" optionValue="id" placeholder="Select Option" class="full-width"/>
                                                </div>   
                                                <div class="col-2 lg:col-2 sm:col-12 mb-2 mt-3" style="float:left">  
                                                    <Button @click="form.userContacts.splice(key,1)" v-if="checkPermission(moduleId,'delete')" v-tooltip.top="'Delete'"  icon="pi pi-trash"  class="p-button-lg p-button-raised p-button-danger mr-2"  style="padding: 8px 20px;"/> 
                                                </div>
                                            </div> 
                                        </Panel> 
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-12 mt-3 mb-3" style="float:left">
                                        <Button @click="addUserContactItem()" v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Add New Contact'" :label="showBtnLabels ? 'Add New Contact' : ''" icon="pi pi-plus"  class="p-button-raised p-button-success mt-2 mr-2 float-right" /> 
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/>
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar>  
        <UserCategoryForm v-on:formClose="closeUserCategoryForm" v-if="showUserCategoryForm && (checkPermission(64,'insert'))" :recordId="0" :showForm="true" /> 
        <DesignationForm v-on:formClose="closeDesignationForm" v-if="showDesignationForm && (checkPermission(11,'insert'))" :recordId="0" :showForm="true" /> 
        <DepartmentForm v-on:formClose="closeDepartmentForm" v-if="showDepartmentForm && (checkPermission(9,'insert'))" :recordId="0" :showForm="true" /> 
        <BranchForm v-on:formClose="closeBranchForm" v-if="showBranchForm && (checkPermission(8,'insert'))" :recordId="0" :showForm="true" /> 
        <SubsidiaryForm v-on:formClose="closeSubsidiaryForm" v-if="showSubsidiaryForm && (checkPermission(7,'insert'))" :recordId="0" :showForm="true" /> 
        <CompanyForm v-on:formClose="closeCompanyForm" v-if="showCompanyForm && (checkPermission(16,'insert'))" :recordId="0" :showForm="true" /> 
        <RoleForm v-on:formClose="closeRoleForm" v-if="showRoleForm && (checkPermission(4,'insert'))" :recordId="0" :showForm="true" /> 
        <!-- <AccessLevelForm v-on:formClose="closeAccessLevelForm" v-if="showAccessLevelForm && (permissions[12].includes('insert'))" :recordId="0" :showForm="true" />  -->
    </BlockUI>
</template>
<script> 
import UserCategoryForm from '../../components/AccessManagement/UserCategoryForm.vue';
import DesignationForm from '../../components/Companies/DesignationForm.vue'; 
import DepartmentForm from '../../components/Companies/DepartmentForm.vue'; 
import BranchForm from '../../components/Companies/BranchForm.vue'; 
import SubsidiaryForm from '../../components/Companies/SubsidiaryForm.vue'; 
import CompanyForm from '../../components/Companies/CompanyForm.vue'; 
// import AccessLevelForm from '../../components/AccessManagement/AccessLevelForm.vue'; 
import RoleForm from '../../components/AccessManagement/RoleForm.vue'; 
export default {
    data() {
			return {  
                form: new this.Form({ 
                    id: '', 
                    name: '', 
                    company_id:0,
                    branch_id:0,
                    email: '',
                    // access_levels:[],
                    subsidiary_id:0,
                    department_id:0,
                    designation_id:0,
                    user_category:0,
                    profile_pic: '',
                    phone: '',
                    roles:[],
                    status:1,
                    saveEmployee:false,
                    description: '', 
                    additionalPermissions: [],
                    selectedAdditionalAccessLevels:[],
                    vehicleTypes:[], 
                    userContacts:[],  
                    email_sending:1,
                    customer_company : '',
                    
                }),
                // selectAllAL:false,
                module_name: this.addCustomer ? 'Customer' : 'Users', 
                moduleId:5,
                companies:[],
                subsidiaries:[],
                branches:[],
                departments:[],
                designations:[],
                // accessLevels:[],
                // additionalAccessLevels:[],
                availablePermissions:[], 
                UserCategoriesLoading:false,
                isSaving:false, 
				roles: null,    
                saveAndClose:false, 
                AllVehicleTypes:false,
                recId:0,
                vehicleTypesLoading:false,
                loadingComponent:true,
                formVisible:false,
                modulePermissions : [],
                vehicleTypes:[], 
                UserCategories:[],
                ShowOnJobSheetOptions:[{name:'Yes', id:1},{name:'No', id:0}],
                BillingContactOptions:[{name:'Yes', id:1},{name:'No', id:0}],
                EmailSendingOptions:[{name:'Active', id:1},{name:'Blocked', id:0}],
                showRoleForm:false,
                // showAccessLevelForm:false,
                showCompanyForm:false,
                showSubsidiaryForm:false,
                showBranchForm:false,
                showDepartmentForm:false,
                showDesignationForm:false,
                showUserCategoryForm:false,
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
            addCustomer :{
                type : Boolean,
            },
        },
        components:{
            RoleForm,
            // AccessLevelForm,
            UserCategoryForm,
            CompanyForm,
            SubsidiaryForm,
            BranchForm,
            DepartmentForm,
            DesignationForm
        },
		created() {   
            this.modulePermissions = this.permissions[this.moduleId];
		},
        computed: { 
        },
		mounted() {  
            this.resetForm();
            this.formVisible = this.showForm; 
            this.recId = this.recordId;   
            this.getAllRequestData();  
		},
		methods: { 
            addUserContactItem(){  
                this.form.userContacts.push({'first_name' : '', 'surname' : '', 'email' : '', 'phone' : '', 'mobile' : '', 'position' : '','address':'', 'show_on_job_sheet' : 0, 'billing_contact' : 0 }); 
            }, 
            closeUserCategoryForm(){ 
                this.getUserCategories();
                this.showUserCategoryForm = false;
            }, 
            getAllRequestData(){   
                this.$axios.all([this.getRoles()])   
                .then(() => {  
                    this.getVehicleTypes();  
                    this.getCompanies();   
                }) 
                .then(() => {  
                    this.updateRecord();  
                })  
                .then(() => {  
                    this.getUserCategories(); 
                })  
                .then(() => { 
                    this.getCompanyDependents(this.form.company_id);   
                })   
                
                .then(() => {   
                    this.getUserSelectedAdditionalPermissions(); 
                })  
                .then(() => { 
                    if(!this.recordId){
                        this.getAssignAblePermissions();   
                    } 
                }) 
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },
            async getUserCategories(){ 
                var Vthis = this;
                Vthis.UserCategoriesLoading = true;
                await this.axios.post('getAllUserCategories',{dropdown:true})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.UserCategories = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error); 
                })
                .finally(() => { 
                    Vthis.UserCategoriesLoading = false;
                })  
            }, 
            closeAccessLevelForm(){
                this.getAccessLevels();
                this.showAccessLevelForm = false;
            },  
            closeRoleForm(){
                this.getRoles();
                this.showRoleForm = false;
            }, 
            closeCompanyForm(){
                this.getCompanies();
                this.showCompanyForm = false;
            }, 
            closeSubsidiaryForm(){
                this.get_subsidiaries(this.form.company_id);
                this.showSubsidiaryForm = false;
            },  
            closeBranchForm(){
                this.getCompanyBranches();
                this.showBranchForm = false;
            },  
            closeDepartmentForm(){
                this.getDepartments();
                this.showDepartmentForm = false;
            },  
            closeDesignationForm(){
                this.getDesignations();
                this.showDesignationForm = false;
            },  
            
            
            
            async getVehicleTypes(){  
                this.vehicleTypesLoading = true; 
                var Vthis = this;  
                await Vthis.axios.post('getAllVehicleTypes',{dropdown:true})
                .then( (response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){    
                        // var selectedVehicleTypes = Vthis.form.vehicleTypes;
                        // console.log(typeof response.data);
                        // console.log(response.data);
                        Vthis.vehicleTypes = response.data;
                        // var selectedVehicleTypes = Vthis.form.vehicleTypes;
                        // Vthis.form.vehicleTypes = new Array(); 
                        // response.data.forEach( (type) => {  
                        //     var obj = {
                        //         'id' : type.id,
                        //         'name' : type.name,
                        //         'quantity' : 0,
                        //     };
                        //     selectedVehicleTypes.forEach( (rec) => {
                        //         if(rec.vehicle_type_id == type.id){
                        //             obj.quantity = rec.quantity;
                        //         }
                        //     })  
                        //     Vthis.form.vehicleTypes.push(obj);
                        // });  
                        // this.vehicle_types = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                    this.vehicleTypesLoading = false; 
                }) 
                 
            },

            selectAllVehicleTypes(){ 
               if(!this.AllVehicleTypes){
                   this.vehicleTypes.forEach((type) => {
                       this.form.vehicleTypes.push(type.id);
                   })
               }
               else{
                   this.form.vehicleTypes = [];
               }
            },

            // async getAssignAbleAdditionalAccessLevels(){  
            //     if(this.recId){ 
            //         var Vthis = this;  
            //         await Vthis.axios.post('getAssignAbleAdditionalAccessLevels',{id:this.recId})
            //         .then( (response) => {   
            //             let responseStatus = this.printResponseResult(response,false); 
            //             if(responseStatus == 200){ 
            //                 this.AdditionalAccessLevels = response.data;   
            //             }
            //         })
            //         .catch((error) => {    
            //             this.printResponseResult(error);
            //         })
            //         .finally(() => {  
            //         }) 
            //     }
            // },
            async getUserSelectedAdditionalPermissions(){  
                if(this.recId){ 
                    var Vthis = this;  
                    await Vthis.axios.post('getUserSelectedAdditionalPermissions',{id:this.recId})
                    .then( (response) => {   
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            this.form.additionalPermissions = response.data;   
                        }
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => {  
                    }) 
                }
            },
            // async getAccessLevels(){  
            //     var Vthis = this;  
            //     await Vthis.axios.post('getAllAccessLevels')
            //     .then( (response) => {  
            //         let responseStatus = this.printResponseResult(response,false); 
            //         if(responseStatus == 200){  
            //             this.accessLevels = response.data;   
            //         }
            //     })
            //     .catch((error) => {    
            //         this.printResponseResult(error);
            //     })
            //     .finally(() => {  
            //     }) 
            // },
            async getDesignations(id){ 
                var Vthis = this;  
                await Vthis.axios.post('getAllDesignations',{company_id:id, dropdown:true})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                         this.designations = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                }) 
            },
            async getDepartments(id){ 
                var Vthis = this;  
                await Vthis.axios.post('getAllDepartments',{company_id:id,dropdown:true})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){  
                        this.departments = response.data;  
                    } 
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                })
                
            },
            async getCompanyBranches(company_id=0){
                var Vthis = this; 
                var company = [this.form.company_id,company_id];  
                await Vthis.axios.post('getCompaniesBranches',{companies:company, dropdown:true})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        if(response.data.length){
                            if(!this.recId){  
                                this.form.branch_id = response.data[0].id;  
                            }  
                        }  
                        this.branches = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error); 
                })
                .finally(() => {  
                })
            },
            getBranches(){
                this.getCompanyBranches(); 
            }, 
            selectCompany(e){
                var company = e.value;
                this.getCompanyDependents(company);
            },
            getCompanyDependents(company){  
                // this.get_subsidiaries(company);
                this.getCompanyBranches(company);
                this.getDepartments(company);
                this.getDesignations(company);
            },
            async get_subsidiaries(id){ 
                var Vthis = this; 
                await Vthis.axios.post('getCompanySubsidiaries',{id:id,dropdown:true})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        if(response.data.length){
                            if(!this.recId){ 
                                this.form.subsidiaries = [];
                                response.data.forEach((rec) => {
                                    this.form.subsidiaries.push(rec.id);
                                })  
                            }    
                            this.subsidiaries = response.data; 
                            this.form.is_admin = 1;
                        }
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {  
                }) 
            },
            async getCompanies(){
                var Vthis = this; 
                if(!this.form.company_id){
                    this.form.company_id = this.userData['company_id'];
                }
                await Vthis.axios.post('getAllCompanies',{dropdown:true})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.companies = response.data;   
                        if(this.form.company_id==0){
                            this.form.company_id = response.data[0].id;
                        }


                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {  
                })
            },
            async getAssignAblePermissions(){
                var Vthis = this; 
                var roleIds = this.form.roles; 
                await Vthis.axios.post('getAssignAblePermissions',{id:roleIds,dropdown:true})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.availablePermissions = []; 
                        if(response.data.length){
                            this.availablePermissions = response.data; 
                        }  
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {  
                })
            }, 
            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                await this.form
                .post(Vthis.$baseurl+'api/saveUser', Vthis.form)
                .then((response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        Vthis.showMessage('Saved successfully','success'); 
                        if(!this.recId){
                            this.resetForm();
                        } 
                        // this.getRecords();
                        if(this.saveAndClose){   
                            this.saveAndClose = false;
                            setTimeout( () => {this.closeForm();},1000); 
                        }  
                    }
                })
                .catch((error) => {  
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false; 
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){ 
                this.$emit('formClose',true); 
            },
            myUploader(event){   
                this.form.profile_pic = event.files;
            }, 
            async getRoles(){ 
                var Vthis = this;
                this.loading = true;
                await this.axios.post('getAllRoles',{dropdown:true})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.roles = response.data;   
                        if(this.addCustomer){
                            this.roles.forEach( (role) => {
                                if(role.name == 'Customer'){
                                    this.form.roles.push(role.id);
                                }
                            })
                        }
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    this.loading = false; 
                })  
            },
            
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getAllUsers',{id:this.recId})
                    .then( (response) => {    
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            if(response.data[0].vehicleTypes === undefined){
                                response.data[0].vehicleTypes = [];
                            }
                            if(response.data[0].vehicleTypes && response.data[0].vehicleTypes != ''){
                                if(response.data[0].vehicleTypes.includes(',')){
                                    response.data[0].vehicleTypes = response.data[0].vehicleTypes.split(","); 
                                }
                                else{
                                    var vtype = response.data[0].vehicleTypes;
                                    response.data[0].vehicleTypes = [];
                                    response.data[0].vehicleTypes.push(vtype); 
                                }
                                response.data[0].vehicleTypes = response.data[0].vehicleTypes.map(Number);
                            }
                            else{
                                response.data[0].vehicleTypes = [];
                            }
                            Vthis.form.fill(response.data[0]);
                            Vthis.profilePic = false;
                            if(response.data[0].profile_pic){
                                Vthis.profilePic = Vthis.filesUrl+"users/"+response.data[0].profile_pic;
                            }

                        
                            var selectedRoles = new Array();
                            // console111.log(Vthis.profilePic);
                            if(response.data[0].role_ids){
                                if(!isNaN(response.data[0].role_ids)){
                                    response.data[0].role_ids = response.data[0].role_ids.toString();
                                }
                                
                                selectedRoles = response.data[0].role_ids.split(',');
                                
                                if(selectedRoles.length > 0){ 
                                    var frr = [];
                                    this.roles.forEach( (role) => {  
                                        if(selectedRoles.includes(role.id.toString())){ 
                                            frr.push(role.id);
                                        } 
                                    });
                                    Vthis.form.roles = frr;
                                }
                            } 
                            
                        
                            // if(response.data[0].user_access_level_ids){ 
                            //     var ids = response.data[0].user_access_level_ids.toString().split(',');
                            //     Vthis.form.access_levels = ids.map(Number); 
                            // }

                            Vthis.getAssignAblePermissions();
                        }
                    })
                    .catch((error) => {  
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },   
            async removeProfilePic(){ 
                var Vthis = this;  
                await Vthis.axios.post('deleteUserProfilePic',{id:this.form.id})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        if(response){
                            Vthis.profilePic = false;
                            Vthis.showMessage('Deleted Successfully','success');  
                        }
                        else{ 
                            Vthis.showMessage('Not deleted! Please try later','error'); 
                        }
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {   
                }) 
            },
            confirmFileDeletion($event){
                this.$confirm.require({
                    target: $event.currentTarget,
                    message: 'Are you sure you want to proceed?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        this.removeProfilePic();
                    },
                    reject: () => {
                        
                    }
                });
            },
		},
        watch : {
             
        }
}
</script>
 
