<template>
    <PlaceSearch   :ref="ref"
        :ready="ready"
        placeholder="Enter a location" 
        loading="Map is loading"
        :fallbackProcedure="fallbackProcedure"
        :zoom="zoom"
        :geolocation="geolocation"
        :manually="manually"
        :gps_timeout="9000"
        :address="address"
        @changed="getMapData"
        :class="mapAutoCompleteAbid"
        >
    </PlaceSearch> 
     
</template>
<script>
export default {
     data() {
        return { 
            ready: true,
            ref:'',
            fallbackProcedure: "manually",
            zoom: 17,
            geolocation: {
                // If GPS and Find by address fails then, map will be positioned by a default geolocation
                lat: 31.51106279999999,
                lng: 74.341932,
                zoom: 15,
                query: "Albania, Tirane", 
            },
            address: {
                query: "Albania, Tirane", //If GPS fails, Find by address is triggered
                zoom: 10,
            },
            manually: { 
                address_description: "lahore, Pakistan", 
                query: "",
                place_id: "",
                city: "lahore",
                country: "pakistan",
                lat: 41.3267905,
                lng: 19.8060475,
                state: "punjab",
                zip_code: "54000",
                zoom: 17, 
            },
            place: {},  
        }
     },  
     props:{
        ref11 : {
                type : String, 
            }, 
     },
     mounted(){ 
        this.ref = this.ref11;
        this.emitter.on("editLocation", (editLocation) => { 
            // console.log(editLocation);
            this.manually = editLocation;  
            setTimeout( () => {  
                document.getElementById('pac-input').value = editLocation.address_description;
            },1000);  
        }); 
     },
     methods: { 
        triggerReady() {
            this.fallbackProcedure = "manually";
            this.ready = true;
        },  
        getMapData(place) {    
            this.place = place; 
            this.$emit('locationChanged',place); 
        },
     }
           
}
</script>