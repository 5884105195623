<template>
    <BlockUI :blocked="blockComponent">
        <Sidebar :loading="loadingComponent"  v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form @submit.prevent="login" @keydown="form.onKeydown($event)"> 
                <div class="">
                    <div class="col-12 lg:col-12 mb-0">
                        <h4 class="mb-0">Manage Roles</h4> 
                        <p>You can add or update role here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="name" /> 
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" v-if="checkPermission(12,'dropdown')"> 
                                    <label>Access Levels<span v-if="permissions[12].includes('insert')" class="supporting-btn-link" @click="showAccessLevelForm=true;">+ Add New</span></label>
                                    <MultiSelect :filter="true" v-model="form.access_levels" :options="accessLevels" optionLabel="name" optionValue="id" placeholder="Select Access Levels" display="chip" class="full-width" />
                                    <HasError class="p-error" :form="form" field="access_levels" /> 
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Status </label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name" optionValue="id" placeholder="Select Status" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="status" />
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" v-if="this.userData['company_id'] == 1"> 
                                    <label>Is Shared with other companies?</label>
                                    <Dropdown v-model="form.is_shared" :options="SharedOptions" optionLabel="name" optionValue="id" placeholder="Is Shared?" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="is_shared" />
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Description</label>
                                    <Textarea placeholder="Role Description" v-model="form.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="form" field="description" />
                                </div> 
                            </div>  
                        </div> 
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <Tree :filter="true" :expandedKeys="expandableNodesKey" :value="treeValue" selectionMode="checkbox" v-model:selectionKeys="form.rolePermissions"></Tree>    
                                </div> 
                            </div>   
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                        <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                        <Button type="button" label="Cancel" icon="pi pi-times" class="p-button-raised p-button-lg p-button-warning mr-2" @click="closeForm"/>
                    </div>
                </div> 
            </form>
        </Sidebar> 
        <AccessLevelForm v-on:formClose="closeAccessLevelForm" v-if="showAccessLevelForm && (permissions[12].includes('insert'))" :recordId="0" :showForm="true" /> 
    </BlockUI>
    </template>
    <script>
    import AccessLevelForm from '../../components/AccessManagement/AccessLevelForm.vue'; 
    export default {
        data() {
                return { 
                    
                    form: new this.Form({ 
                        id: '',
                        name: '',
                        access_levels:'',
                        rolePermissions: null,
                        status:1,
                        is_shared:0,
                        description: '', 
                    }),
                    module_name:'Role',
                    treeValue: null, 
                    expandableNodesKey:{},
                    companiesSuperSuperAdmin:false, 
                    SharedOptions:[{id : 0, name : 'No'},{id : 1, name : 'Yes'}], 
                    isSaving:false,    
                    saveAndClose:false, 
                    formVisible:false,
                    loadingComponent:true,
                    blockComponent:true,
                    showAccessLevelForm : false,
                    accessLevels:[],
                    recId:0,
                } 
            },
            props:{
                showForm : {
                    type : Boolean,
                    required : true,
                },
                recordId :{
                    type : Number,
                },
            },
            components:{ 
                AccessLevelForm
            },
            mounted(){ 
                
                this.resetForm();
                this.formVisible = this.showForm;
                this.recId = this.recordId;   
                this.form.id = this.recordId;   
                this.getAllRequestData(); 

                if(this.$route.query.view){
                    this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
                    if(Object.keys(this.urlParams).length){
                        this.urlParams.forEach( (parameter) => {
                            if(parameter.view == 'add-role'){ 
                                this.selectedRecordId = parameter.id;
                                this.formVisible = true;
                                if(this.selectedRecordId){
                                    this.updateRecord();
                                } 
                            }  
                        })
                    }
                }
            }, 
            methods: { 
                closeAccessLevelForm(){
                    this.getAccessLevels();
                    this.showAccessLevelForm = false;
                }, 
                getAllRequestData(){ 
                    this.$axios.all([this.getRolePermissions()]) 
                    .then(() => {  
                        this.getAccessLevels();    
                    })  
                    .then(this.$axios.spread(() => {
                        this.updateRecord();
                        this.blockComponent = false;    
                    })); 
                }, 
                resetForm(){
                    this.form.reset();
                },
                async getAccessLevels(){  
                    var Vthis = this;  
                    await Vthis.axios.post('getAllAccessLevels',{dropdown:true})
                    .then( (response) => {  
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){  
                            this.accessLevels = response.data;   
                        }
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => {  
                    }) 
                },
                async save(){   
                    var Vthis = this; 
                    this.isSaving = true;
                    await this.form
                    .post(Vthis.$baseurl+'api/saveRole', Vthis.form)
                    .then((response) => {   
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            this.resetForm(); 
                            if(this.saveAndClose){   
                                this.saveAndClose = false;
                                setTimeout( () => { 
                                    this.closeForm();
                                },1000) 
                            }   
                            Vthis.showMessage('Saved successfully','success'); 
                        }
                    })
                    .catch((error) => {   
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        this.isSaving = false; 
                    }) 
                },
                loadForm(){
                    this.resetForm();
                    // this.showForm = true;
                },
                closeForm(){
                    this.$emit('formClose',true); 
                }, 
                async getRolePermissions(){ 
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getRolePermissions',{id:this.recId})
                    .then( (response) => { 
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){  
                            this.treeValue = response.data;  
                        }
                    })
                    .catch((error) => {   
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        this.loading = false; 
                    })  
                }, 
                async updateRecord(){ 
                    if(this.recId){
                        var Vthis = this;
                        this.loading = true;
                        await this.axios.post('getAllRoles',{id:this.recId })
                        .then( (response) => {   
                            let responseStatus = this.printResponseResult(response,false); 
                            if(responseStatus == 200){  
                                response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                                Vthis.form.fill(response.data[0]); 
                                Vthis.form.rolePermissions = response.data[0].rolePermissions;  
                                Vthis.getRolePermissions();
                                setTimeout(function(){
                                    if(Vthis.form.rolePermissions){
                                        Object.keys(Vthis.form.rolePermissions).forEach(key => {
                                            Vthis.expandableNodesKey = {...Vthis.expandableNodesKey,key};
                                        }); 
                                    }
                                },2000)  

                                if(response.data[0].role_access_level_ids){ 
                                    var ids = response.data[0].role_access_level_ids.toString().split(','); 
                                    Vthis.form.access_levels = ids.map(Number); 
                                }
                            }
                        })
                        .catch((error) => {   
                            this.printResponseResult(error);
                        })
                        .finally(() => { 
                            Vthis.loading = false;
                        })  
                    }
                },
                 
            },
            watch:{ 
            }
       
    }
    </script>