<template>
<BlockUI :blocked="blockComponent">
    <Sidebar :loading="loadingComponent" v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0 form-zindex'"> 
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{module_name}}</h3> 
                        <p>You can add or update user here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="name" /> 
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  v-if="checkPermission(16,'dropdown')"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Company</label>
                                    <Dropdown @change="get_subsidiaries()" v-model="form.company_id" :filter="true" :showClear="true" :options="companies" optionLabel="name" optionValue="id" placeholder="Select a Company" class="full-width" />
                                    <HasError class="p-error" :form="form" field="company_id" /> 
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  v-if="this.userData['company_id'] === 1"> 
                                    <label>Is Shared?</label>
                                    <Dropdown   v-model="form.shared" :options="SharedOptions" optionLabel="name" optionValue="id" placeholder="Select" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="shared" />
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Is Company Admin?</label>
                                    <Dropdown @change="selectAllSubsidiaries($event)" v-model="form.is_admin" :options="CompanyAdminOpt" optionLabel="name" optionValue="id" placeholder="Select" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="status" />
                                </div>  
                                <!-- <div v-if="!form.is_admin" class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Company Subsidiaries</label>
                                    <MultiSelect @change="checkSelectedSubsidiaries()" :filter="true" v-model="form.subsidiaries" :showClear="true" :options="subsidiaries" optionLabel="name" optionValue="id" placeholder="Select Subsidiaries" display="chip" class="full-width" />
                                    <HasError class="p-error" :form="form" field="subsidiaries" /> 
                                </div> -->
                                <div v-if="!form.is_admin && checkPermission(8,'dropdown')" class="col-12 lg:col-12 sm:col-12 mb-2" > 
                                    <label>Branchs</label>
                                    <MultiSelect :filter="true" v-model="form.branches" :showClear="true" :options="branches" optionLabel="company_branch_name" optionValue="company_branch_id" placeholder="Select  Branches" display="chip" class="full-width" />
                                    <HasError class="p-error" :form="form" field="branches" /> 
                                </div>
                                
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Status</label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name" optionValue="id" placeholder="Select Status" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="status" />
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Description</label>
                                    <Textarea placeholder="Description" v-model="form.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="form" field="description" />
                                </div>  
                            </div>  
                        </div> 
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6" >
                            <div>  
                                <Panel class="mb-3" header="Departments" :toggleable="true" v-if="checkPermission(9,'dropdown')">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2">   
                                        <div class="field-checkbox">
                                            <Checkbox @click="selectAllDepartments()" value="true" v-model="selectAllDept" :binary="true" />
                                            <label >Select All</label>
                                        </div>
                                        <HasError class="p-error" :form="form" field="departments" /> 
                                        <Listbox v-model="form.departments" filterPlaceholder="Select Departments" checkbox="true" :multiple="true" :filter="true" :showClear="true" :options="departments" optionLabel="name"  optionValue="company_dep_id" class="full-width" />
                                    </div> 
                                </Panel> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <div class="field-checkbox">
                                        <Checkbox value="true" v-model="form.individual_access" :binary="true" />
                                        <label >Individual Access Only</label>
                                    </div>
                                    <p>The user with individual access will be able to see only tagged records in the authorised mdoules.</p>
                                </div>
                            </div>   
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/>
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>     
                    </div>
                </div> 
            </form>
    </Sidebar>
</BlockUI>
</template>
<script>
 
export default {
    data() {
			return { 
                
                form: new this.Form({ 
                    id: '', 
                    name: '',
                    is_admin: 0,
                    company_id:0,
                    shared:0,
                    departments:[],
                    use_for_new_company_admin:false, 
                    individual_access:false,
                    subsidiaries:[],   
                    branches:[],
                    status:1, 
                    description: '',  
                }),
                module_name:'Access Levels',
                CompanyAdminOpt:[{id : 0, name : 'No'},{id : 1, name : 'Yes'}], 
                SharedOptions:[{id : 0, name : 'No'},{id : 1, name : 'Yes'}], 
                selectAllDept:false, 
                companies:[],
                subsidiaries:[],
                branches:[],
                departments:null, 
                isSaving:false,    
                saveAndClose:false, 
                formVisible:false,
                loadingComponent:true,
                blockComponent:true,
                recId:0,
			} 
		},
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
        },
        components:{ 
        },
        mounted(){ 
            this.resetForm();
            this.formVisible = this.showForm;
            this.recId = this.recordId;   
            this.getAllRequestData(); 
        }, 
        methods: { 
            getAllRequestData(){
                this.$axios.all([this.get_subsidiaries()])
                this.$axios.all([this.updateRecord()])
                this.$axios.all([this.getCompanies()]) 
                this.$axios.all([this.getDepartments()]) 
                .then(this.$axios.spread(() => {
                    this.blockComponent = false;  
                    this.getCompanyBranches();
                    
                })); 
            },
            selectAllDepartments(){ 
               if(!this.selectAllDept){
                   this.departments.forEach((dep) => {
                       this.form.departments.push(dep.company_dep_id);
                   })
               }
               else{
                   this.form.departments = [];
               }
            },
            async getDepartments(){
                var Vthis = this;  
                await Vthis.axios.post('getAllDepartments',{dropdown:true})
                .then( (response) => {   
                    this.departments = response.data;   
                })
                .catch((error) => {    
                    let message = Vthis.customError(error.response.data.message ); 
                    Vthis.showMessage(message,'error'); 
                })
                .finally(() => {  
                })
            },
            async getCompanyBranches(){
                var Vthis = this;  
                await Vthis.axios.post('getCompaniesBranches',{companies:this.form.subsidiaries,dropdown:true})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        if(response.data.length){
                            if(!this.recId){  
                                if(this.form.company_id){
                                    this.form.branches = [];
                                    response.data.forEach((branch) => { 
                                        this.form.branches.push(branch.company_branch_id);
                                    })
                                }
                            }  
                        }  
                        if(this.form.company_id){
                            this.branches = response.data;   
                        }
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                })
            },
            checkSelectedSubsidiaries(){
                this.getCompanyBranches();
                if(this.form.subsidiaries.length < this.subsidiaries.length){
                    this.form.is_admin = 0;
                    this.showMessage('Company admin must have access to all subsidiaries','warn'); 
                }
                else{
                    this.form.is_admin = 1;
                }
            },
            checkSelectedBranches(){
                // this.getCompanyBranches();
                if(this.form.branches.length < this.branches.length){
                    this.form.is_admin = 0;
                    this.showMessage('Company admin must have access to all branches','warn'); 
                }
                else{
                    this.form.is_admin = 1;
                }
            },
            selectAllSubsidiaries($event){
                 
                if($event.value){ 
                    if(this.subsidiaries.length > 0){ 
                        this.form.subsidiaries = [];
                        this.subsidiaries.forEach((rec) => {
                            this.form.subsidiaries.push(rec.id);
                        })  
                    }
                }
                else{ 
                    if(!this.recId){
                        this.form.subsidiaries = [];
                    }
                }   
                
                this.getCompanyBranches();
            },
            async get_subsidiaries(){ 
                if(this.form.company_id || this.recId){ 
                    var Vthis = this; 
                    await Vthis.axios.post('getCompanySubsidiaries',{id:Vthis.form.company_id,dropdown:true})
                    .then( (response) => {  
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            if(response.data){ 
                                if(!this.recId){ 
                                    this.form.subsidiaries = [];
                                    response.data.forEach((rec) => {
                                        this.form.subsidiaries.push(rec.id);
                                    })  
                                }    
                                this.subsidiaries = response.data;  
                            }
                        }
                    })
                    .catch((error) => {   
                        this.printResponseResult(error);
                    })
                    .finally(() => {  
                        this.getCompanyBranches();
                    })
                }
                else{
                    this.subsidiaries = [];
                    this.form.subsidiaries = [];
                    this.branches = [];
                    this.form.branches = [];
                }

                // this.getCompanyBranches();
            },
            async getCompanies(){
                var Vthis = this; 
                await Vthis.axios.post('getAllCompanies',{dropdown:true})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.companies = response.data; 
                        if(this.form.company_id==0){
                            this.form.company_id = response.data[0].id;
                        }
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {  
                })
            }, 
            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                await this.form
                .post(Vthis.$baseurl+'api/saveAccessLevel', Vthis.form)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response); 
                    if(responseStatus == 200){ 
                        // if(!this.recId){
                        //     this.resetForm();
                        // } 
                        // this.getRecords();
                        if(this.saveAndClose){   
                            this.saveAndClose = false;
                            setTimeout( () => {this.closeForm();},1000);
                        }   
                        
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false; 
                }) 
            },  
            loadForm(){
                this.resetForm();
                // this.showForm = true;
            },
            closeForm(){
                this.$emit('formClose',true); 
            },  
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.blockComponent = true;
                    await this.axios.post('getAllAccessLevels',{id:this.recId,dropdown:true})
                    .then( (response) => {    
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            
                            var subSid = response.data[0].subsidiaries; 
                            response.data[0].subsidiaries = [];
                            Vthis.form.fill(response.data[0]); 
                            
                            
                            setTimeout( () => { 
                                if(response.data[0].individual_access){ 
                                    this.form.individual_access = true;
                                }  
                                if(response.data[0].departments.length == this.departments.length){
                                    this.selectAllDept = true;
                                }
                                var frr = [];
                                this.subsidiaries.forEach( (sub) => {  
                                    if(subSid.includes(sub.id.toString())){ 
                                        frr.push(sub.id);
                                    } 
                                });
                                Vthis.form.subsidiaries = frr;
                            },1000);
                        }
                        
                    })
                    .catch((error) => {   
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },
             
		},
        watch:{ 
        }
   
}
</script>