<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="true" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">{{module_name}}</h3> 
                        <p>You can view the complete information of a user</p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body full-page-modal">  
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <TabView  @tab-click="loadTargetedTab($event)" class="tabview-custom" v-model:activeIndex="activeTab" :scrollable="true">
                                <TabPanel>
                                    <template #header>
                                        <i class="pi pi-info-circle"></i>
                                        <span>&nbsp; Profile Details</span>
                                    </template>
                                    <div> 
                                        <Panel class="col-12 lg:col-12 sm:col-12 mb-3" header="" :toggleable="false" style="float:left">
                                            <div class="col-12 lg:col-2 sm:col-2 mb-2 profileCardImg" style="float:left"> 
                                                <div class="mb-2" v-if="user.profile_pic">
                                                    <img alt="Profile Picture" :src="profilePic" >
                                                </div> 
                                                <div>
                                                    <Tag v-if="user.status" class="mb-2 span-100 font-14" severity="success" value="Active" rounded></Tag>
                                                    <Tag v-else class="mb-2 span-100 font-14" severity="danger" value="InActive" rounded></Tag>
                                                    <Tag v-if="user.user_type" class="mb-2 span-100 font-14" severity="warning" :value="user.user_type" rounded></Tag>
                                                </div>
                                            </div> 
                                            <div class="col-12 lg:col-10 sm:col-9 mb-3" style="float:left">
                                                <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Name: &nbsp;</strong><span v-if="user.name">{{user.name}}</span><span v-else>N/A</span></div>
                                                <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Email: &nbsp;</strong><span v-if="user.email">{{user.email}}</span><span v-else>N/A</span></div>
                                                <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Phone: &nbsp;</strong><span v-if="user.phone">{{user.phone}}</span><span v-else>N/A</span></div>
                                                <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Company: &nbsp;</strong><span v-if="user.company_name">{{user.company_name}}</span><span v-else>N/A</span></div>
                                                <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Subsidiary: &nbsp;</strong><span v-if="user.subsidiary_name">{{user.subsidiary_name}}</span><span v-else>N/A</span></div>
                                                <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Branch: &nbsp;</strong><span v-if="user.branch_name">{{user.branch_name}}</span><span v-else>N/A</span></div>
                                                <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Department: &nbsp;</strong><span v-if="user.department">{{user.department}}</span><span v-else>N/A</span></div>
                                                <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Designation: &nbsp;</strong><span v-if="user.designation">{{user.designation}}</span><span v-else>N/A</span></div>
                                                <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Roles: &nbsp;</strong><span v-if="user.roles_name">{{user.roles_name}}</span><span v-else>N/A</span></div>
                                                <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Access Levels: &nbsp;</strong><span v-if="user.access_levels_name">{{user.access_levels_name}}</span><span v-else>N/A</span></div> 
                                                <div class="col-12 lg:col-12 sm:col-12 mb-2 "  style="float:left"> <strong>Summary: &nbsp;</strong><span v-if="user.description">{{user.description}}</span><span v-else>N/A</span></div>
                                            </div>
                                        </Panel> 
                                    </div>
                                </TabPanel>
                                <TabPanel v-if="user_type=='employee'" :disabled="tabDisabled">
                                    <template #header> 
                                        <i class="pi pi-car"></i>
                                        <span>&nbsp; Orders Assigned</span>  
                                    </template> 
                                    <div> 
                                        <UserAssignedOrders :userId="recId" :userType="user_type" /> 
                                    </div>
                                </TabPanel>
                                <TabPanel   :disabled="tabDisabled">
                                    <template #header> 
                                        <i class="pi pi-car"></i>
                                        <span>&nbsp; Orders Placed</span>  
                                    </template> 
                                    <div> 
                                        <UserOrders  :userId="recId" :userEmail="userEmail" /> 
                                    </div>
                                </TabPanel> 
                                <TabPanel   :disabled="tabDisabled">
                                    <template #header> 
                                        <i class="pi pi-car"></i>
                                        <span>&nbsp; Invoices</span>  
                                    </template> 
                                    <div> 
                                        <UserOrderInvoices  :userId="recId"   /> 
                                    </div>
                                </TabPanel> 
                                <TabPanel  :disabled="tabDisabled">
                                    <template #header> 
                                        <i class="pi pi-dollar"></i>
                                        <span>&nbsp; Transactions</span>  
                                    </template> 
                                    <div>  
                                        <UserTransactions   :user_id="recId"  /> 
                                    </div>
                                </TabPanel> 
                                 
                            </TabView>  
                             
                        </div> 
                        
                    </div>
                </div> 
        </Sidebar> 
    </BlockUI>
</template>
<script> 
import UserTransactions from './UserTransactions.vue';
import UserOrderInvoices from './UserOrderInvoices.vue';
import UserOrders from './UserOrders.vue';
import UserAssignedOrders from './UserAssignedOrders.vue'; 
export default {
    data() {
			return {   
                user:[],
                module_name:'User Profile',  
                recId:0, 
                formVisible:false, 
                user_type:'employee',
			}
		},   
        props:{
            userType : {
                type : String, 
            },
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
        },
        components:{
            UserAssignedOrders, 
            UserOrders,
            UserOrderInvoices,
            UserTransactions
        },
		created() {   
            this.user_type = this.userType;  
		},
        computed: { 
        },
		mounted() {   
            this.formVisible = this.showForm; 
            this.recId = this.recordId;    
            this.updateRecord();  
		},
		methods: { 
            loadTargetedTab($event,currentTab=0){
                var tab = currentTab;
                if(!currentTab){
                    tab = $event.index;
                }
                this.showTab1 = false;
                this.showTab2 = false;
                this.showTab3 = false;
                this.showTab4 = false;
                this.showTab5 = false;
                this.showTab6 = false;
                this.showTab7 = false;
                let i = 1;
                do { 
                    if(tab === 1){
                        this.showTab1 = true;
                    }
                    if(tab === 2){
                        this.showTab2 = true;
                    }
                    if(tab === 3){
                        this.showTab3 = true;
                    }
                    if(tab === 4){
                        this.showTab4 = true;
                    }
                    if(tab === 5){
                        this.showTab5 = true;
                    }
                    if(tab === 6){
                        this.showTab6 = true;
                    }
                    if(tab === 7){
                        this.showTab7 = true;
                    }
                    i += 1;
                } while (i < 7);
            },
              
            closeForm(){ 
                this.$emit('UserProfileClose',true); 
            }, 
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getAllUsers',{id:this.recId})
                    .then( (response) => {   
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){  
                            Vthis.user = response.data[0];
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            
                            if(response.data[0].profile_pic){
                                Vthis.profilePic = Vthis.filesUrl+"users/"+response.data[0].profile_pic;
                            } 
                        }
                    })
                    .catch((error) => {  
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },   
		},
        watch : {
             
        }
}
</script>
 
