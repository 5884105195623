<template>
    <div class="grid">  
		<div class="col-12"> 
			<div class="card"> 
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start> 
                        <h3>Manage {{module_name}}</h3>  
                    </template>
					<template v-slot:end>  
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" /> 
                        <!-- <Button v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Send New'" :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" />  -->
                    </template> 
				</Toolbar> 
                
                <!-- <Toolbar class="pl-0 pr-0">
					<template v-slot:start> 
                        <Button v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Add New'" :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" /> 
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" /> 
                        <Button v-if="checkPermission(moduleId,'export')" v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSV($event)" />
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Remove Filters'" type="button" icon="pi pi-filter-slash" :label="showBtnLabels ? 'Remove Filters' : ''" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  @click="clearFilter()"/> 
                        <Button v-if="checkPermission(moduleId,'delete')" v-tooltip.top="'Delete Selected'" :label="showBtnLabels ? 'Delete Selected' : ''" icon="pi pi-trash" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-danger mr-2"  @click="deleteRecord" :disabled="!selectedRecords || !selectedRecords.length" />
					</template> 
                    <template v-slot:end> 
                        <Button  v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Helping Material'" :label="showBtnLabels ? 'Help' : ''" @click="showHelp = true" icon="pi pi-question" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  /> 
                    </template> 
				</Toolbar>  -->
				<DataTable :reorderableColumns="true" @columnReorder="onColReorder" :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="records" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters" filterDisplay="menu" :loading="loading" :filters="filters" responsiveLayout="scroll"
					:globalFilterFields="['send_to','subject','company', 'description','added_by', 'date_added', ]" :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords" selectionMode="multiple"
                    :scrollable="true" scrollHeight="400px">
                    
					<template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">  
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                            </span>
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                placeholder="Select Columns" style="width: 20em"/>
                        </div>
                    </template>
                    
                    <template #empty> 
                        <div class="table-message"> 
                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                        </div>
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>  
                    <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                    <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;':'max-width:5rem;flex-grow:1; flex-basis:160px;'"> 
                        <template  #body="{data}">
                            <img  v-if="col.field == 'company' && data.logo" :alt="data.company" :src="filesUrl+'companies/'+data.logo" class="tableProfileImg mr-2" />
                            <img  v-if="col.field == 'company' && !data.logo" :alt="data.company" :src="filesUrl+'defaults/default-logo.png'" class="tableProfileImg mr-2" />
                            <span v-if="col.field == 'company'">{{data.company}}</span>
                            <span v-if="col.field == 'send_to'">{{data.send_to}}</span>  
                            <span v-if="col.field == 'total_openings'">
                                <Tag v-if="data.total_openings < 1" severity="danger">Not Opened</Tag>
                                <Tag v-else severity="success">{{data.total_openings}} times<br>{{formatDateTime(data.last_opened)}}</Tag>
                            </span> 
                            <span v-if="col.field == 'cc_to'">{{data.cc_to}}</span> 
                            <span v-if="col.field == 'bcc_to'">{{data.bcc_to}}</span> 
                            <span v-if="col.field == 'subject'">{{shortStr(data.subject,0,50)}}</span> 
                            <span v-if="col.field == 'description'">{{data.description}}</span>   
                            <span v-else-if="col.field == 'date_added'">{{formatDate(data.date_added)}}</span>
                            <span v-else-if="col.field == 'added_by'">{{ data.added_by }}</span>
                             
                            <span v-else-if="col.field == 'action'">
                                <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                    <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                </div> 
                            </span>
                        </template>
                        <template #filter="{filterModel}">
                            <span v-if="col.field == 'company'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by company name"/>
                            </span> 
                            <span v-if="col.field == 'send_to'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Recipient"/>
                            </span>   
                             <span v-if="col.field == 'subject'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Subject"/>
                            </span>   
                            <span v-else-if="col.field == 'description'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Description"/>
                            </span> 
                            <span v-if="col.field == 'added_by'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Sender"/>
                            </span>  
                            <span v-else-if="col.field == 'date_added'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Sent Date"/>
                            </span> 
                        </template>   
                    </Column>   
				</DataTable>
			</div>
		</div>
        <Toast />
        <ConfirmDialog group="dialog" />
        <!-- form loading -->
        <OrderEmailView v-on:formClose="closeForm" v-if="showViewForm  && (checkPermission(moduleId,'view'))" :orderId="orderId" :recordId="selectedRecordId" :showForm="true" /> 
        <OrderEmailForm v-on:formClose="closeForm" v-if="showForm  && (checkPermission(moduleId,'insert') || checkPermission(moduleId,'update'))" :orderId="orderId" :recordId="selectedRecordId" :showForm="true" /> 
        <ModuleHelp v-on:closeHelp="showHelp =false" v-if="showHelp  && checkPermission(moduleId,'view')" :moduleId="moduleId"/>
        
    </div>
</template>
<script>
import OrderEmailForm from '../../components/Orders/OrderEmailForm.vue'; 
import OrderEmailView from '../../components/Orders/OrderEmailView.vue'; 
import {FilterMatchMode,FilterOperator} from 'primevue/api';  
export default {
    data() {
			return {  
                module_name:'Order Emails', 
                actions: [], 
                moduleId:44,
                showHelp : false, 
                isSaving:false, 
				records: null, 
				filters: null, 
				loading: true,  
				selectedRecords: null,
				columns: null,
                selectedColumns: null, 
                showForm:false,
                showViewForm:false,
                selectedRecordId: 0,  
                saveAndClose:false, 
			}
		}, 
        props:{ 
            orderId :{
                type : Number,
            },
        }, 
        components:{
            OrderEmailForm, 
            OrderEmailView
        },
		created() { 
            if(this.checkPermission(this.moduleId,'view')){
                this.actions.push({
						label: 'View',
						icon: 'pi pi-eye',
                        command: () => {
                            this.getClickedRecord('view');
                        }
					});
            }
            // if(this.checkPermission(this.moduleId,'delete')){
            //     this.actions.push({
			// 			label: 'Delete',
			// 			icon: 'pi pi-times',
            //             command: () => {
            //                 this.getClickedRecord('delete');
            //             }
			// 		});
            // }   
			this.initFilters();
            this.columns = [
                {field: 'action', header: 'Action'},
                {field: 'company', header: 'Company'},  
                {field: 'subject', header: 'Subject'},   
                {field: 'description', header: 'Description'},   
                {field: 'send_to', header: 'Sent To'}, 
                {field: 'total_openings', header: 'Total Openings'},
                {field: 'cc_to', header: 'CC To'},
                {field: 'bcc_to', header: 'BCC To'},
                {field: 'date_added', header: 'Sent Date'},
                {field: 'added_by', header: 'Sent By'},  
            ];
            this.selectedColumns = this.columns;
		},
        computed: { 
        },
		mounted() { 

            //THIS CODE IS TO TABLE COLUMNS SETTINGS
            this.axios.post('getAllModuleColumnSettings', { 'module_id': this.moduleId })
            .then((response) => {
                if(response.data){
                    if(Object.keys(response.data).length){
                        this.columns =[]; 
                        this.selectedColumns = [];
                        response.data.forEach( (column) => { 
                            this.columns.push({'field':column.column_field, 'header': column.column_head});
                            if(column.display){
                                this.selectedColumns.push({'field':column.column_field, 'header': column.column_head});
                            } 
                        }); 
                    }
                }
            })
            .catch(() => {
            })
            .finally(() => {   
            })
            //END



			this.getRecords();  
            if(this.$route.query.view){
                this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
                if(Object.keys(this.urlParams).length){
                    this.urlParams.forEach( (parameter) => {
                        if(parameter.view == 'order-email-view'){
                            this.selectedRecordId = parameter.id;
                            this.showViewForm = true;
                        }  
                    })
                }
            }
		},
		methods: {  
            onColReorder(event){ 
                this.columns = this.tableColumnOrderChange(event,this.columns);  
                this.saveTableColumnOrderChange(this.moduleId,this.columns,this.selectedColumns);
            },
            onToggle(value) {  
                //this.selectedColumns = this.columns.filter(col => value.includes(col)); 
                this.selectedColumns = []; 
                let newColumns = []; 
                this.columns.filter((col) => {
                    if(value.find(x => x.field === col.field)){
                        newColumns.push(col);
                        return col;
                    } 
                }); 
                this.selectedColumns = newColumns; 
                this.saveTableColumnOrderChange(this.moduleId,this.columns,this.selectedColumns);
            },
            getClickedRecord(action){
                if(action == 'delete'){ 
                    this.deleteRecord(); 
                }
                else if(action == 'view'){
                    this.showViewForm = true; 
                    this.addViewInURL({view: 'order-email-view', id:this.selectedRecordId}); 
                }  
                else if(action == 'update'){
                    this.showForm = true; 
                }
            },
            toggleRecordMenu(event) {
                this.selectedRecordId = event.currentTarget.getAttribute('recordId');  
				this.$refs.menu.toggle(event);
			},
            loadForm(){ 
                this.selectedRecordId = 0;
                this.showForm = true; 
            },
            closeForm(){ 
                if(!this.selectedRecordId){
                    this.getRecords();
                }
                this.removeViewFromURL('order-email-view');
                this.showForm = false;
                this.showViewForm = false; 
            }, 
            getRecords(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllOrderEmails',{'sent_for_table_id': this.orderId,'sent_for_table':'orders'})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        if(response.data.length){
                            response.data.forEach( (record) => {
                                if(record.cc_to != '' && record.cc_to){
                                    record.cc_to = JSON.parse(record.cc_to).join(",\n"); 
                                }
                                if(record.bcc_to != '' && record.bcc_to){
                                    record.bcc_to = JSON.parse(record.bcc_to).join(",\n"); 
                                }
                            })
                        }
                        this.records = response.data;   
                        this.loading = false; 
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            }, 
            viewRecord(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllOrderEmails',{id:this.selectedRecordId})
                .then( (response) => {  
                    this.printResponseResult(response,false);  
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            },
            // deleteRecord(){ 
            //     var Vthis = this;
            //     this.$confirm.require({
            //         group: 'dialog',
            //         header: 'Confirmation',
            //         message: 'Are you sure you want to delete?',
            //         icon: 'pi pi-exclamation-triangle',
            //         accept: () => { 
            //             Vthis.loading = true;  
            //             let DeleteAbleRecords = [];
                         
            //             if(Vthis.selectedRecords){
            //                 Vthis.selectedRecordId = '';
            //                 Vthis.selectedRecords.forEach((data) => {
            //                     DeleteAbleRecords.push(data.id);
            //                 })
            //             } 
            //             else{
            //                 DeleteAbleRecords.push(Vthis.selectedRecordId);
            //             } 
                         
            //             // let filterdRecords = Vthis.records.filter(val => Vthis.selectedRecords.includes(val)); 
            //             // filterdRecords.forEach((data) => {
            //             //     DeleteAbleRecords.push(data.id);
            //             // })  

            //             Vthis.axios.post('deleteOrderEmail',{id:DeleteAbleRecords})
            //             .then( () => {   
            //                 Vthis.getRecords();
            //                 Vthis.showMessage('Record deleted successfully','success'); 
            //             })
            //             .catch((error) => {   
            //                 let message = Vthis.customError(error.response ); 
            //                 Vthis.showMessage(message,'error'); 
            //             })
            //             .finally(() => { 
            //                 Vthis.loading = false;
            //             })
            //         },
            //         reject: () => {
            //             // this.loading = false;
            //         }
            //     }); 
            // },
            exportCSV() {
                this.$refs.dt.exportCSV();
            },
          
            
            initFilters() {
				this.filters = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'send_to': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'subject': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'description': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
				 	'company': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'added_by': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
				 	'date_added': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}, 
				}
			},
			clearFilter() {
				this.initFilters();
			}, 
            isRTL() {
				return this.$appState.RTL;
			}
		},
        watch : { 
        }
}
</script>
 
