<template>
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"
            :showCloseIcon="false" @hide="closeForm" position="right"
            :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data">
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{ module_name }}</h3>
                        <p>You can add or update {{ module_name }} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">
                        <div class="col-6 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left">
                                    <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name" type="text"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="name" />
                                </div>

                                <div class="col-12 lg:col-6 sm:col-6 mb-2" style="float:left"
                                    v-if="checkPermission(16, 'dropdown')">
                                    <label><span class="pi pi-star-fill important mr-1"></span>Company</label>
                                    <Dropdown CompanyLoading :filter="true" :showClear="true" v-model="form.company_id"
                                        :options="companies" optionLabel="name" optionValue="id"
                                        :placeholder="CompanyLoading ? 'Loading...' : 'Select Company'"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="company_id" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-6 mb-2" style="float:left"
                                    v-if="checkPermission(36, 'dropdown')">
                                    <label><span class="pi pi-star-fill important mr-1"></span>Vehicle Types<span
                                            v-if="permissions[36].includes('insert')" class="supporting-btn-link"
                                            @click="showVehicleTypesForm = true;">+ Add New</span></label>
                                    <Dropdown @change="getVehicleTypeData()" VehicleTypesLoading v-model="form.vehicleTypes"
                                        :filter="true" :showClear="true" :options="vehicle_types" optionLabel="name"
                                        optionValue="id"
                                        :placeholder="VehicleTypesLoading ? 'Loading...' : 'Select Vehicle Type'"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="vehicleTypes" />
                                </div>
                                <!-- <div class="col-12 lg:col-6 sm:col-12 mb-2 float-left" >
                                    <label>Vehicle Fix Price </label>
                                    <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'" locale="en-US"  placeholder="Price" v-model="form.vehicle_fixed_amount" :min="0" showButtons class="full-width"/>  
                                    <HasError class="p-error" :form="form" field="vehicle_fixed_amount" />
                                </div> -->
                                <div class="col-12 lg:col-6 sm:col-6 mb-2" style="float:left">
                                    <label>Make</label>
                                    <InputText id="make" placeholder="Make" v-model="form.make" type="text"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="make" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-6 mb-2" style="float:left">
                                    <label>Model</label>
                                    <InputText id="model" placeholder="Model" v-model="form.model" type="text"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="model" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-6 mb-2" style="float:left">
                                    <label>Registration</label>
                                    <InputText id="registration" placeholder="Registration" v-model="form.registration"
                                        type="text" class="full-width" />
                                    <HasError class="p-error" :form="form" field="registration" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-6 mb-2" style="float:left">
                                    <label>Color</label>
                                    <InputText id="color" placeholder="Color" v-model="form.color" type="text"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="color" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-6 mb-2" style="float:left">
                                    <label>Chassis Number</label>
                                    <InputText id="chassis_number" placeholder="Chassis Number"
                                        v-model="form.chassis_number" type="text" class="full-width" />
                                    <HasError class="p-error" :form="form" field="chassis_number" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-6 mb-2" style="float:left">
                                    <label>Sort Order</label>
                                    <InputText id="sort_order" placeholder="Sort Order" v-model="form.sort_order"
                                        showButtons :min="0" :max="100" :step="1" type="number" class="full-width" />
                                    <HasError class="p-error" :form="form" field="sort_order" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-6 mb-2" style="float:left">
                                    <label>Reference</label>
                                    <InputText id="reference" placeholder="Reference" v-model="form.reference" type="text"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="reference" />
                                </div>

                                <div class="col-12 lg:col-6 sm:col-6 mb-2" style="float:left">
                                    <label>Status</label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name"
                                        optionValue="id" placeholder="Select Status" class="full-width" />
                                    <HasError class="p-error" :form="form" field="status" />
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left">
                                    <label>About/Description</label>
                                    <Textarea placeholder="Description" v-model="form.description" rows="5" cols="30"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="description" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <Panel class="mb-3 full-width" :header="module_name + ' images'" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                        <FileUpload name="images" :showUploadButton="false" :customUpload="true"
                                            @select="myUploader" :multiple="true" accept="image/*" :maxFileSize="1000000">
                                        </FileUpload>
                                    </div>
                                </Panel>
                                <Panel v-if="images.length" class="mb-3 full-width" header="Current images"
                                    :toggleable="true" style="float:left">
                                    <div class="col-4 lg:col-4 sm:col-4 mb-2 text-center" v-for="(image, key) in images"
                                        :key="key" style="float:left">
                                        <div>
                                            <img alt="image" class="form-image"
                                                :src="filesUrl + 'vehicles/' + image.original_name">
                                        </div>
                                        <div>
                                            <ConfirmPopup group="popup"></ConfirmPopup>
                                            <Button ref="popup" @click="confirmFileDeletion($event, image.id)"
                                                icon="pi pi-times" label="Remove"
                                                class="p-button-danger p-button-raised p-button-md"
                                                style="margin-left: .5em" />
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()"
                                    class="p-button-raised p-button-lg p-button-success mr-2" />
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check"
                                    @click="saveAndClose = true, save()"
                                    class="p-button-raised p-button-lg p-button-info mr-2" />
                                <Button type="button" label="Reset" icon="pi pi-refresh"
                                    class="p-right p-button-raised p-button-lg p-button-warning mr-2"
                                    @click="resetForm()" />
                            </template>
                            <template v-slot:end>
                                <Button type="button" label="Cancel" icon="pi pi-times"
                                    class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm" />
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </form>
        </Sidebar>
    </BlockUI>
    <VehicleTypesForm v-on:formClose="closeVehicleTypeForm"
        v-if="showVehicleTypesForm && (permissions[36].includes('insert'))" :recordId="0" :showForm="true" />
</template>
<script>
import VehicleTypesForm from '../../components/Vehicles/VehicleTypesForm.vue';
export default {
    data() {
        return {
            form: new this.Form({
                id: '',
                company_id: 0,
                name: '',
                make: '',
                model: '',
                sort_order: 0,
                reference: '',
                vehicle_fixed_amount: 0,
                color: '',
                registration: '',
                chassis_number: '',
                status: 1,
                description: '',
                vehicleTypes: [],
                vehicleImages: [],
            }),
            VehicleTypesLoading: false,
            CompanyLoading: false,
            module_name: 'Vehicles',
            isSaving: false,
            saveAndClose: false,
            recId: 0,
            loadingComponent: true,
            formVisible: false,
            companies: [],
            vehicle_types: [],
            images: [],
            showVehicleTypesForm: false,
        }
    },
    props: {
        showForm: {
            type: Boolean,
            required: true,
        },
        recordId: {
            type: Number,
        },
    },
    created() {
    },
    components: {
        VehicleTypesForm,
    },
    computed: {
    },
    mounted() {
        this.resetForm();
        this.formVisible = this.showForm;
        this.recId = this.recordId;
        this.getAllRequestData();
    },
    methods: {
        closeVehicleTypeForm() {
            this.getVehicleTypes();
            this.showVehicleTypesForm = false;
        },
        getAllRequestData() {
            this.$axios.all([this.getVehicleTypes()])
                .then(() => {
                    this.getCompanies();
                })
                .then(() => {
                    this.updateRecord();
                })
                .then(() => {
                    this.blockComponent = false;
                }).catch(() => {
                    //    this.getAllRequestData();
                })
                .finally(() => {
                });
        },
        async getVehicleTypes() {
            var Vthis = this;
            Vthis.VehicleTypesLoading = true;
            await this.axios.post('getAllVehicleTypes', { dropdown: true })
                .then((response) => {
                    this.vehicle_types = response.data;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {
                    Vthis.VehicleTypesLoading = false;
                })
        },
        async getCompanies() {
            var Vthis = this;
            Vthis.CompanyLoading = true;
            await this.axios.post('getAllCompanies', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.companies = response.data;
                        if (this.form.company_id == 0) {
                            this.form.company_id = response.data[0].id;
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.CompanyLoading = false;
                })
        },

        async getVehicleTypeData() {

            if (this.form.vehicleTypes > 0) {
                var Vthis = this;
                Vthis.CompanyLoading = true;
                await this.axios.post('getAllVehicleTypes', { id: this.form.vehicleTypes })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            this.form.vehicle_fixed_amount = response.data[0].vehicle_fixed_amount;
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.CompanyLoading = false;
                    })
            }
        },

        resetForm() {
            this.form.reset();
        },
        async save() {
            var Vthis = this;
            this.isSaving = true;
            await this.form
                .post(Vthis.$baseurl + 'api/saveVehicle', Vthis.form)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        // this.getRecords();
                        if (this.saveAndClose) {
                            this.saveAndClose = false;
                            setTimeout(() => { this.closeForm(); }, 1000);
                        }
                        Vthis.showMessage('Saved successfully', 'success',);
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false;
                })
        },
        loadForm() {
            this.resetForm();
        },
        closeForm() {
            this.$emit('formClose', true);
        },
        myUploader(event) {
            this.form.image = event.files;
        },
        async updateRecord() {
            if (this.recId > 0) {
                var Vthis = this;
                this.loading = true;
                await this.axios.post('getAllVehicles', { id: this.recId })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            Vthis.form.fill(response.data[0]);
                            if (response.data[0].images) {
                                Vthis.images = response.data[0].images;
                            }

                            if (response.data[0].vehicle_type_ids) {
                                var typeIds = '';
                                response.data[0].vehicle_type_ids = response.data[0].vehicle_type_ids.toString();
                                if (response.data[0].vehicle_type_ids.includes(',')) {
                                    typeIds = response.data[0].vehicle_type_ids.split(',');
                                }
                                else {
                                    typeIds = [response.data[0].vehicle_type_ids];
                                }

                                if (typeIds.length > 0) {
                                    Vthis.form.vehicleTypes = parseInt(typeIds[0]);
                                    // var frr = [];
                                    // this.vehicle_types.forEach( (type) => {  
                                    //     if(typeIds.includes(type.id.toString())){ 
                                    //         frr.push(type.id);
                                    //     } 
                                    // });
                                    // Vthis.form.vehicleTypes = [];
                                    // Vthis.form.vehicleTypes = frr;
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.loading = false;
                    })
            }
        },
        async removeimage($id) {
            var Vthis = this;
            await Vthis.axios.post('deleteVehicleImage', { id: this.form.id, image: $id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        if (response) {
                            Vthis.showMessage('Deleted Successfully', 'success');
                            Vthis.images = Vthis.images.filter((image) => {
                                if (image.id != $id) {
                                    return image;
                                }
                            });
                        }
                        else {
                            Vthis.showMessage('Not deleted! Please try later', 'error');
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                })
        },
        confirmFileDeletion($event, $id) {
            this.$confirm.require({
                target: $event.currentTarget,
                message: 'Are you sure you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.removeimage($id);
                },
                reject: () => {

                }
            });
        }

    },
    watch: {

    }
}
</script>
 
