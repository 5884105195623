<template>
     
        <form  @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
            <div class="mt-5 mb-0">
                <!-- <div class="col-12 lg:col-12 mb-0">
                    <h3 class="mb-0">Manage {{module_name}}</h3> 
                    <p>You can add or update {{module_name}} here. </p>
                </div>
                <Divider class="mt-0" /> -->
                <div class="grid modal-body">  
                    <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                        <div> 
                            <div class="col-12 lg:col-2 sm:col-12 mb-2"  style="float:left"> 
                                <label><span class="pi pi-star-fill important mr-1"></span>Repitition Status</label>
                                <Dropdown :filter="true" :showClear="true"  v-model="form.repitition_status" :options="statuses" optionLabel="name" optionValue="id"  :placeholder="'Select Status'" class="full-width" style="float:left"/>
                                <HasError class="p-error" :form="form" field="repitition_status" />
                            </div>
                            <div class="col-12 lg:col-2 sm:col-12 mb-2"  style="float:left"> 
                                <label><span class="pi pi-star-fill important mr-1"></span>Auto Payments</label>
                                <Dropdown :filter="true" :showClear="true"  v-model="form.auto_payments" :options="statuses" optionLabel="name" optionValue="id"  :placeholder="'Select Status'" class="full-width" style="float:left"/>
                                <HasError class="p-error" :form="form" field="auto_payments" />
                            </div> 
                            <div class="col-12 lg:col-4 sm:col-12 mb-2"  style="float:left"> 
                                <label><span class="pi pi-star-fill important mr-1"></span>Tag Invoice</label>
                                <Dropdown :filter="true" :showClear="true"  v-model="form.invoice_id" :options="order_invoices" optionLabel="invoice_number" optionValue="id"  :placeholder="'Select Invoice'" class="full-width" style="float:left"/>
                                <p><span style="color:red">**</span>Travel datetime will be auto update in the tagged invoice</p>
                                <HasError class="p-error" :form="form" field="invoice_id" />
                            </div> 
                            
                            <Divider />
                            <!-- <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                <p>Traveling Repititions </p>
                            </div>  -->
                            <div class="col-12 lg:col-2 sm:col-12 mb-2"  style="float:left">  
                                <label><span class="pi pi-star-fill important mr-1"></span>Traveling Repititions</label>
                                <InputNumber :input="frequencyChanged()" id="frequency" v-model="form.frequency" showButtons suffix=" times"  :min="0"  style="float:left" locale="en-US"  class="_full-width"/> 
                                <HasError class="p-error" :form="form" field="frequency" /> 
                            </div>  
                            <div class="col-12 lg:col-2 sm:col-12 mb-2"  style="float:left">  
                                <label><span class="pi pi-star-fill important mr-1"></span>Frequency</label>
                                <Dropdown :filter="true" :showClear="true"  v-model="form.frequency_type" :options="frequencies" optionLabel="name" optionValue="id"  :placeholder="'Select Frequency Type'"  class="full-width" style="float:left"/>
                                <HasError class="p-error" :form="form" field="frequency_type" />
                            </div> 
                            <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left">  </div> 
                            <div v-if="form.frequency_type == 'Daily'">
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left">
                                    <p><strong>**NOTE**</strong><br>Traveling schedule will repeat every <strong>day</strong> on these times</p>
                                </div> 
                                <div v-for="(val,key) in form.daily" :key="key" class="col-12 lg:col-2 sm:col-12 mb-2"  style="float:left">  
                                    <label>Schedule {{key+1}}</label>
                                    <Calendar :dateFormat="calendarFormat()" :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  id="time12" v-model="form.daily[key]" :timeOnly="true"  class="full-width"/>
                                    <HasError class="p-error" :form="form" field="daily" /> 
                                </div>  
                            </div> 
                            <div v-if="form.frequency_type == 'Weekly'">
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left">
                                    <p><strong>**NOTE**</strong><br>Traveling schedule will repeat every <strong>week</strong> on these days and time</p>
                                </div> 
                                <div v-for="(val,key) in form.weekly" :key="key" class="col-12 lg:col-2 sm:col-12 mb-2"  style="float:left">  
                                    <label>Schedule {{key+1}}</label>
                                    <Dropdown :filter="true" :showClear="true"  v-model="form.weekly[key].day" :options="days" optionLabel="name" optionValue="id"  :placeholder="'Select Day'"  class="full-width" style="float:left"/>
                                    <Calendar :dateFormat="calendarFormat()" :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  id="time12" v-model="form.weekly[key].time" :timeOnly="true"  class="full-width"/>
                                </div>  
                            </div> 
                            <div v-if="form.frequency_type == 'Monthly'">
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left">
                                    <p><strong>**NOTE**</strong><br>Traveling schedule will repeat every <strong>month</strong> on these dates and time</p>
                                </div> 
                                <div v-for="(val,key) in form.monthly" :key="key" class="col-12 lg:col-2 sm:col-12 mb-2"  style="float:left">  
                                    <label>Schedule {{key+1}}</label>
                                    <!-- <Dropdown :filter="true" :showClear="true"  v-model="form.monthly[key].month" :options="months" optionLabel="name" optionValue="id"  :placeholder="'Select Month'"  class="full-width" style="float:left"/> -->
                                    <Calendar  :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  id="time12" v-model="form.monthly[key]" :timeOnly="false" :showTime="true"   dateFormat="dd"  :showSeconds="false"  class="full-width"/>
                                </div>  
                            </div>
                            <div v-if="form.frequency_type == 'Yearly'">
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left">
                                    <p><strong>**NOTE**</strong><br>Traveling schedule will repeat every <strong>year</strong> on these date and time</p>
                                </div> 
                                <div v-for="(val,key) in form.yearly" :key="key" class="col-12 lg:col-2 sm:col-12 mb-2"  style="float:left">  
                                    <label>Schedule {{key+1}}</label>
                                    <!-- <Dropdown :filter="true" :showClear="true"  v-model="form.yearly[key].month" :options="months" optionLabel="name" optionValue="id"  :placeholder="'Select Month'"  class="full-width" style="float:left"/> -->
                                    <Calendar  :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  id="time122" v-model="form.yearly[key]" :timeOnly="false" :showTime="true"  dateFormat="dd/mm"  :showSeconds="false"  class="full-width"/>
                                </div>  
                            </div>
                            <Divider />
                            <div> 
                                <!-- <div class="col-12 lg:col-4 sm:col-12 mb-2"  style="float:left">  
                                    <label>Generate Invoice <InputNumber id="invoice_generation_hours" v-model="form.invoice_generation_hours" showButtons suffix=" hours"  :min="0"  style="float:left" locale="en-US"  class="full-width"/>  hours before every schedule </label>
                                    <Dropdown :filter="true" :showClear="true"  v-model="form.yearly[key].month" :options="months" optionLabel="name" optionValue="id"  :placeholder="'Select Month'"  class="full-width" style="float:left"/>  
                                    <Calendar :dateFormat="calendarFormat()" :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  id="time122" v-model="form.yearly[key]" :timeOnly="false" :showTime="true"  dateFormat="dd/mm"  :showSeconds="false"  class="full-width"/>  
                                </div>   -->
                                <div class="col-12 lg:col-2 sm:col-12 mb-2"  style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Start Date</label>
                                    <Calendar :dateFormat="calendarFormat()" :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  :showButtonBar="true" id="start_date" @date-select="startDateTimeSelected($event)"    v-model="form.start_date" :showTime="false" :showSeconds="false" class="full-width"/> 
                                    <HasError class="p-error" :form="form" field="start_date" />
                                </div>
                                <div class="col-12 lg:col-2 sm:col-12 mb-2"  style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>End Date</label>
                                    <Calendar :dateFormat="calendarFormat()" :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  :showButtonBar="true" id="end_date" @date-select="endDateTimeSelected($event)" :minDate="minDateTime"   v-model="form.end_date" :showTime="false" :showSeconds="false" class="full-width"/> 
                                    <HasError class="p-error" :form="form" field="end_date" />
                                </div>
                                <!-- <Divider /> -->
                                <Divider />
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left">
                                    <label>Exceptions (Schedule will not run on these dates)</label>
                                    <Calendar :dateFormat="calendarFormat()" inputId="multiple" v-model="form.exceptions" selectionMode="multiple" class="full-width"  :manualInput="false" :minDate="minDateTime" :maxDate="maxDateTime" />
                                </div>
                            </div>
                            <!-- <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                <label>Description</label>
                                <Textarea placeholder="description" v-model="form.description" rows="5" cols="30" class="full-width" />
                                <HasError class="p-error" :form="form" field="description" />
                            </div>   -->
                        </div>  
                    </div>  
                </div>
                <Divider />
                <div class="col-12 lg:col-12">
                    <Toolbar class="pl-0 pr-0">
                        <template v-slot:start>
                            <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                            <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                            <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/>
                        </template> 
                    </Toolbar>   
                </div>
            </div> 
        </form>
			 
        <Toast />
        <ConfirmDialog group="dialog" />
        <ModuleHelp v-on:closeHelp="showHelp =false" v-if="showHelp  && checkPermission(moduleId,'view')" :moduleId="moduleId"/>
        
 
</template>
<script> 
// import {FilterMatchMode,FilterOperator} from 'primevue/api';  
export default {
    data() {
			return { 
                form: new this.Form({ 
                    id: '', 
                    invoice_id:0, 
                    order_id:0, 
                    repitition_status: 0,   
                    auto_payments: 0,  
                    invoice_generation_hours:0, 
                    frequency: 0,   
                    start_date: new Date(),
                    end_date: new Date(),
                    daily:[],
                    weekly:[],
                    monthly:[],
                    yearly:[],
                    exceptions:[],
                    frequency_type:0,  
                    description: '',    
                }), 
                
                
                
                frequency:0,
                saveAndClose:false, 
                recId:0,
                invoiceId:0,
                loadingComponent:true,
                formVisible:false, 
                order_invoices:[],
                statuses:[{name:'On',id:1},{name:'Off',id:0}],  
                frequencies: [{name:'Yearly',id:'Yearly'},{name:'Monthly',id:'Monthly'},{name:'Weekly',id:'Weekly'},{name:'Daily',id:'Daily'}],  
                days: [{name:'Monday',id:'Monday'},{name:'Tuesday',id:'Tuesday'},{name:'Wednesday',id:'Wednesday'},{name:'Thursday',id:'Thursday'},{name:'Friday',id:'Friday'},{name:'Saturday',id:'Saturday'},{name:'Sunday',id:'Sunday'}],  
                months: [{name:'January',id:'January'},{name:'February',id:'February'},{name:'March',id:'March'},{name:'April',id:'April'},{name:'May',id:'May'},{name:'June',id:'June'},{name:'July',id:'July'},{name:'August',id:'August'},{name:'September',id:'September'},{name:'October',id:'October'},{name:'November',id:'November'},{name:'December',id:'December'}],  

                maxDateTime:'',
                minDateTime:'',
                module_name:'Order Schedules', 
                actions: [], 
                order_id:0,
                moduleId:54,
                showHelp : false, 
                isSaving:false, 
				  
			}
		}, 
        props:{ 
            orderId :{
                type : Number,
            },
        }, 
        components:{ 
        },
		created() {  
		},
        computed: { 
        },
		mounted() {   
            this.form.order_id = this.orderId;
            this.getAllRequestData();  
		},
		methods: { 
            startDateTimeSelected($event){ 
                this.form.end_date =  ''; 
                var selectedDate = new Date($event);   
                this.minDateTime = selectedDate;  
                this.form.end_date = new Date($event);   
                // this.form.start_date = (selectedDate.getMonth()+1)+'/'+(selectedDate.getDate())+'/'+selectedDate.getFullYear();
                this.form.end_date = this.form.start_date; 
            },
            endDateTimeSelected($event){  
                var selectedDate = new Date($event); 
                this.maxDateTime = selectedDate;   
                // this.form.end_date = (selectedDate.getMonth()+1)+'/'+(selectedDate.getDate())+'/'+selectedDate.getFullYear();
            },
            
            frequencyChanged(){ 
                if(this.form.frequency >= 0){ 
                   this.initFrequencyTypeVar();
                }
            },

            initFrequencyTypeVar(){
                if(this.form.frequency && this.form.frequency > 0){
                    var i = 0; 
                    if(this.form.frequency > this.frequency){
                        for(i=0; i < (this.form.frequency - this.frequency); i++){  
                            if(this.form.yearly && this.form.yearly.length < this.form.frequency){
                                this.form.yearly.push('01/01 00:00');
                            }
                            if(this.form.monthly && this.form.monthly.length < this.form.frequency){
                                this.form.monthly.push('01 00:00');
                            }
                            if(this.form.weekly && this.form.weekly.length < this.form.frequency){
                                this.form.weekly.push({'day':'','time':'00:00'});
                            }
                            if(this.form.daily && this.form.daily.length < this.form.frequency){
                                this.form.daily.push('00:00');
                            }
                            
                            // this.form.yearly.push('01/01 00:00');
                            // this.form.monthly.push('01 00:00');
                            // this.form.weekly.push({'day':'','time':'00:00'});
                            // this.form.daily.push('00:00');
                        }
                    }
                    else{
                        for(i=0; i< (this.frequency - this.form.frequency); i++){
                            this.form.yearly.pop();
                            this.form.monthly.pop();
                            this.form.weekly.pop();
                            this.form.daily.pop();
                        }
                    } 
                    this.frequency = this.form.frequency;
                }
                else{
                    this.form.daily = [];
                    this.form.weekly = [];
                    this.form.monthly = [];
                }
            },
            
            getAllRequestData(){   
                this.$axios.all([this.getOrderSchedule()])  
                .then(() => {  
                    this.getOrderInvoices();     
                })  
                .catch((error) => {     
                    console.log(error);
                })
                .finally(() => {  
                }); 
            }, 
            async getOrderSchedule(){  
                var Vthis = this;
                Vthis.ProductCategoriesLoading = true;
                await this.axios.post('getOrderSchedule',{order_id:this.form.order_id})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        // this.frequency = this.scheduleData.frequency;
                        if(response.data.order_schedule){
                            if(response.data.order_schedule.order_id < 1){
                                response.data.order_schedule.order_id = this.orderId;
                            }
                            Vthis.scheduleData = response.data.order_schedule; 
                            Vthis.form.fill(response.data.order_schedule);   
                            this.minDateTime = new Date(Vthis.form.start_date);
                            this.maxDateTime = new Date(Vthis.form.end_date); 
                            if(this.form.exceptions && this.form.exceptions != ''){ 
                                this.form.exceptions = this.form.exceptions.map( (date) => {
                                    return new Date(date);
                                }) 
                            }
                            // var startDate = new Date(Vthis.form.start_date);
                            // var endDate = new Date(Vthis.form.end_date);
                            // Vthis.form.start_date = (startDate.getMonth()+1)+'/'+(startDate.getDate())+'/'+startDate.getFullYear(); 
                            // Vthis.form.end_date = (endDate.getMonth()+1)+'/'+(endDate.getDate())+'/'+endDate.getFullYear(); 
                            // Vthis.form.end_date.setDate(Vthis.form.end_date.getDate());
                            setTimeout( () => {
                                this.initFrequencyTypeVar();
                            },1000);
                        } 
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    Vthis.ProductCategoriesLoading = false;
                })  
            }, 
            async getOrderInvoices(){  
                var Vthis = this;
                this.loading = true;
                await this.axios.post('getAllOrderInvoices',{invoice_for:'order','invoice_for_id':this.orderId})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){  
                        this.order_invoices = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    Vthis.loading = false;
                })  
            },  
            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                await this.form
                .post(Vthis.$baseurl+'api/saveOrderTravelingSchedule', Vthis.form)
                .then((response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){  
                        Vthis.showMessage('Saved successfully','success',); 
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false; 
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            }, 
           
		},
        watch : { 
        }
}
</script>
 
