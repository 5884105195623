<template>
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <!-- <Sidebar v-model:visible="formVisible" :baseZIndex="101" :autoZIndex="true" :dismissable="false" :showCloseIcon="true" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">  -->
        <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data">
            <div class="mt-0 mb-0">
                <!-- <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{module_name}}</h3> 
                        <p>You can add or update {{module_name}} here. </p> 
                    </div>
                    <Divider class="mt-0" /> -->
                <div class="grid" :class="[$appState.modelClass]">
                    <div class="col-12 lg:col-12 mb-0 p-0 pt-4">
                        <div style="padding: 10px" v-if="!viewableOnly && !checkUserCoreRole(['Customer'])">
                            <label>
                                <h4>Calcualte price automatically?
                                    <InputSwitch @click="priceCalculationMethodChanged()"
                                        v-model="form.price_auto_calculation" />
                                </h4>
                            </label>
                        </div>
                        <div class="p-2">
                            <div class="col-12 lg:col-12 mb-0">
                                <Panel class="mb-3" header="Routes Information" :toggleable="true"
                                    style="float: left; width: 100%">
                                    <div class="col-12 lg:col-4 sm:col-12 mb-2" style="float: left">
                                        <div>
                                            <label>Find Location</label>
                                            <div class="mapAutoComplete" tabindex="0">
                                                <MapAutoComplete v-if="loadMapAutoComplete"
                                                    v-on:locationChanged="setLocation" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 lg:col-2 sm:col-12 mb-2" style="float: left"
                                        v-if="checkPermission(19, 'insert') && !viewableOnly">
                                        <!-- <div class="col-12 lg:col-12 sm:col-12 mb-0"  style="float:left"> 
                                                <label :class="'full-width'">Journey Type</label>
                                                <Dropdown :filter="true" :showClear="true"  v-model="journey_type" @change="setReturnJourney($event)" :options="JourneyTypes" optionLabel="name" optionValue="id"  :placeholder="'Select Journey Type'" class="full-width"/>
                                            </div>  -->
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float: left">
                                            <label :class="'full-width'">Stay Hours</label>
                                            <InputNumber id="stay_hours_id" suffix=" hours" v-model="stay_hours"
                                                mode="decimal" :min="0" class="_full-width" />
                                        </div>
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float: left">
                                            <label :class="'full-width'">Stay Minutes</label>
                                            <InputNumber id="stay_minutes_id" suffix=" minutes" v-model="stay_minutes"
                                                mode="decimal" :min="0" :max="60" class="_full-width" />
                                        </div>
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float: left">
                                            <label :class="'full-width'">Location Notes</label>
                                            <Textarea placeholder="Stay Notes" v-model="stay_notes" rows="7" cols="7"
                                                class="full-width" />
                                            <HasError class="p-error" :form="form" field="stay_notes" />
                                        </div>
                                        <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float: left">
                                            <Button @click="addNewRoute()" type="button" :label="locationBtnLabel"
                                                icon="pi pi-plus"
                                                class="p-button-raised p-button-lg p-button-success mr-2 full-width" />
                                            <Button v-if="cancelUpdateLocationBtn" @click="cancelUpdateLocation()"
                                                type="button" :label="'Cancel'" icon="pi pi-times"
                                                class="p-button-raised p-button-lg p-button-danger mr-2 full-width" />
                                        </div>
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2 routes-tabs" style="float: left">
                                        <TabView @tab-click="journeyTabClicked($event)" lazy class="tabview-custom"
                                            :activeIndex="0" :scrollable="true">
                                            <TabPanel
                                                v-if="checkPermission(40, 'insert') && !checkUserCoreRole(['Customer'])">
                                                <template #header>
                                                    <i class="pi pi-info-circle"></i>
                                                    <span>&nbsp;First Journey</span>
                                                </template>
                                                <div>
                                                    <div class="card height-100 widget-timeline" style="min-height: 100px">
                                                        <div class="card-header" style="float: left">
                                                            <span
                                                                v-if="distance['first'] && distance['first'] != 'undefined'">
                                                                <div class="col-12 lg:col-5 sm:col-12 mb-0"
                                                                    style="float: left">
                                                                    <Button
                                                                        v-if="distance['first'] && distance['first'] != 'undefined'"
                                                                        type="button" label="Map View"
                                                                        icon="pi pi-map-marker"
                                                                        class="p-right p-button-raised p-button-lg p-button-info mr-2"
                                                                        @click="
                                                                            journey_type = 'first';
                                                                        displayRouteMap = true;
                                                                        loadRoutesMapView();
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            " />
                                                                </div>
                                                                <div v-if="Number(distance['first']) && !checkUserCoreRole(['Customer'])"
                                                                    class="col-7 lg:col-7 sm:col-12 mb-0"
                                                                    style="float: left; display: flex; margin-top: -15px">
                                                                    <div class="col-6 lg:col-6 sm:col-12 mb-0"
                                                                        style="float: left">
                                                                        <label>Price/Distance Unit</label>
                                                                        <InputNumber
                                                                            :disabled="viewableOnly || form.price_auto_calculation"
                                                                            mode="currency" @blur="perUnitPriceChange()"
                                                                            :currency="currency" locale="en-US"
                                                                            placeholder="Price"
                                                                            v-model="form.per_distance_unit_price_first_journey"
                                                                            :min="0" class="_full-width" />
                                                                    </div>
                                                                    <div class="col-6 lg:col-6 sm:col-12 mb-0"
                                                                        style="float: left">
                                                                        <label style="float: left">Total Price</label>
                                                                        <InputNumber
                                                                            :disabled="viewableOnly || form.price_auto_calculation"
                                                                            mode="currency" @blur="totalPriceChange()"
                                                                            :currency="currency" locale="en-US"
                                                                            placeholder="Price"
                                                                            v-model="form.first_journey_price" :min="0"
                                                                            class="_full-width" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 lg:col-12 sm:col-12 mb-0"
                                                                    style="float: left; text-align: center; margin-top: -8px">
                                                                    <Chip
                                                                        :label="form.first_journey_duration ? form.first_journey_duration : 'Not Calculated'"
                                                                        icon="pi pi-clock" class="mr-2" />
                                                                    <Chip
                                                                        :label="distance['first'] > 0 ? Number(distance['first']).toFixed(0) + ' ' + userData['company_distance_unit_code'] : 'Not Calculated'"
                                                                        icon="pi pi-car" class="" />
                                                                </div>
                                                            </span>
                                                            <h5 style="text-align: center" v-else>No Journey Added</h5>
                                                        </div>
                                                        <Divider v-if="form.routes && form.routes['first']" />
                                                        <div v-if="form.routes && form.routes['first']"
                                                            class="col-12 lg:col-12 sm:col-12 mb-0">
                                                            <Timeline :value="form.routes['first']" align="left"
                                                                class="customized-timeline">
                                                                <template #marker="">
                                                                    <span class="custom-marker shadow-2 p-2"
                                                                        style="background-color: red">
                                                                        <i class="marker-icon pi pi-map-marker"></i>
                                                                    </span>
                                                                </template>
                                                                <template #content="slotProps">
                                                                    <Card class="mb-3">
                                                                        <template #title>
                                                                            {{ slotProps.item.city }}, {{
                                                                                slotProps.item.state }}, {{
        slotProps.item.country }}
                                                                            <div style="float: right" v-if="!viewableOnly">
                                                                                <Button icon="pi pi-ellipsis-h"
                                                                                    :locationId="slotProps.item.locationId"
                                                                                    class="p-button-rounded p-button-text p-button-plain"
                                                                                    @click="toggleMenu"></Button>
                                                                                <Menu :popup="true" ref="menu"
                                                                                    :model="routesMenue"></Menu>
                                                                            </div>
                                                                        </template>
                                                                        <template #subtitle> lat: {{ slotProps.item.latitude
                                                                        }} | lng: {{ slotProps.item.longitude }}
                                                                        </template>
                                                                        <template #content>
                                                                            <p class="m-1"><strong>Address :</strong> {{
                                                                                slotProps.item.address }}</p>
                                                                            <p class="m-1"><strong>ZipCode :</strong> {{
                                                                                slotProps.item.zipcode }}</p>
                                                                            <p class="m-1"><strong>Stay :</strong> {{
                                                                                slotProps.item.stay_hours }} hours {{
        slotProps.item.stay_minutes }} minutes</p>
                                                                            <p class="m-1"><strong>Stay Notes :</strong> {{
                                                                                slotProps.item.stay_notes }}</p>
                                                                            <p class="m-1" v-if="slotProps.item.distance">
                                                                                <strong>Distance from previous point
                                                                                    :</strong> {{ slotProps.item.distance }}
                                                                            </p>
                                                                            <p class="m-1" v-if="slotProps.item.time">
                                                                                <strong>Travel time from previous point
                                                                                    :</strong> {{ slotProps.item.time }}
                                                                            </p>
                                                                        </template>
                                                                    </Card>
                                                                </template>
                                                            </Timeline>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel
                                                v-if="checkPermission(40, 'insert') && !checkUserCoreRole(['Customer'])">
                                                <template #header>
                                                    <i class="pi pi-info-circle"></i>
                                                    <span>&nbsp; Return Journey</span>
                                                </template>
                                                <div>
                                                    <div class="card height-100 widget-timeline" style="min-height: 100px">
                                                        <div class="card-header" style="display: block">
                                                            <div class="col-12 lg:col-5 sm:col-12 mb-0" style="float: left">
                                                                <span
                                                                    v-if="distance['first'] && distance['first'] != 'undefined'">
                                                                    <Button
                                                                        v-if="distance['return'] && distance['return'] != 'undefined'"
                                                                        type="button" label="Map View"
                                                                        icon="pi pi-map-marker"
                                                                        class="p-right p-button-raised p-button-lg p-button-info mr-2"
                                                                        @click="
                                                                            journey_type = 'return';
                                                                        displayRouteMap = true;
                                                                        loadRoutesMapView();
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            " />
                                                                    <Button type="button" v-if="!viewableOnly" label=""
                                                                        icon="fa fa-refresh"
                                                                        class="p-right p-button-raised p-button-lg p-button-warning mr-2"
                                                                        @click="setReturnJourney()" />
                                                                </span>
                                                            </div>
                                                            <div v-if="Number(distance['return']) && !checkUserCoreRole(['Customer'])"
                                                                class="col-7 lg:col-7 sm:col-12 mb-0"
                                                                style="float: left; display: flex; margin-top: -15px">
                                                                <div class="col-6 lg:col-6 sm:col-12 mb-0"
                                                                    style="float: left">
                                                                    <label>Price/Distance Unit</label>
                                                                    <InputNumber
                                                                        :disabled="viewableOnly || form.price_auto_calculation"
                                                                        mode="currency" @blur="perUnitPriceChange()"
                                                                        :currency="currency" locale="en-US"
                                                                        placeholder="Price"
                                                                        v-model="form.per_distance_unit_price_return_journey"
                                                                        :min="0" class="_full-width" />
                                                                </div>
                                                                <div class="col-6 lg:col-6 sm:col-12 mb-0"
                                                                    style="float: left">
                                                                    <label style="float: left">Total Price</label>
                                                                    <InputNumber
                                                                        :disabled="viewableOnly || form.price_auto_calculation"
                                                                        mode="currency" @blur="totalPriceChange()"
                                                                        :currency="currency" locale="en-US"
                                                                        placeholder="Price"
                                                                        v-model="form.return_journey_price" :min="0"
                                                                        class="_full-width" />
                                                                    <!-- <label  style="float:left;background: blanchedalmond;" class="_full-width p-inputtext p-component p-inputnumber-input p-inputnumber-input">{{currency}}  {{form.return_journey_price}}</label>  -->
                                                                </div>
                                                            </div>
                                                            <div class="col-12 lg:col-12 sm:col-12 mb-0"
                                                                style="float: left; text-align: center; margin-top: -8px">
                                                                <Chip
                                                                    :label="form.return_journey_duration ? form.return_journey_duration : 'Not Calculated'"
                                                                    icon="pi pi-clock" class="mr-2" />
                                                                <Chip
                                                                    :label="distance['return'] > 0 ? Number(distance['return']).toFixed(0) + ' ' + userData['company_distance_unit_code'] : 'Not Calculated'"
                                                                    icon="pi pi-car" class="" />
                                                            </div>
                                                        </div>
                                                        <Divider v-if="form.routes && form.routes['return']" />
                                                        <div v-if="form.routes && form.routes['return']"
                                                            class="col-12 lg:col-12 sm:col-12 mb-0">
                                                            <Timeline :value="form.routes['return']" align="left"
                                                                class="customized-timeline">
                                                                <template #marker="">
                                                                    <span class="custom-marker shadow-2 p-2"
                                                                        style="background-color: red">
                                                                        <i class="marker-icon pi pi-map-marker"></i>
                                                                    </span>
                                                                </template>
                                                                <template #content="slotProps">
                                                                    <Card class="mb-3">
                                                                        <template #title>
                                                                            {{ slotProps.item.city }}, {{
                                                                                slotProps.item.state }} {{
        slotProps.item.country }}
                                                                            <div style="float: right" v-if="!viewableOnly">
                                                                                <Button icon="pi pi-ellipsis-h"
                                                                                    :locationId="slotProps.item.locationId"
                                                                                    class="p-button-rounded p-button-text p-button-plain"
                                                                                    @click="toggleMenu"></Button>
                                                                                <Menu :popup="true" ref="menu"
                                                                                    :model="routesMenue"></Menu>
                                                                            </div>
                                                                        </template>
                                                                        <template #subtitle> lat: {{ slotProps.item.latitude
                                                                        }} | lng: {{ slotProps.item.longitude }}
                                                                        </template>
                                                                        <template #content>
                                                                            <!-- <img v-if="slotProps.item.image" :src="'assets/showcase/images/demo/product/' + slotProps.item.image" :alt="slotProps.item.name" width="200" class="shadow-2" /> -->
                                                                            <p class="m-1"><strong>Address :</strong> {{
                                                                                slotProps.item.address }}</p>
                                                                            <p class="m-1"><strong>ZipCode :</strong> {{
                                                                                slotProps.item.zipcode }}</p>
                                                                            <p class="m-1"><strong>Stay :</strong> {{
                                                                                slotProps.item.stay_hours }} hours {{
        slotProps.item.stay_minutes }} minutes</p>
                                                                            <p class="m-1"><strong>Stay Notes :</strong> {{
                                                                                slotProps.item.stay_notes }}</p>
                                                                            <p class="m-1" v-if="slotProps.item.distance">
                                                                                <strong>Distance from previous point
                                                                                    :</strong> {{ slotProps.item.distance }}
                                                                            </p>
                                                                            <p class="m-1" v-if="slotProps.item.time">
                                                                                <strong>Travel time from previous point
                                                                                    :</strong> {{ slotProps.item.time }}
                                                                            </p>
                                                                        </template>
                                                                    </Card>
                                                                </template>
                                                            </Timeline>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </TabView>
                                    </div>
                                </Panel>
                            </div>

                            <Dialog v-if="journey_type" :header="journey_type.charAt(0).toUpperCase() + ' Route Map'"
                                v-model:visible="displayRouteMap" :style="{ width: '50vw' }" :maximizable="true"
                                :modal="true">
                                <div>
                                    <div id="routesMapView" class="routesMapView"></div>
                                </div>
                            </Dialog>
                            <Divider />
                            <div class="col-12 lg:col-4 sm:col-12 mb-1" style="float: left">
                                <Panel class="mb-3" header="Journey Information" :toggleable="true"
                                    style="float: left; width: 100%">
                                    <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                        <label><span class="pi pi-star-fill important mr-1"></span>Start Time</label>
                                        <Calendar ref="calendar1" panelClass="calendarStyle" :disabled="viewableOnly"
                                            :dateFormat="calendarFormat()" :hideOnDateTimeSelect="false"
                                            :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"
                                            :minDate="minJourneyStartDateTime" @date-select="startDateTimeSelected($event)"
                                            :showButtonBar="true" id="start_time" v-model="form.start_time" :showTime="true"
                                            :stepMinute="5" :showSeconds="false" class="full-width">
                                            <template #footer>
                                                <div class="full-width text-center">
                                                    <Button label="OK!" @click="closeCalendar"
                                                        class="p-button-raised p-button-success" />
                                                </div>
                                            </template>
                                        </Calendar>
                                        <HasError class="p-error" :form="form" field="start_time" />
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                        <label>End Time <small>(Optional)</small></label>
                                        <Calendar ref="calendar2" panelClass="calendarStyle" :dateFormat="calendarFormat()"
                                            :hideOnDateTimeSelect="false"
                                            :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"
                                            :minDate="minDateTime" @date-select="endDateTimeSelected($event)"
                                            :showButtonBar="true"
                                            :disabled="form.start_time == '' || viewableOnly ? true : false" id="end_time"
                                            v-model="form.end_time" :stepMinute="5" :showTime="true" :showSeconds="false"
                                            class="full-width">
                                            <template #footer>
                                                <div class="full-width text-center">
                                                    <Button label="OK!" @click="closeCalendar"
                                                        class="p-button-raised p-button-success" />
                                                </div>
                                            </template>
                                        </Calendar>
                                        <HasError class="p-error" :form="form" field="end_time" />
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left"
                                        v-if="form.routes['return'] && form.routes['return'].length">
                                        <label>Return Start Time <small>(Optional) </small></label>
                                        <Calendar ref="calendar3" panelClass="calendarStyle" :disabled="viewableOnly"
                                            :dateFormat="calendarFormat()" :hideOnDateTimeSelect="false"
                                            :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"
                                            :minDate="returnMinDateTime" @date-select="returnDateTimeSelected($event)"
                                            :showButtonBar="true" id="return_time" v-model="form.return_time"
                                            :showTime="true" :stepMinute="5" :showSeconds="false" class="full-width">
                                            <template #footer>
                                                <div class="full-width text-center">
                                                    <Button label="OK!" @click="closeCalendar"
                                                        class="p-button-raised p-button-success" />
                                                </div>
                                            </template>
                                        </Calendar>
                                        <HasError class="p-error" :form="form" field="return_time" />
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left"
                                        v-if="form.routes['return'] && form.routes['return'].length">
                                        <label>Return End Time <small>(Optional)</small></label>
                                        <Calendar ref="calendar4" panelClass="calendarStyle" :dateFormat="calendarFormat()"
                                            :hideOnDateTimeSelect="false"
                                            :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"
                                            :minDate="returnEndMinDateTime" :showButtonBar="true" id="return_end_time"
                                            v-model="form.return_end_time"
                                            :disabled="form.return_time == '' || viewableOnly ? true : false"
                                            :showTime="true" :stepMinute="5" :showSeconds="false" class="full-width">
                                            <template #footer>
                                                <div class="full-width text-center">
                                                    <Button label="OK!" @click="closeCalendar"
                                                        class="p-button-raised p-button-success" />
                                                </div>
                                            </template>
                                        </Calendar>
                                        <HasError class="p-error" :form="form" field="return_end_time" />
                                    </div>
                                    <div class="col-8 lg:col-8 sm:col-12 mb-1" style="float: left"
                                        v-if="checkPermission(19, 'dropdown') && !checkUserCoreRole(['Customer'])">
                                        <label>Journey Type<span v-if="checkPermission(19, 'insert') && !viewableOnly"
                                                class="supporting-btn-link" @click="showJourneyTypeForm = true">+ Add
                                                New</span></label>
                                        <Dropdown :disabled="viewableOnly" JourneyTypeLoading :filter="true"
                                            :showClear="true" v-model="form.journey_type_id" :options="journeyTypes"
                                            optionLabel="name" optionValue="id"
                                            :placeholder="JourneyTypeLoading ? 'Loading...' : 'Select Type'"
                                            class="full-width" />
                                        <HasError class="p-error" :form="form" field="journey_type_id" />
                                    </div>
                                    <div class="col-4 lg:col-4 sm:col-12 mb-0 pr-0" style="float: left">
                                        <label>Passangers</label>
                                        <InputNumber :disabled="viewableOnly" placeholder="Passangers"
                                            v-model="form.passangers" :min="0" suffix="" showButtons class="_full-width" />
                                        <HasError class="p-error" :form="form" field="passangers" />
                                    </div>
                                    <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float: left">
                                        <label>Child Seats</label>
                                        <InputNumber :disabled="viewableOnly" placeholder="Child Seats"
                                            v-model="form.child_seats" :min="0" suffix="" showButtons class="_full-width" />
                                        <HasError class="p-error" :form="form" field="child_seats" />
                                    </div>
                                    <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float: left">
                                        <label>Hand Luggage</label>
                                        <InputNumber :disabled="viewableOnly" placeholder="Hand Luggage"
                                            v-model="form.hand_luggage" :min="0" suffix="" showButtons
                                            class="_full-width" />
                                        <HasError class="p-error" :form="form" field="hand_luggage" />
                                    </div>
                                    <div class="col-4 lg:col-4 sm:col-12 mb-0" style="float: left">
                                        <label>Suitcases</label>
                                        <InputNumber :disabled="viewableOnly" placeholder="Suitcases"
                                            v-model="form.suitcases" :min="0" suffix="" showButtons class="_full-width" />
                                        <HasError class="p-error" :form="form" field="suitcases" />
                                    </div>

                                    <!-- <div class="col-3 lg:col-3 sm:col-12 mb-0 pl-0 pr-0" style="float:left"> 
                                            <label>Luggage (KG)</label> 
                                            <InputNumber placeholder="Luggage" v-model="form.luggage" :min="0" suffix="KG" showButtons class="_full-width"/> 
                                            <HasError class="p-error" :form="form" field="luggage" /> 
                                        </div> -->
                                    <div class="col-12 lg:col-12 sm:col-12 mb-1" style="float: left">
                                        <label>Notes</label>
                                        <Textarea :disabled="viewableOnly" placeholder="Notes" v-model="form.notes" rows="5"
                                            cols="30" class="full-width" />
                                    </div>
                                </Panel>
                            </div>
                            <div class="col-12 lg:col-5 sm:col-12 mb-1" style="float: left">
                                <Panel class="mb-3" header="Vehicle" :toggleable="true" style="float: left">
                                    <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left"
                                        v-if="checkPermission(36, 'dropdown')">
                                        <label>Vehicle Type<span v-if="checkPermission(36, 'insert') && !viewableOnly"
                                                class="supporting-btn-link" @click="showVehicleTypeForm = true">+ Add
                                                New</span></label>
                                        <Dropdown :disabled="viewableOnly" required ref="vehicleType12"
                                            @change="
                                                getVehicles();
                                            VehicleTypeChanged();
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "
                                            VehicleTypesLoading :filter="true" :showClear="true"
                                            v-model="form.vehicle_type_id" :options="vehicleTypes" optionLabel="name"
                                            optionValue="id"
                                            :placeholder="VehicleTypesLoading ? 'Loading...' : 'Select Type'"
                                            class="full-width" />
                                        <HasError class="p-error" :form="form" field="vehicle_type_id" />
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left"
                                        v-if="checkPermission(38, 'dropdown') && !checkUserCoreRole(['Customer'])">
                                        <label>Vehicle<span v-if="checkPermission(38, 'insert') && !viewableOnly"
                                                class="supporting-btn-link" @click="showVehicleForm = true">+ Add
                                                New</span></label>
                                        <Dropdown :disabled="viewableOnly" VehiclesLoading :filter="true" :showClear="true"
                                            v-model="form.vehicle_id" :options="vehicles" optionLabel="name"
                                            optionValue="id"
                                            :placeholder="VehiclesLoading ? 'Loading...' : 'Select Vehicle'"
                                            class="full-width" />
                                        <HasError class="p-error" :form="form" field="vehicle_id" />
                                    </div>
                                    <div class="col-12 lg:col-4 sm:col-12 mb-0" v-for="(type, index) in cargoGroupTypes"
                                        :key="index" style="float: left">
                                        <label>{{ type.name }}</label>
                                        <InputText :disabled="viewableOnly" placeholder="Max Units" :max="type.units"
                                            v-model="form.orderCargoValues[index].cargo_units" type="number"
                                            class="full-width" />
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left"
                                        v-if="checkPermission(5, 'dropdown') && !checkUserCoreRole(['Customer'])">
                                        <label>Driver</label>
                                        <Dropdown :disabled="viewableOnly" UsersLoading :filter="true" :showClear="true"
                                            @change="checkDrivers('primary')" v-model="form.driver_id"
                                            :options="driverUsers" optionLabel="designation_name" optionValue="id"
                                            :placeholder="UsersLoading ? 'Loading...' : 'Select Driver'"
                                            class="full-width" />
                                        <HasError class="p-error" :form="form" field="driver_id" />
                                    </div>
                                    <!-- <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left" v-if="checkPermission(5, 'dropdown') && !checkUserCoreRole(['Customer'])">
                                        <label>Secondary Driver</label>
                                        <Dropdown
                                            :disabled="viewableOnly"
                                            UsersLoading
                                            :filter="true"
                                            :showClear="true"
                                            @change="checkDrivers('secondary')"
                                            v-model="form.secondary_driver_id"
                                            :options="driverUsers"
                                            optionLabel="designation_name"
                                            optionValue="id"
                                            :placeholder="UsersLoading ? 'Loading...' : 'Select Driver'"
                                            class="full-width"
                                        />
                                        <HasError class="p-error" :form="form" field="secondary_driver_id" />
                                    </div> -->
                                    <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float:left">
                                        <label>Driver will be as helper?</label>
                                        <Dropdown :disabled="viewableOnly" required ref="vehicleType12"
                                            @change="driverHelperStatus(); " :filter="true" :showClear="true"
                                            v-model="form.is_driver_helper" :options="booleanStatus" optionLabel="name"
                                            optionValue="id" class="full-width" />
                                        <HasError class="p-error" :form="form" field="is_driver_helper" />
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                        <label>Helpers</label>
                                        <InputNumber :disabled="viewableOnly" placeholder="Helpers" v-model="form.helpers"
                                            :min="0" suffix="" showButtons class="_full-width" />
                                        <HasError class="p-error" :form="form" field="helpers" />
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left"
                                        v-if="checkPermission(8, 'dropdown') && !checkUserCoreRole(['Customer'])">
                                        <label>Base Location</label>
                                        <Dropdown :disabled="viewableOnly" BaseLocationsLoading :filter="true"
                                            :showClear="true" v-model="form.vehicle_base_branch_id" :options="branches"
                                            optionLabel="company_branch_name" optionValue="id"
                                            :placeholder="BaseLocationsLoading ? 'Loading...' : 'Select Base'"
                                            class="full-width" />
                                        <HasError class="p-error" :form="form" field="vehicle_base_branch_id" />
                                    </div>

                                    <div class="col-12 lg:col-12 sm:col-12 mb-1" style="float: left">
                                        <label>Driver Specific Notes</label>
                                        <Textarea :disabled="viewableOnly" placeholder="Notes" v-model="form.driver_notes"
                                            rows="5" cols="30" class="full-width" />
                                    </div>
                                    <!-- <div class="col-12 lg:col-12 mb-0" > 
                                            <div class="field-checkbox">
                                                <Checkbox value="true" v-model="form.send_email" :binary="true" />
                                                <label >Send Email</label>
                                            </div>
                                        </div>  -->
                                </Panel>
                                <!-- <Panel class="mb-3" header="Price" :toggleable="true">
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0">  
                                            <label>Price</label> 
                                            <InputNumber id="price" v-model="form.price" mode="currency" :currency="currency" locale="en-US" class="full-width"/> 
                                            <HasError class="p-error" :form="form" field="price" /> 
                                        </div>
                                    </Panel> -->
                            </div>
                            <div class="col-12 lg:col-3 sm:col-12 mb-1" style="float: left">
                                <Panel class="mb-3" header="Pickup/Dropof" :toggleable="true" :collapsed="true"
                                    style="float: left; width: 100%">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float: left">
                                        <label>Pickup Floor</label>
                                        <InputNumber :disabled="viewableOnly" id="pickupFloor" placeholder="Pickup Floor"
                                            v-model="form.pickup_floor" :showButtons="true" :min="0" :max="150"
                                            class="full-width" />
                                        <HasError class="p-error" :form="form" field="pickup_floor" />
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float: left"
                                        v-if="form.pickup_floor > 0">
                                        <label>Pickup by lift?</label>
                                        <Dropdown :disabled="viewableOnly" :filter="true" :showClear="true"
                                            v-model="form.pickup_lift" :options="yesno" optionLabel="name" optionValue="id"
                                            class="full-width" />
                                        <HasError class="p-error" :form="form" field="pickup_lift" />
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float: left">
                                        <label>Dropof Floor</label>
                                        <InputNumber :disabled="viewableOnly" id="DropofFloor" placeholder="Dropof Floor"
                                            v-model="form.dropof_floor" :showButtons="true" :min="0" :max="150"
                                            class="full-width" />
                                        <HasError class="p-error" :form="form" field="dropof_floor" />
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float: left"
                                        v-if="form.dropof_floor > 0">
                                        <label>Dropof by lift?</label>
                                        <Dropdown :disabled="viewableOnly" :filter="true" :showClear="true"
                                            v-model="form.dropof_lift" :options="yesno" optionLabel="name" optionValue="id"
                                            class="full-width" />
                                        <HasError class="p-error" :form="form" field="dropof_lift" />
                                    </div>
                                </Panel>
                                <Panel class="mb-3" header="Flight" :toggleable="true" style="float: left; width: 100%"
                                    :collapsed="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float: left">
                                        <label>Airline</label>
                                        <InputText :disabled="viewableOnly" id="airline" placeholder="Airline"
                                            v-model="form.flight_name" type="text" class="full-width" />
                                        <HasError class="p-error" :form="form" field="flight_name" />
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                        <label>Flight Number</label>
                                        <InputText :disabled="viewableOnly" id="flight_number" placeholder="Flight Number"
                                            v-model="form.flight_number" type="text" class="full-width" />
                                        <HasError class="p-error" :form="form" field="flight_number" />
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                        <label>Inbound/Outbound</label>
                                        <Dropdown :disabled="viewableOnly" :filter="true" :showClear="true"
                                            v-model="form.inbound_outbound" :options="inboundOutbound" optionLabel="name"
                                            optionValue="id" class="full-width" />
                                        <HasError class="p-error" :form="form" field="inbound_outbound" />
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                        <label>Departure Time</label>
                                        <Calendar ref="calendar5" panelClass="calendarStyle" :disabled="viewableOnly"
                                            :dateFormat="calendarFormat()"
                                            :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"
                                            :showButtonBar="true" id="departure_time" :stepMinute="5"
                                            v-model="form.departure_time" :showTime="true" :showSeconds="false"
                                            class="full-width">
                                            <template #footer>
                                                <div class="full-width text-center">
                                                    <Button label="OK!" @click="closeCalendar"
                                                        class="p-button-raised p-button-success" />
                                                </div>
                                            </template>
                                        </Calendar>
                                        <HasError class="p-error" :form="form" field="departure_time" />
                                    </div>
                                    <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                        <label>Arrival Time</label>
                                        <Calendar ref="calendar6" panelClass="calendarStyle" :disabled="viewableOnly"
                                            :dateFormat="calendarFormat()"
                                            :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"
                                            :showButtonBar="true" id="arrival_time" :stepMinute="5"
                                            v-model="form.arrival_time" :showTime="true" :showSeconds="false"
                                            class="full-width">
                                            <template #footer>
                                                <div class="full-width text-center">
                                                    <Button label="OK!" @click="closeCalendar"
                                                        class="p-button-raised p-button-success" />
                                                </div>
                                            </template>
                                        </Calendar>
                                        <HasError class="p-error" :form="form" field="arrival_time" />
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 mb-0">
                                        <label>Notes</label>
                                        <Textarea :disabled="viewableOnly" placeholder="Notes" v-model="form.flight_notes"
                                            rows="5" cols="30" class="full-width" />
                                        <!-- <Editor v-model="notes" editorStyle="height: 320px"/> -->
                                    </div>
                                </Panel>
                            </div>
                            <div class="col-12 lg:col-12 mb-0" style="float: left">
                                <div class="card height-100 widget-timeline" style="float: left; width: 100%">
                                    <div class="card-header">
                                        <h3>Products</h3>
                                        <Button v-if="checkPermission(42, 'insert')" v-tooltip.top="'Add Product'"
                                            :label="showBtnLabels ? 'Add Product' : ''"
                                            @click="(showProductForm = true), (selectedProductId = 0)" icon="pi pi-plus"
                                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                            class="p-button-raised p-button-primary mr-2" />
                                        <!-- <Button type="button" label="Map View" icon="pi pi-map-marker" class="p-right p-button-raised p-button-lg p-button-info mr-2" @click="journey_type= 'first';displayRouteMap= true;"/> -->
                                    </div>
                                    <Divider />
                                    <div style="display: table; width: 100%"
                                        v-if="form.orderProducts && Object.keys(form.orderProducts).length">
                                        <div class="col-12 lg:col-4 md:col-4 mb-0"
                                            v-for="(product, pkey) in form.orderProducts" :key="pkey"
                                            style="float: left; display: table-cell">
                                            <Card class="mb-3" style="height: 100%">
                                                <template #title>
                                                    {{ product.product_name }}
                                                    <div style="float: right" v-if="!viewableOnly">
                                                        <Button icon="pi pi-ellipsis-h" :productId="product.id"
                                                            class="p-button-rounded p-button-text p-button-plain"
                                                            @click="toggleProductMenu"></Button>
                                                        <Menu :popup="true" ref="menu2" :model="productsMenu"></Menu>
                                                    </div>
                                                </template>
                                                <template #subtitle>
                                                    {{ product.product_category_name }}
                                                </template>
                                                <template #content>
                                                    <!-- <img v-if="slotProps.item.image" :src="'assets/showcase/images/demo/product/' + slotProps.item.image" :alt="slotProps.item.name" width="200" class="shadow-2" /> -->
                                                    <p class="m-1"><strong>Quantity :</strong> {{ currency }} {{
                                                        product.quantity }}</p>
                                                    <p class="m-1"><strong>Unit Price :</strong> {{ currency }} {{
                                                        product.unit_price }}</p>
                                                    <p class="m-1"><strong>Price :</strong> {{ currency }} {{ product.price
                                                    }}</p>
                                                    <p class="m-1">{{ product.notes }}</p>
                                                </template>
                                            </Card>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <h4 class="text-center">No Product Added</h4>
                                    </div>
                                </div>
                            </div>
                            <div v-if="priceCalculationSteps && Object.keys(priceCalculationSteps).length > 0"
                                class="col-12 lg:col-12 mb-0" style="float: left">
                                <div class="card height-100 widget-timeline" style="float: left; width: 100%">
                                    <div class="card-header">
                                        <h3>Price Calculations</h3>
                                    </div>
                                    <Divider />
                                    <div style="display: table; width: 100%">
                                        <div class="col-12 lg:col-12 md:col-12 mb-0"
                                            style="float: left; display: table-cell">
                                            <Panel
                                                v-if="priceCalculationSteps.first && priceCalculationSteps.first.length && distance['first'] && distance['first'] > 0"
                                                class="mb-3 full-width float-left" :header="'First Jorney Pricing'"
                                                :toggleable="false">
                                                <div>
                                                    <table v-if="priceCalculationSteps.first[0]">
                                                        <tr v-for="(step, key) in priceCalculationSteps.first" :key="key">
                                                            <td v-if="step.vehicleFixedPrice > 0"><strong>Vehicle Fixed
                                                                    Amount :</strong>{{ currency }} {{
                                                                        Number(step.vehicleFixedPrice).toFixed(priceRounding) }}
                                                            </td>

                                                            <td v-if="step.travelingHours > 0 || step.perHourPrice > 0">
                                                                <strong>Traveling Price :</strong> {{
                                                                    Number(step.travelingHours).toFixed(priceRounding) }} hours
                                                                x {{ Number(step.perHourPrice).toFixed(priceRounding) }} =
                                                                {{ currency }} {{ Number(step.perHourPrice *
                                                                    step.travelingHours).toFixed(priceRounding) }}
                                                            </td>


                                                            <td
                                                                v-if="step.perDistanceUnitPrice > 0 || step.travelingDistance > 0">
                                                                <strong>Traveling Distance :</strong> {{
                                                                    Number(step.travelingDistance).toFixed(priceRounding) }}
                                                            </td>
                                                            <td
                                                                v-if="step.perDistanceUnitPrice > 0 || step.travelingDistance > 0">
                                                                <strong>Per Distance Unit Price :</strong> {{
                                                                    Number(step.perDistanceUnitPrice).toFixed(priceRounding) }}
                                                            </td>
                                                            <td
                                                                v-if="step.perDistanceUnitPrice > 0 || step.travelingDistance > 0">
                                                                <strong>Total :</strong> {{ currency }} {{
                                                                    Number(step.travelingDistance *
                                                                        step.perDistanceUnitPrice).toFixed(priceRounding) }}
                                                            </td>

                                                            <td v-if="step.pricePerStop > 0 || step.priceAbleStops > 0">
                                                                <strong>Priceable Stops :</strong> {{ step.priceAbleStops }}
                                                            </td>
                                                            <td v-if="step.pricePerStop > 0 || step.priceAbleStops > 0">
                                                                <strong>Per Stop Price :</strong> {{
                                                                    Number(step.pricePerStop).toFixed(priceRounding) }}
                                                            </td>
                                                            <td v-if="step.pricePerStop > 0 || step.priceAbleStops > 0">
                                                                <strong>Total :</strong> {{ currency }} {{
                                                                    Number(step.pricePerStop *
                                                                        step.priceAbleStops).toFixed(priceRounding) }}
                                                            </td>


                                                            <td v-if="step.driverAsHelperPriceFirst > 0"><strong>Driver as
                                                                    Helper Price :</strong> {{ step.driverAsHelperPrice }} x
                                                                {{ step.priceAbleHours }} hours = {{ currency }} {{
                                                                    step.driverAsHelperPriceFirst }}</td>

                                                            <td
                                                                v-if="step.priceForHelper > 0 || step.priceAbleHelpers > 0 || step.priceForHelperHours > 0">
                                                                <strong>Priceable Helpers :</strong> {{
                                                                    step.priceAbleHelpers }}
                                                            </td>
                                                            <td
                                                                v-if="step.priceForHelper > 0 || step.priceAbleHelpers > 0 || step.priceForHelperHours > 0">
                                                                <strong>Per Helper Price :</strong> {{
                                                                    Number(step.priceForHelper).toFixed(priceRounding) }}
                                                            </td>




                                                            <td
                                                                v-if="step.priceForHelper > 0 || step.priceAbleHelpers > 0 || step.priceForHelperHours > 0">
                                                                <strong>Periceable Hours :</strong> {{
                                                                    Number(step.priceForHelperHours).toFixed(priceRounding) }}
                                                            </td>
                                                            <td
                                                                v-if="step.priceForHelper > 0 || step.priceAbleHelpers > 0 || step.priceForHelperHours > 0">
                                                                <strong>Total :</strong> {{ currency }} {{
                                                                    Number(step.priceForHelper * step.priceAbleHelpers *
                                                                        step.priceForHelperHours).toFixed(priceRounding) }}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table v-if="priceCalculationSteps.adjustments[0]">
                                                        <tr v-for="(step, key) in priceCalculationSteps.adjustments"
                                                            :key="key">

                                                            <td v-if="step.vehicleFixedPrice > 0"><strong>Vehicle Fixed
                                                                    Amount :</strong> {{
                                                                        Number(step.vehicleFixedPrice).toFixed(priceRounding) }}
                                                            </td>


                                                            <td
                                                                v-if="step.minimumBookingHours && step.minimumBookingHours.priceAbleHours > 0">
                                                                <strong>Priceable Hours (Less than minimum booking
                                                                    hours):</strong> {{
                                                                        Number(step.minimumBookingHours.priceAbleHours).toFixed(priceRounding)
                                                                    }}
                                                            </td>
                                                            <td
                                                                v-if="step.minimumBookingHours && step.minimumBookingHours.perHourPrice > 0">
                                                                <strong>Per Hour Price :</strong> {{
                                                                    Number(step.minimumBookingHours.perHourPrice).toFixed(priceRounding)
                                                                }}
                                                            </td>
                                                            <td
                                                                v-if="step.minimumBookingHours && (step.minimumBookingHours.perHourPrice > 0 || step.minimumBookingHours.priceAbleHours > 0)">
                                                                <strong>Total :</strong> {{ currency }} {{
                                                                    Number(step.minimumBookingHours.priceAbleHours *
                                                                        step.minimumBookingHours.perHourPrice).toFixed(priceRounding)
                                                                }}
                                                            </td>

                                                            <td
                                                                v-if="step.fixedTimePrice && step.fixedTimePrice.priceIncrease > 0">
                                                                <strong>Fixed Booking Time Price :</strong> {{ currency }}
                                                                {{
                                                                    Number(step.fixedTimePrice.priceIncrease).toFixed(priceRounding)
                                                                }}
                                                            </td>

                                                            <td
                                                                v-if="step.pickup_withLiftPriceIncrease && step.pickup_withLiftPriceIncrease.priceIncrease > 0">
                                                                <strong>Pickup With Lift Price Increase :</strong> {{
                                                                    currency }} {{
        Number(step.pickup_withLiftPriceIncrease.priceIncrease).toFixed(priceRounding)
    }}
                                                            </td>
                                                            <td
                                                                v-if="step.pickup_withOutLiftPriceIncrease && step.pickup_withOutLiftPriceIncrease.priceIncrease > 0">
                                                                <strong>Pickup With Out Lift Price Increase :</strong> {{
                                                                    currency }} {{
        Number(step.pickup_withOutLiftPriceIncrease.priceIncrease).toFixed(priceRounding)
    }}
                                                            </td>
                                                            <td
                                                                v-if="step.dropof_withLiftPriceIncrease && step.dropof_withLiftPriceIncrease.priceIncrease > 0">
                                                                <strong>Dropof With Lift Price Increase :</strong> {{
                                                                    currency }} {{
        Number(step.dropof_withLiftPriceIncrease.priceIncrease).toFixed(priceRounding)
    }}
                                                            </td>
                                                            <td
                                                                v-if="step.dropof_withOutLiftPriceIncrease && step.dropof_withOutLiftPriceIncrease.priceIncrease > 0">
                                                                <strong>Dropof With Out Lift Price Increase :</strong> {{
                                                                    currency }} {{
        Number(step.dropof_withOutLiftPriceIncrease.priceIncrease).toFixed(priceRounding)
    }}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </Panel>
                                            <Panel
                                                v-if="priceCalculationSteps.return && priceCalculationSteps.return.length && distance['return'] && distance['return'] > 0"
                                                class="mb-3 full-width float-left" :header="'Return Jorney Pricing'"
                                                :toggleable="false">
                                                <div>
                                                    <table v-if="priceCalculationSteps.return[0]">
                                                        <tr v-for="(step, key) in priceCalculationSteps.return" :key="key">
                                                            <td v-if="step.travelingHours > 0 || step.perHourPrice > 0">
                                                                <strong>Traveling Hours :</strong> {{
                                                                    Number(step.travelingHours).toFixed(priceRounding) }}
                                                            </td>
                                                            <td v-if="step.travelingHours > 0 || step.perHourPrice > 0">
                                                                <strong>Per Hour Price :</strong> {{
                                                                    Number(step.perHourPrice).toFixed(priceRounding) }}
                                                            </td>
                                                            <td v-if="step.travelingHours > 0 || step.perHourPrice > 0">
                                                                <strong>Total :</strong> {{ currency }} {{
                                                                    Number(step.perHourPrice *
                                                                        step.travelingHours).toFixed(priceRounding) }}
                                                            </td>

                                                            <td
                                                                v-if="step.perDistanceUnitPrice > 0 || step.travelingDistance > 0">
                                                                <strong>Traveling Distance :</strong> {{
                                                                    step.travelingDistance ?
                                                                    Number(step.travelingDistance).toFixed(priceRounding) : 0 }}
                                                                {{ defaultDistanceUnit }}
                                                            </td>
                                                            <td
                                                                v-if="step.perDistanceUnitPrice > 0 || step.travelingDistance > 0">
                                                                <strong>Per Distance Unit Price :</strong> {{ currency }} {{
                                                                    Number(step.perDistanceUnitPrice).toFixed(priceRounding) }}
                                                            </td>
                                                            <td
                                                                v-if="step.perDistanceUnitPrice > 0 || step.travelingDistance > 0">
                                                                <strong>Total :</strong> {{ currency }} {{
                                                                    Number(step.travelingDistance *
                                                                        step.perDistanceUnitPrice).toFixed(priceRounding) }}
                                                            </td>

                                                            <td v-if="step.pricePerStop > 0 || step.priceAbleStopsR > 0">
                                                                <strong>Priceable Stops :</strong> {{ step.priceAbleStopsR
                                                                }}
                                                            </td>
                                                            <td v-if="step.pricePerStop > 0 || step.priceAbleStopsR > 0">
                                                                <strong>Per Stop Price :</strong> {{ currency }} {{
                                                                    Number(step.pricePerStop).toFixed(priceRounding) }}
                                                            </td>
                                                            <td v-if="step.pricePerStop > 0 || step.priceAbleStopsR > 0">
                                                                <strong>Total :</strong> {{ currency }} {{
                                                                    Number(step.pricePerStop *
                                                                        step.priceAbleStopsR).toFixed(priceRounding) }}
                                                            </td>

                                                            <td v-if="step.driverAsHelperPriceReturn > 0"><strong>Driver
                                                                    as Helper Price :</strong> {{ step.driverAsHelperPrice
                                                                    }} x {{ step.priceAbleHours }} hours = {{ currency }} {{
    step.driverAsHelperPriceReturn }}</td>


                                                            <td
                                                                v-if="step.priceForHelper > 0 || step.priceAbleHelpers > 0 || step.priceForHelperHours > 0">
                                                                <strong>Priceable Helpers :</strong> {{
                                                                    step.priceAbleHelpers }}
                                                            </td>
                                                            <td
                                                                v-if="step.priceForHelper > 0 || step.priceAbleHelpers > 0 || step.priceForHelperHours > 0">
                                                                <strong>Per Helper Price :</strong> {{ currency }} {{
                                                                    Number(step.priceForHelper).toFixed(priceRounding) }}
                                                            </td>
                                                            <td
                                                                v-if="step.priceForHelper > 0 || step.priceAbleHelpers > 0 || step.priceForHelperHours > 0">
                                                                <strong>Periceable Hours :</strong> {{
                                                                    Number(step.priceForHelperHours).toFixed(priceRounding) }}
                                                            </td>
                                                            <td
                                                                v-if="step.priceForHelper > 0 || step.priceAbleHelpers > 0 || step.priceForHelperHours > 0">
                                                                <strong>Total :</strong> {{ currency }} {{
                                                                    Number(step.priceForHelper * step.priceAbleHelpers *
                                                                        step.priceForHelperHours).toFixed(priceRounding) }}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </Panel>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 lg:col-12 mb-0" style="float: left">
                                <label>Quotation Terms</label>
                                <ckeditor id="editor" @ready="onReady" :editor="editor" placeholder="Quotation Terms"
                                    v-model="form.quotation_terms" :config="editorConfig1" />
                                <HasError class="p-error" :form="form" field="quotation_terms" />
                            </div>
                            <div class="col-12 lg:col-12 mb-0" style="float: left">
                                <label>Invoice Terms</label>
                                <ckeditor id="editor" @ready="onReady" :editor="editor" placeholder="Invoice Terms"
                                    v-model="form.invoice_terms" :config="editorConfig1" />
                                <HasError class="p-error" :form="form" field="invoice_terms" />
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <div class="col-12 lg:col-12">
                    <Toolbar class="pl-0 pr-0">
                        <template v-slot:start>
                            <Button v-if="!viewableOnly" type="submit"
                                :label="checkUserCoreRole(['Customer']) ? 'Place Order' : 'Save & Next'" :loading="isSaving"
                                icon="pi pi-check" @click="(saveAndClose = false), save()"
                                class="p-button-raised p-button-lg p-button-success mr-2" />
                            <Button v-if="!checkUserCoreRole(['Customer']) && !viewableOnly" type="submit"
                                label="Save and Send Quotation" :loading="isSaving" icon="pi pi-check"
                                @click="(form.send_quotation = true), save()"
                                class="p-button-raised p-button-lg p-button-secondary mr-2" />
                            <Button v-if="!checkUserCoreRole(['Customer']) && !viewableOnly" type="submit"
                                label="Save and Close" :loading="isSaving" icon="pi pi-check"
                                @click="(saveAndClose = true), save()"
                                class="p-button-raised p-button-lg p-button-info mr-2" />
                            <Button v-if="!viewableOnly" type="button" label="Reset" icon="pi pi-refresh"
                                class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()" />
                            <Button v-if="viewableOnly" type="button" label="Previous" icon="pi pi-arrow-left"
                                class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="movePrevious()" />
                            <Button v-if="viewableOnly" type="button" label="Next" icon="pi pi-arrow-right"
                                class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="moveNext()" />
                        </template>
                        <template v-slot:end>
                            <Button type="button" label="Cancel" icon="pi pi-times"
                                class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm" />
                        </template>
                    </Toolbar>
                </div>
            </div>
        </form>
        <!-- </Sidebar>  -->
    </BlockUI>
    <OrderProductForm v-on:formClose="closeProductForm"
        v-if="showProductForm && (checkPermission(42, 'insert') || checkPermission(42, 'update'))" :orderId="form.order_id"
        :recordId="selectedProductId" :showForm="true" />
    <VehicleForm v-on:formClose="closeVehicleForm" v-if="showVehicleForm && permissions[38].includes('insert')"
        :recordId="0" :showForm="true" />
    <VehicleTypeForm v-on:formClose="closeVehicleTypeForm" v-if="showVehicleTypeForm && permissions[36].includes('insert')"
        :recordId="0" :showForm="true" />
    <JourneyTypesForm v-on:formClose="closeJourneyTypesForm"
        v-if="showJourneyTypeForm && permissions[19].includes('insert')" :recordId="0" :showForm="true" />
</template>
<script>
import OrderProductForm from '../../components/Orders/OrderProductForm.vue';
import VehicleForm from '../../components/Vehicles/VehiclesForm.vue';
import VehicleTypeForm from '../../components/Vehicles/VehicleTypesForm.vue';
import JourneyTypesForm from '../../components/Companies/JourneyTypeForm.vue';
// import { GoogleMap, Polyline,Marker  } from 'vue3-google-map'
import MapAutoComplete from '../../components/MapAutoComplete.vue';
// import GMap from '../../components/GMap.vue';
// import MapAutoComplete2 from '../../components/MapAutoComplete.vue';

import ClassicEditor1 from '@ckeditor/ckeditor5-build-classic';
export default {
    emits: ['editLocation'],
    data() {
        return {
            form: new this.Form({
                id: '',
                company_id: 0,
                order_id: 0,
                vehicle_id: 0,
                inbound_outbound: 'inbound',
                vehicle_type_id: 0,
                vehicle_base_branch_id: 0,
                price: '',
                user_id: 0,
                passangers: 0,
                suitcases: 0,
                child_seats: 0,
                hand_luggage: 0,
                luggage: 0,
                start_time: '',
                end_time: '',
                return_time: '',
                return_end_time: '',
                flight_name: '',
                flight_number: '',
                departure_time: '',
                arrival_time: '',
                per_distance_unit_price_first_journey: 0,
                per_distance_unit_price_return_journey: 0,
                first_journey_price: 0,
                return_journey_price: 0,
                first_journey_duration: '',
                return_journey_duration: '',
                first_journey_distance: '',
                return_journey_distance: '',
                helpers: 0,
                booking_hours: 0,
                is_driver_helper: 0,
                notes: '',
                journey_type_id: 0,
                driver_id: 0,
                secondary_driver_id: 0,
                driver_notes: '',
                flight_notes: '',
                send_quotation: false,
                status: 1,
                urgent_booking: false,
                routes: [],
                orderCargoValues: [],
                orderProducts: [],
                pickup_floor: 0,
                pickup_lift: false,
                dropof_floor: 0,
                dropof_lift: false,
                price_auto_calculation: false,
                quotation_terms: '',
                invoice_terms: '',
                first_journey_time_uncalculated: 0,
                return_journey_time_uncalculated: 0,
            }),
            pageLoadingCompleted: false,
            editor: ClassicEditor1,
            onReady: false,
            cargoGroupTypes: [],
            stay_hours: 0,
            stay_minutes: 0,
            stay_notes: '',
            activeTab: 1,
            total_traveling_price: 0,
            editMapLocation: {},
            displayRouteMap: false,
            journey_type: 'first',
            loadMapAutoComplete: false,
            cancelUpdateLocationBtn: false,
            vehicleTypeCargoGroup: 0,
            vehicleCargoManifest: 0,
            minOrderPrice: 0,
            maxOrderPrice: 0,
            orderPassengers: 0,
            orderLuggage: 0,
            driverUsers: [],
            routesMenue: [],
            activeLocation: [],
            distance: [],
            travelingTime: [],
            activeLocationId: 0,
            center: { lat: 31.51106279999999, lng: 74.341932 },
            polygonMarkers: [],
            markers: [],
            zoomPercentage: [],
            moduleId: 42,
            inboundOutbound: [
                { name: 'Inbound', id: 'inbound' },
                { name: 'OutBound', id: 'outbound' },
            ],
            booleanStatus: [
                { name: 'Yes', id: 1 },
                { name: 'No', id: 0 },
            ],
            vehicleTypes: [],
            vehicles: [],
            branches: [],
            journeyTypes: [],
            returnMinDateTime: '',
            returnEndMinDateTime: '',
            minDateTime: '',
            minJourneyStartDateTime: '',
            selected: null,
            returnAble: false,
            UsersLoading: false,
            VehiclesLoading: false,
            VehicleTypesLoading: false,
            BaseLocationsLoading: false,
            JourneyTypeLoading: false,
            CompanyLoading: false,
            module_name: 'Order Transport',
            isSaving: false,
            saveAndClose: false,
            recId: 0,
            loadingComponent: true,
            formVisible: false,
            companies: [],
            routes: [],
            user: [],
            locationBtnLabel: 'Save',
            JourneyTypes: [
                { name: 'First', id: 'first' },
                { name: 'Return', id: 'return' },
            ],
            showJourneyTypeForm: false,
            showVehicleTypeForm: false,
            showVehicleForm: false,
            orderProductsloading: false,
            showProductForm: false,
            selectedProductId: 0,
            productsMenu: [],
            vehicleTypeData: [],
            firstJourneybookingHours: 2,
            returnJourneybookingHours: 2,
            bookingBeforeTime: 1,
            priceCalculationSteps: [],
            currency: this.userData['company_currency_code'] ? this.userData['company_currency_code'] : 'USD',
        };
    },
    props: {
        showForm: {
            type: Boolean,
            required: true,
        },
        recordId: {
            type: Number,
        },
        viewableOnly: {
            type: Boolean,
            required: true,
        },
    },
    created() {
        this.loadMapAutoComplete = true;

        if (this.checkPermission(this.moduleId, 'delete')) {
            this.routesMenue.push({
                label: 'Delete',
                icon: 'pi pi-times',
                command: () => {
                    this.getClickedRecord('delete');
                },
            });
        }
        if (this.checkPermission(this.moduleId, 'update')) {
            this.routesMenue.push({
                label: 'Update',
                icon: 'pi pi-pencil',
                command: () => {
                    this.getClickedRecord('update');
                },
            });
        }

        //Products Menu
        if (this.checkPermission(42, 'delete')) {
            this.productsMenu.push({
                label: 'Delete',
                icon: 'pi pi-times',
                command: () => {
                    this.getClickedProductRecord('delete');
                },
            });
        }
        if (this.checkPermission(42, 'update')) {
            this.productsMenu.push({
                label: 'Update',
                icon: 'pi pi-pencil',
                command: () => {
                    this.getClickedProductRecord('update');
                },
            });
        }
    },
    computed: {},
    components: {
        MapAutoComplete,
        //  GoogleMap,
        //  Polyline,
        //  Marker ,
        JourneyTypesForm,
        VehicleTypeForm,
        VehicleForm,
        OrderProductForm,
        //  GMap
        //  MapAutoComplete2
    },
    mounted() {
        (this.minJourneyStartDateTime = this.moment().toDate()), (this.user = JSON.parse(localStorage.getItem('user')));
        this.form.order_id = this.recordId;
        // alert(this.form.order_id);
        setTimeout(() => {
            this.loadMapAutoComplete = true;
        }, 1000);
        setTimeout(() => {
            this.loadGMap = true;
        }, 8000);
        this.resetForm();
        this.formVisible = this.showForm;
        this.recId = this.recordId;
        this.getAllRequestData();

        if (this.$route.query.view) {
            this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
            if (Object.keys(this.urlParams).length) {
                this.urlParams.forEach((parameter) => {
                    if (parameter.view == 'transport-tab') {
                        this.activeTab = parameter.id;
                    }
                });
            }
        }
    },
    methods: {
        loadRoutesMapView(interval = 1000, showMap = true, journeyType = this.journey_type) {
            // alert(journeyType);
            // console.log(this.form.routes);
            if (this.form.routes[journeyType] && this.form.routes[journeyType].length >= 2) {
                var journeyRoutes = this.form.routes[journeyType];
                setTimeout(() => {
                    const directionsService = new google.maps.DirectionsService();
                    const directionsRenderer = new google.maps.DirectionsRenderer();
                    if (showMap) {
                        const map = new google.maps.Map(document.getElementById('routesMapView'), {
                            zoom: 6,
                            center: { lat: 41.85, lng: -87.65 },
                        });

                        directionsRenderer.setMap(map);
                    }
                    // this.calculateAndDisplayRoute(directionsService, directionsRenderer);
                    var queryString = {};
                    var stops = [];

                    queryString.origin = { lat: journeyRoutes[0].latitude, lng: journeyRoutes[0].longitude };
                    queryString.destination = { lat: journeyRoutes[journeyRoutes.length - 1].latitude, lng: journeyRoutes[journeyRoutes.length - 1].longitude };
                    if (journeyRoutes.length > 2) {
                        for (var k = 1; k < journeyRoutes.length - 1; k++) {
                            stops.push({
                                location: { lat: journeyRoutes[k].latitude, lng: journeyRoutes[k].longitude },
                                stopover: true,
                            });
                        }

                        queryString.stops = stops;
                    }
                    this.calculateAndDisplayRoute(directionsService, directionsRenderer, queryString, journeyType);
                }, interval);
            }
        },
        calculateAndDisplayRoute(directionsService, directionsRenderer, queryString, journeyType) {
            var journeyRoutes = this.form.routes[journeyType];
            directionsService
                .route({
                    origin: queryString.origin,
                    destination: queryString.destination,
                    waypoints: queryString.stops,
                    optimizeWaypoints: true,
                    travelMode: google.maps.TravelMode.DRIVING,
                })
                .then((response) => {
                    // console.log(journeyType);
                    // console.log(response);
                    directionsRenderer.setDirections(response);
                    const route = response.routes[0];
                    var journeyIndex = 0;
                    var distance = 0;
                    var totalDistance = 0;
                    var totalDistanceMeters = 0;
                    var totalTravelingTime = 0;
                    var totalTravelingUncalculated = 0;
                    var totalDistanceWithOutUnit = 0;
                    journeyRoutes[0].distance = '';
                    journeyRoutes[0].time = '';
                    route.legs.forEach((leg, index) => {
                        totalDistanceMeters = totalDistanceMeters + leg.distance.value;
                        totalTravelingUncalculated = totalTravelingUncalculated + leg.duration.value;
                        journeyIndex = index + 1;
                        distance = leg.distance.text;
                        if (this.defaultDistanceUnit == 'mile') {
                            distance = (leg.distance.value * 0.000621371192).toFixed(0) + ' mile';
                        }
                        journeyRoutes[journeyIndex].rawDistance = leg.distance.value;
                        journeyRoutes[journeyIndex].distance = distance;
                        journeyRoutes[journeyIndex].time = leg.duration.text;
                        journeyRoutes[journeyIndex].rawTime = leg.duration.value;
                    });

                    // Time is in seconds so need to convert it into hours and minutes
                    totalTravelingTime = Math.floor(totalTravelingUncalculated / 3600) + ' hours ' + Math.floor((totalTravelingTime % 3600) / 60) + ' minutes';

                    this.form.routes[journeyType] = journeyRoutes;

                    if (this.defaultDistanceUnit == 'mile') {
                        totalDistanceWithOutUnit = (totalDistanceMeters * 0.000621371192).toFixed(0);
                        totalDistance = totalDistanceWithOutUnit + ' mile';
                    } else if (this.defaultDistanceUnit == 'nmi') {
                        totalDistanceWithOutUnit = (totalDistanceMeters / 1852).toFixed(0);
                        totalDistance = totalDistanceWithOutUnit + ' nmi';
                    } else {
                        totalDistanceWithOutUnit = (totalDistanceMeters / 1000).toFixed(0);
                        totalDistance = totalDistanceWithOutUnit + ' km';
                    }

                    if (journeyType == 'first') {
                        this.distance['first'] = totalDistanceWithOutUnit;
                        this.form.first_journey_distance = totalDistance;
                        this.form.first_journey_duration = totalTravelingTime;
                        this.form.first_journey_time_uncalculated = totalTravelingUncalculated;
                    } else if (journeyType == 'return') {
                        this.distance['return'] = totalDistanceWithOutUnit;
                        this.form.return_journey_distance = totalDistance;
                        this.form.return_journey_duration = totalTravelingTime;
                        this.form.return_journey_time_uncalculated = totalTravelingUncalculated;
                    }

                    this.calculatePrice();
                })
                .catch((e) => {
                    console.log(e);
                    // window.alert("Directions request failed due to " + status)
                });
        },
        checkDrivers($driverType) {
            if (this.form.driver_id == this.form.secondary_driver_id) {
                if ($driverType == 'primary') {
                    this.form.secondary_driver_id = 0;
                } else {
                    this.form.driver_id = 0;
                }
            }
        },
        driverHelperStatus() {
            if (this.form.is_driver_helper) {
                // this.form.helpers += 1;
            } else {
                if (this.form.helpers > 0) {
                    // this.form.helpers -= 1;
                }
            }

            return true;
        },
        closeProductForm() {
            this.getOrderProducts();
            this.showProductForm = false;
        },
        closeVehicleForm() {
            this.getVehicles();
            this.showVehicleForm = false;
        },
        closeVehicleTypeForm() {
            this.getVehicleTypes();
            this.showVehicleTypeForm = false;
        },
        closeJourneyTypesForm() {
            this.getjourneyTypes();
            this.showJourneyTypeForm = false;
        },
        priceCalculationMethodChanged() {
            setTimeout(() => {
                if (this.form.price_auto_calculation) {
                    this.calculatePrice();
                } else {
                    this.priceCalculationSteps = [];
                    this.perUnitPriceChange();
                    this.totalPriceChange();
                }
            }, 500);
        },
        calculateBookingHours() {
            // this.bookingHours = 0;
            // this.bookingBeforeTime = 0;
            var now = '';
            var end = '';
            var duration = '';
            if (this.form.start_time && this.form.start_time != '') {
                if (this.form.end_time && this.form.end_time != '') {
                    now = this.moment(this.form.start_time); //todays date
                    end = this.moment(this.form.end_time); // another date
                    duration = this.moment.duration(end.diff(now));
                    this.firstJourneybookingHours = duration.asHours();
                } else {
                    this.firstJourneybookingHours = this.vehicleTypeData.default_minimum_booking_hours;
                    this.bookingBeforeTime = 1;
                }

                //Calculate booking before time
                now = this.moment(new Date()); //todays date
                end = this.moment(this.form.start_time); // another date
                duration = this.moment.duration(end.diff(now));
                this.bookingBeforeTime = duration.asHours();
            } else {
                this.firstJourneybookingHours = this.vehicleTypeData.default_minimum_booking_hours;
                this.bookingBeforeTime = 1;
            }

            if (this.form.return_time && this.form.return_time != '') {
                if (this.form.return_end_time && this.form.return_end_time != '') {
                    now = this.moment(this.form.return_time); //todays date
                    end = this.moment(this.form.return_end_time); // another date
                    duration = this.moment.duration(end.diff(now));
                    this.returnJourneybookingHours = duration.asHours();
                } else {
                    this.returnJourneybookingHours = this.firstJourneybookingHours;
                }
            } else {
                this.returnJourneybookingHours = 0;
            }
        },
        calculatePrice() {
            // console.log(this.vehicleTypeData);
            setTimeout(() => {
                if (this.form.price_auto_calculation) {
                    if (this.form.vehicle_type_id > 0) {
                        var firstJourney = {
                            start_location: {},
                            stops: [],
                            destination: {},
                            pi_date_time: '',
                            journey_type: 0,
                            calculations: []

                        };

                        var returnJourney = {
                            start_location: {},
                            stops: [],
                            destination: {},
                            pi_date_time: '',
                            journey_type: 0,
                            calculations: []

                        };

                        var firstJourneyTotalDistanceMeters = 0;
                        var firstJourneyTotalDistance = 0;
                        var firstJourneyTotalTime = 0;
                        var returnJourneyTotalDistanceMeters = 0;
                        var returnJourneyTotalDistance = 0;
                        var returnJourneyTotalTime = 0;
                        var distanceArr = [];
                        var distanceUnit = "";
                        var routeDistance = 0;
                        if (this.form.routes['first'] && this.form.routes['first'].length > 0) {
                            this.form.routes['first'].forEach((route, index) => {
                                routeDistance = 0;
                                if (route.rawDistance && route.rawDistance != "") {
                                    firstJourneyTotalDistanceMeters = +firstJourneyTotalDistanceMeters + route.rawDistance;
                                }
                                if (route.distance && route.distance != "") {
                                    distanceArr = route.distance.split(' ');
                                    if (distanceArr[1] && distanceUnit == "") {
                                        distanceUnit = distanceArr[1];
                                    }
                                    if (distanceArr[0]) {
                                        routeDistance = distanceArr[0];
                                    }
                                    firstJourneyTotalDistance = firstJourneyTotalDistance + routeDistance;
                                }

                                if (route.rawTime && route.rawTime != '') {
                                    firstJourneyTotalTime = firstJourneyTotalTime + route.rawTime;
                                }


                                if (index === 0) {
                                    firstJourney.start_location.address = route.address;
                                    firstJourney.start_location.country = route.country;
                                    firstJourney.start_location.state = route.state;
                                    firstJourney.start_location.city = route.city;
                                    firstJourney.start_location.zipcode = route.zipcode;
                                    firstJourney.start_location.latitude = route.latitude;
                                    firstJourney.start_location.longitude = route.longitude;
                                }
                                else if ((index + 1) == this.form.routes['first'].length) {
                                    firstJourney.destination.address = route.address;
                                    firstJourney.destination.country = route.country;
                                    firstJourney.destination.state = route.state;
                                    firstJourney.destination.city = route.city;
                                    firstJourney.destination.zipcode = route.zipcode;
                                    firstJourney.destination.latitude = route.latitude;
                                    firstJourney.destination.longitude = route.longitude;
                                }
                                else {
                                    firstJourney.stops.push({
                                        "address": route.address,
                                        "country": route.country,
                                        "state": route.state,
                                        "city": route.city,
                                        "zipcode": route.zipcode,
                                        "latitude": route.latitude,
                                        "longitude": route.longitude,
                                    });
                                }
                            })
                        }

                        if (this.form.routes['return'] && this.form.routes['return'].length > 0) {
                            this.form.routes['return'].forEach((route, index) => {

                                routeDistance = 0;
                                if (route.rawDistance && route.rawDistance != "") {
                                    returnJourneyTotalDistanceMeters = +returnJourneyTotalDistanceMeters + route.rawDistance;
                                }
                                if (route.distance && route.distance != "") {
                                    distanceArr = route.distance.split(' ');
                                    if (distanceArr[1] && distanceUnit == "") {
                                        distanceUnit = distanceArr[1];
                                    }
                                    if (distanceArr[0]) {
                                        routeDistance = distanceArr[0];
                                    }
                                    returnJourneyTotalDistance = returnJourneyTotalDistance + routeDistance;
                                }

                                if (route.rawTime && route.rawTime != '') {
                                    returnJourneyTotalTime = returnJourneyTotalTime + route.rawTime;
                                }


                                if (index === 0) {
                                    firstJourney.start_location.address = route.address;
                                    firstJourney.start_location.country = route.country;
                                    firstJourney.start_location.state = route.state;
                                    firstJourney.start_location.city = route.city;
                                    firstJourney.start_location.zipcode = route.zipcode;
                                    firstJourney.start_location.latitude = route.latitude;
                                    firstJourney.start_location.longitude = route.longitude;
                                }
                                else if ((index + 1) == this.form.routes['return'].length) {
                                    firstJourney.destination.address = route.address;
                                    firstJourney.destination.country = route.country;
                                    firstJourney.destination.state = route.state;
                                    firstJourney.destination.city = route.city;
                                    firstJourney.destination.zipcode = route.zipcode;
                                    firstJourney.destination.latitude = route.latitude;
                                    firstJourney.destination.longitude = route.longitude;
                                }
                                else {

                                    firstJourney.stops.push({
                                        "address": route.address,
                                        "country": route.country,
                                        "state": route.state,
                                        "city": route.city,
                                        "zipcode": route.zipcode,
                                        "latitude": route.latitude,
                                        "longitude": route.longitude,
                                    });
                                }
                            })
                        }

                        firstJourney.calculations.push({
                            "RawDistanceMeters": firstJourneyTotalDistanceMeters,
                            "distance": firstJourneyTotalDistance + " " + distanceUnit,
                            "time": "", 
                            "RawTimeSeconds": firstJourneyTotalTime
                        });

                        returnJourney.calculations.push({
                            "RawDistanceMeters": returnJourneyTotalDistanceMeters,
                            "distance": returnJourneyTotalDistance + " " + distanceUnit,
                            "time": "", 
                            "RawTimeSeconds": returnJourneyTotalTime
                        });

                        var requestData = {
                            "order_id": this.form.order_id,
                            "company_id": this.form.company_id,
                            "pickup_floor": this.form.pickup_floor,
                            "pickup_lift": this.form.pickup_lift,
                            "dropof_floor": this.form.dropof_floor,
                            "dropof_lift": this.form.dropof_lift,
                            "helpers": this.form.helpers,
                            "is_driver_helper": this.form.is_driver_helper,
                            "vehicle_type_id": this.form.vehicle_type_id,
                            "van": this.form.vehicle_id,
                            "pi_date_time": this.form.start_time,
                            "child_seats": this.form.child_seats,
                            "passengers": this.form.passengers,
                            "suitcases": this.form.suitcases,
                            "hand_luggage": this.form.hand_luggage,
                            "firstJourney": firstJourney,
                            "returnJourney": returnJourney,
                        };

                        this.axios
                            .post('calculateOrderPrice', requestData)
                            .then((response) => {
                                let responseStatus = this.printResponseResult(response, false);
                                if (responseStatus == 200) {
                                    this.form.first_journey_price = response.data.first_journey_price;
                                    this.form.return_journey_price = response.data.return_journey_price;

                                    this.form.per_distance_unit_price_return_journey = (this.form.return_journey_price / this.distance['return']).round(this.priceRounding);
                                    this.form.per_distance_unit_price_first_journey = (this.form.first_journey_price / this.distance['first']).round(this.priceRounding);
                                    this.total_traveling_price = Number(this.form.return_journey_price) + Number(this.form.first_journey_price);

                                }
                            })
                            .catch((error) => {
                                this.printResponseResult(error);
                            })
                            .finally(() => {

                            });











                        // this.priceCalculationSteps = { first: [], return: [], adjustments: [] };

                        // var perHourPrice = 0;
                        // var perDistanceUnitPrice = 0;
                        // var firstJourneyTotalPrice = 0;
                        // var returnJourneyTotalPrice = 0;

                        // this.calculateBookingHours();

                        // if (this.vehicleTypeData.distancePricingRules) {
                        //     this.vehicleTypeData.distancePricingRules.forEach((rule) => {
                        //         if (this.vehicleTypeData.price_calculation_unit == 'Hourly') {
                        //             perHourPrice = rule.price;
                        //         } else {
                        //             perDistanceUnitPrice = rule.price;
                        //         }

                        //         if (this.distance['first'] && this.distance['first'] > 0) {
                        //             if (this.distance['first'] >= rule.from && this.distance['first'] <= rule.to) {
                        //                 if (this.vehicleTypeData.price_calculation_unit == 'Hourly') {
                        //                     firstJourneyTotalPrice = this.firstJourneybookingHours * perHourPrice;
                        //                     this.priceCalculationSteps.first.push({ perHourPrice: perHourPrice, travelingHours: this.firstJourneybookingHours });
                        //                 } else {
                        //                     firstJourneyTotalPrice = perDistanceUnitPrice * this.distance['first'];
                        //                     this.priceCalculationSteps.first.push({ perDistanceUnitPrice: perDistanceUnitPrice, travelingDistance: this.distance['first'] });
                        //                 }
                        //             }
                        //         }
                        //         if (this.distance['return'] && this.distance['return'] > 0) {
                        //             if (this.distance['return'] >= rule.from && this.distance['return'] <= rule.to) {
                        //                 if (this.vehicleTypeData.price_calculation_unit == 'Hourly') {
                        //                     returnJourneyTotalPrice = this.returnJourneybookingHours * perHourPrice;
                        //                     this.priceCalculationSteps.return.push({ perHourPrice: perHourPrice, travelingHours: this.returnJourneybookingHours });
                        //                 } else {
                        //                     returnJourneyTotalPrice = perDistanceUnitPrice * this.distance['return'];
                        //                     this.priceCalculationSteps.return.push({ perDistanceUnitPrice: perDistanceUnitPrice, travelingDistance: this.distance['return'] });
                        //                 }
                        //             }
                        //         }
                        //     });
                        // }


                        // //VEHICLE FIXED PRICE
                        // if (this.vehicleTypeData.vehicle_fixed_amount) {
                        //     firstJourneyTotalPrice = firstJourneyTotalPrice + this.vehicleTypeData.vehicle_fixed_amount;
                        //     this.priceCalculationSteps.first.push({ vehicleFixedPrice: this.vehicleTypeData.vehicle_fixed_amount });
                        // }


                        // //CALCULATE JOURNEY PRICE BY PER DISTANCE UNIT PRICE
                        // if (firstJourneyTotalPrice < 1) {
                        //     firstJourneyTotalPrice = this.distance['first'] * this.vehicleTypeData.per_distance_unit_price;
                        //     this.priceCalculationSteps.first.push({ perDistanceUnitPrice: this.vehicleTypeData.per_distance_unit_price, travelingDistance: this.distance['first'] });
                        // }

                        // if (returnJourneyTotalPrice < 1) {
                        //     returnJourneyTotalPrice = this.distance['return'] * this.vehicleTypeData.per_distance_unit_price;
                        //     this.priceCalculationSteps.return.push({ perDistanceUnitPrice: this.vehicleTypeData.per_distance_unit_price, travelingDistance: this.distance['return'] });
                        // }

                        // //CALCULATE PRICE FOR STOPS
                        // if (this.form.routes['first'] && this.form.routes['first'].length > 0) {
                        //     var stops = this.form.routes['first'].length - 2; //Substract pickup and dropof location
                        //     if (stops > this.vehicleTypeData.default_stops) {
                        //         var priceAbleStops = stops - this.vehicleTypeData.default_stops;
                        //         var firstStopPrice = priceAbleStops * this.vehicleTypeData.every_extra_stop_price;
                        //         firstJourneyTotalPrice = firstJourneyTotalPrice + firstStopPrice;
                        //         // totalPrice = totalPrice +  firstStopPrice;
                        //         this.priceCalculationSteps.first.push({ priceAbleStops: priceAbleStops, pricePerStop: this.vehicleTypeData.every_extra_stop_price });
                        //     }
                        // }

                        // if (this.form.routes['return'] && this.form.routes['return'].length > 0) {
                        //     var stopsR = this.form.routes['return'].length - 2; //Substract pickup and dropof location
                        //     // if(stops > this.vehicleTypeData.default_stops){
                        //     // var priceAbleStops = stops - this.vehicleTypeData.default_stops;
                        //     var priceAbleStopsR = stopsR;
                        //     var returnStopPriceR = priceAbleStopsR * this.vehicleTypeData.every_extra_stop_price;
                        //     returnJourneyTotalPrice = returnJourneyTotalPrice + returnStopPriceR;
                        //     // totalPrice = totalPrice +  returnStopPriceR;
                        //     this.priceCalculationSteps.return.push({ priceAbleStopsR: priceAbleStopsR, pricePerStop: this.vehicleTypeData.every_extra_stop_price });

                        //     // }
                        // }

                        // //CALCULATE PRICE FOR DRIVER AS A HELPER
                        // if (this.form.is_driver_helper) {
                        //     let driver_as_helper_price_first = (this.vehicleTypeData.driver_as_helper_price * this.firstJourneybookingHours);

                        //     this.priceCalculationSteps.first.push({ driverAsHelperPriceFirst: driver_as_helper_price_first, driverAsHelperPrice: this.vehicleTypeData.driver_as_helper_price, priceAbleHours: this.firstJourneybookingHours });

                        //     let driver_as_helper_price_return = (this.vehicleTypeData.driver_as_helper_price * this.returnJourneybookingHours);

                        //     this.priceCalculationSteps.return.push({ driverAsHelperPriceReturn: driver_as_helper_price_return, driverAsHelperPrice: this.vehicleTypeData.driver_as_helper_price, priceAbleHours: this.returnJourneybookingHours });

                        //     firstJourneyTotalPrice = firstJourneyTotalPrice + driver_as_helper_price_first;
                        //     returnJourneyTotalPrice = returnJourneyTotalPrice + driver_as_helper_price_return;
                        // }

                        // //CALCULATE PRICE FOR HELPERS
                        // if (this.form.helpers > 0) {
                        //     if (this.form.helpers > this.vehicleTypeData.default_helpers) {
                        //         var priceAbleHelpers = this.form.helpers - this.vehicleTypeData.default_helpers;
                        //         var priceForHelpersFirst = this.firstJourneybookingHours * (priceAbleHelpers * this.vehicleTypeData.every_extra_helper_price);
                        //         var priceForHelpersReturn = this.returnJourneybookingHours * (priceAbleHelpers * this.vehicleTypeData.every_extra_helper_price);
                        //         firstJourneyTotalPrice = firstJourneyTotalPrice + priceForHelpersFirst;
                        //         returnJourneyTotalPrice = returnJourneyTotalPrice + priceForHelpersReturn;
                        //         // totalPrice = totalPrice + priceForHelpersFirst;
                        //         // totalPrice = totalPrice + priceForHelpersReturn;
                        //         this.priceCalculationSteps.first.push({ priceAbleHelpers: priceAbleHelpers, priceForHelper: this.vehicleTypeData.every_extra_helper_price, priceForHelperHours: this.firstJourneybookingHours });
                        //         this.priceCalculationSteps.return.push({ priceAbleHelpers: priceAbleHelpers, priceForHelper: this.vehicleTypeData.every_extra_helper_price, priceForHelperHours: this.returnJourneybookingHours });
                        //     }
                        // }

                        // //MINIMUM BOOKING HOURS PRICE CALCULATION
                        // var totalBookingHours = this.returnJourneybookingHours + this.firstJourneybookingHours;
                        // if (totalBookingHours > 0) {
                        //     if (totalBookingHours < this.vehicleTypeData.default_minimum_booking_hours) {
                        //         if (this.vehicleTypeData.price_calculation_unit == 'Hourly') {
                        //             var priceAbleHours = this.vehicleTypeData.default_minimum_booking_hours - totalBookingHours;
                        //             var minimumBookingHoursPriceAdjustment = perHourPrice * priceAbleHours;
                        //             firstJourneyTotalPrice = firstJourneyTotalPrice + minimumBookingHoursPriceAdjustment;
                        //             // totalPrice = totalPrice + minimumBookingHoursPriceAdjustment;
                        //             this.priceCalculationSteps.adjustments.push({ minimumBookingHours: { perHourPrice: perHourPrice, priceAbleHours: priceAbleHours } });
                        //         }
                        //     }
                        // }

                        // //FIXED TIME PRICE CALCULATION
                        // if (this.vehicleTypeData.fixedTimeArrivals) {
                        //     if (Object.keys(this.vehicleTypeData.fixedTimeArrivals).length > 0) {
                        //         let hour_charged = false;
                        //         this.vehicleTypeData.fixedTimeArrivals.forEach((time) => {
                        //             if (this.bookingBeforeTime >= time.from_hours && this.bookingBeforeTime <= time.to_hours && !hour_charged) {
                        //                 this.priceCalculationSteps.adjustments.push({ fixedTimePrice: { priceIncrease: time.price } });
                        //                 firstJourneyTotalPrice = firstJourneyTotalPrice + time.price;
                        //                 hour_charged = true;
                        //                 // totalPrice += time.price;
                        //             }
                        //         });
                        //     }
                        // }

                        // //PICKUP AND DROPOF PRICE CALCULATION
                        // if (this.form.pickup_floor > 0) {
                        //     if (this.form.pickup_lift) {
                        //         this.priceCalculationSteps.adjustments.push({ pickup_withLiftPriceIncrease: { priceIncrease: this.vehicleTypeData.fixed_price_increase_with_lift } });
                        //         firstJourneyTotalPrice = firstJourneyTotalPrice + this.vehicleTypeData.fixed_price_increase_with_lift;
                        //     } else {
                        //         var withoutLiftCost = Number(this.vehicleTypeData.every_floor_price_with_no_lift) * Number(this.form.pickup_floor);
                        //         firstJourneyTotalPrice = firstJourneyTotalPrice + withoutLiftCost;
                        //         this.priceCalculationSteps.adjustments.push({ pickup_withOutLiftPriceIncrease: { priceIncrease: withoutLiftCost } });
                        //     }
                        // }

                        // if (this.form.dropof_floor > 0) {
                        //     if (this.form.dropof_lift) {
                        //         this.priceCalculationSteps.adjustments.push({ dropof_withLiftPriceIncrease: { priceIncrease: this.vehicleTypeData.fixed_price_increase_with_lift } });
                        //         firstJourneyTotalPrice = firstJourneyTotalPrice + this.vehicleTypeData.fixed_price_increase_with_lift;
                        //     } else {
                        //         var withoutLiftCostDropof = Number(this.vehicleTypeData.every_floor_price_with_no_lift) * Number(this.form.dropof_floor);
                        //         firstJourneyTotalPrice = firstJourneyTotalPrice + withoutLiftCostDropof;
                        //         this.priceCalculationSteps.adjustments.push({ dropof_withOutLiftPriceIncrease: { priceIncrease: withoutLiftCostDropof } });
                        //     }
                        // }

                        // //CALCULATE TOTAL PRICE
                        // this.form.first_journey_price = firstJourneyTotalPrice;
                        // this.form.return_journey_price = returnJourneyTotalPrice;
                        // this.form.per_distance_unit_price_return_journey = (this.form.return_journey_price / this.distance['return']).round(this.priceRounding);
                        // this.form.per_distance_unit_price_first_journey = (this.form.first_journey_price / this.distance['first']).round(this.priceRounding);

                        // this.total_traveling_price = Number(this.form.return_journey_price) + Number(this.form.first_journey_price);
                        // // console.log(this.priceCalculationSteps.first[0].travelingHours);
                        // // console.log(this.priceCalculationSteps.first[0].travelingHours);
                    }
                    else {
                        this.form.price_auto_calculation = 0;
                        this.showMessage('Select Vehicle Type First', 'error');
                    }
                }
            }, 200);
        },
        perUnitPriceChange() {
            if (!this.form.price_auto_calculation) {
                setTimeout(() => {
                    if (this.distance['return']) {
                        if (!this.form.per_distance_unit_price_return_journey) {
                            this.form.per_distance_unit_price_return_journey = 0;
                        }
                        this.form.return_journey_price = (Number(this.distance['return']) * this.form.per_distance_unit_price_return_journey).round(this.priceRounding);
                    }
                    if (this.distance['first']) {
                        if (!this.form.per_distance_unit_price_first_journey) {
                            this.form.per_distance_unit_price_first_journey = 0;
                        }
                        this.form.first_journey_price = (Number(this.distance['first']) * this.form.per_distance_unit_price_first_journey).round(this.priceRounding);
                    }
                }, 100);
            }
            // setTimeout( () => {
            //     this.generateMarkers();
            // },1000);
            return true;
        },
        totalPriceChange() {
            if (!this.form.price_auto_calculation) {
                setTimeout(() => {
                    if (this.distance['return']) {
                        if (!this.form.return_journey_price) {
                            this.form.return_journey_price = 0;
                        }
                        this.form.per_distance_unit_price_return_journey = this.form.return_journey_price / this.distance['return'];
                    }
                    if (this.distance['first']) {
                        if (!this.form.first_journey_price) {
                            this.form.first_journey_price = 0;
                        }
                        this.form.per_distance_unit_price_first_journey = this.form.first_journey_price / this.distance['first'];
                    }
                }, 100);
            }
            // setTimeout( () => {
            //     this.generateMarkers();
            // },1000);
            return true;
        },

        journeyTabClicked($event) {
            if ($event.index === 0) {
                this.journey_type = 'first';
            } else if ($event.index === 1) {
                // console.log(this.form.routes);
                this.journey_type = 'return';
            }
        },
        tabClicked($event) {
            // console.log($event.index);
            this.removeViewFromURL('transport-tab');
            this.addViewInURL({ view: 'transport-tab', id: this.activeTab });
        },
        getClickedProductRecord(action) {
            if (action == 'delete') {
                this.deleteOrderProduct();
            }
            if (action == 'update') {
                this.showProductForm = true;
            }
        },
        getClickedRecord(action) {
            if (action == 'delete') {
                this.deleteLocation();
            }
            if (action == 'update') {
                this.updateLocation();
                this.locationBtnLabel = 'Update';
                this.cancelUpdateLocationBtn = true;
                document.getElementsByClassName('mapAutoComplete')[0].focus();
            }
        },

        deleteOrderProduct() {
            var Vthis = this;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirmation',
                message: 'Are you sure you want to delete?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    Vthis.loading = true;
                    let DeleteAbleRecords = [];
                    if (Vthis.selectedRecords) {
                        Vthis.selectedRecordId = '';
                        Vthis.selectedRecords.forEach((data) => {
                            DeleteAbleRecords.push(data.id);
                        });
                    } else {
                        DeleteAbleRecords.push(Vthis.selectedProductId);
                    }

                    Vthis.axios
                        .post('deleteOrderProduct', { id: DeleteAbleRecords })
                        .then((response) => {
                            let responseStatus = this.printResponseResult(response, false);
                            if (responseStatus == 200) {
                                Vthis.getOrderProducts();
                                Vthis.showMessage('Product deleted successfully', 'success');
                            }
                        })
                        .catch((error) => {
                            this.printResponseResult(error);
                        })
                        .finally(() => {
                            Vthis.loading = false;
                        });
                },
                reject: () => {
                    // this.loading = false;
                },
            });
        },
        cancelUpdateLocation() {
            this.locationBtnLabel = 'Save';
            this.cancelUpdateLocationBtn = false;
            this.activeLocationId = 0;
            // this.journey_type = 'first';
            // this.journey_type = 'first';
            this.stay_hours = '';
            this.stay_minutes = '';
            this.stay_notes = '';
            this.editMapLocation = {
                address_description: '',
                query: '',
                city: 'lahore',
                country: 'pakistan',
                lat: 41.3267905,
                lng: 19.8060475,
                state: 'punjab',
                zip_code: '54000',
                zoom: 17,
            };
            this.emitter.emit('editLocation', this.editMapLocation);
            document.getElementById('pac-input').value = '';
        },
        updateLocation() {
            var Vthis = this;
            var editLocation = {};

            if (this.journey_type && this.journey_type != '') {
                if (this.form.routes[this.journey_type] && this.form.routes[this.journey_type].length) {
                    this.form.routes[this.journey_type] = this.form.routes[this.journey_type].filter((route) => {
                        if (route.locationId == Vthis.activeLocationId) {
                            Vthis.journey_type = this.journey_type;
                            Vthis.stay_hours = route.stay_hours;
                            Vthis.stay_minutes = route.stay_minutes;
                            Vthis.stay_notes = route.stay_notes;
                            editLocation.lat = route.latitude;
                            editLocation.lng = route.longitude;
                            editLocation.address_description = route.address;
                            editLocation.country = route.country;
                            editLocation.state = route.state;
                            editLocation.city = route.city;
                            editLocation.zip_code = route.zipcode;
                            editLocation.zoom = 15;
                        }
                        return route;
                    });
                }
            }

            this.editMapLocation = editLocation;
            // this.EventBus.emit('editLocation',editLocation);
            this.emitter.emit('editLocation', this.editMapLocation);
        },
        deleteLocation() {
            var Vthis = this;
            if (this.journey_type && this.journey_type != '') {
                if (this.form.routes[this.journey_type]) {
                    this.form.routes[this.journey_type] = this.form.routes[this.journey_type].filter((route) => {
                        if (route.locationId != Vthis.activeLocationId) {
                            return route;
                        }
                    });
                }
            }

            Vthis.activeLocationId = 0;

            if (this.form.routes[this.journey_type].length < 2) {
                if (this.journey_type == 'first') {
                    this.distance['first'] = 0;
                    this.form.first_journey_price = 0;
                    this.form.first_journey_distance = 0;
                    this.form.per_distance_unit_price_first_journey = 0;
                } else if (this.journey_type == 'return') {
                    this.distance['return'] = 0;
                    this.form.return_journey_price = 0;
                    this.form.return_journey_distance = 0;
                    this.form.per_distance_unit_price_return_journey = 0;
                }
            } else {
                this.loadRoutesMapView(0, false);
            }
        },
        toggleProductMenu(event) {
            this.selectedProductId = event.currentTarget.getAttribute('productId');
            this.$refs.menu2.toggle(event);
        },
        toggleMenu(event) {
            this.activeLocationId = event.currentTarget.getAttribute('locationId');
            this.$refs.menu.toggle(event);
        },
        setLocation(location) {
            this.activeLocation = location;
        },
        setReturnJourney() {
            if (this.form.routes['first'].length) {
                this.form.routes['return'] = [];
                this.form.routes['first'].forEach((route, index) => {
                    route = JSON.parse(JSON.stringify(route));
                    this.form.routes['return'].push(route);
                    this.form.routes['return'][index].locationId = this.generateRandomNumber(10000000);
                });

                this.form.routes['return'] = this.form.routes['return'].reverse();

                this.loadRoutesMapView(0, false, 'return');
                this.form.per_distance_unit_price_return_journey = this.form.per_distance_unit_price_first_journey;
                this.form.return_journey_price = this.form.first_journey_price;
                this.form.return_journey_duration = this.form.first_journey_duration;
            }
        },
        addNewRoute() {
            var Vthis = this;
            if (this.form.routes == undefined) {
                this.form.routes = [];
            }
            if (!this.form.routes[this.journey_type]) {
                this.form.routes[this.journey_type] = [];
            }
            var push = true;
            if (this.form.routes[this.journey_type].length) {
                this.form.routes[this.journey_type].forEach((route) => {
                    if (Vthis.activeLocationId < 1 && route.latitude == Vthis.activeLocation.lat && route.longitude == Vthis.activeLocation.lng) {
                        push = false;
                    }
                    if (Vthis.activeLocationId > 0 && route.locationId == Vthis.activeLocationId) {
                        Vthis.locationBtnLabel = 'Save';
                        Vthis.activeLocationId = 0;
                        push = false;
                        route.stay_hours = Vthis.stay_hours;
                        route.stay_minutes = Vthis.stay_minutes;
                        route.stay_notes = Vthis.stay_notes;
                        route.address = Vthis.activeLocation.address_description;
                        route.latitude = Vthis.activeLocation.lat;
                        route.longitude = Vthis.activeLocation.lng;
                        route.country = Vthis.activeLocation.country;
                        route.state = Vthis.activeLocation.state;
                        route.city = Vthis.activeLocation.city;
                        route.zipcode = Vthis.activeLocation.zip_code;
                        Vthis.stay_hours = 0;
                        Vthis.stay_minutes = 0;
                        Vthis.stay_notes = '';

                        this.cancelUpdateLocationBtn = false;
                        document.getElementsByClassName('mapAutoComplete')[0].value = '';
                        document.getElementsByClassName('mapAutoComplete')[0].focus();
                        Vthis.activeLocationId = '';
                    }
                });
            }
            if (push) {
                var locationId = this.generateRandomNumber(10000000);
                this.form.routes[this.journey_type].push({
                    stay_hours: Vthis.stay_hours,
                    stay_minutes: Vthis.stay_minutes,
                    stay_notes: Vthis.stay_notes,
                    address: Vthis.activeLocation.address_description,
                    latitude: Vthis.activeLocation.lat,
                    longitude: Vthis.activeLocation.lng,
                    country: Vthis.activeLocation.country,
                    state: Vthis.activeLocation.state,
                    city: Vthis.activeLocation.city,
                    zipcode: Vthis.activeLocation.zip_code,
                    locationId: locationId,
                });

                Vthis.stay_hours = 0;
                Vthis.stay_minutes = 0;
                Vthis.stay_notes = '';
            }

            // this.generateMarkers();
            this.loadRoutesMapView(0, false);
        },
        generateRandomNumber(maxNum) {
            return Math.floor(Math.random() * maxNum);
        },

        showRoutesDistanceAndTime() {
            this.loadRoutesMapView(0, false, 'first');
            setTimeout(() => {
                this.loadRoutesMapView(0, false, 'return');
            }, 1000);

            // if(this.form.price_auto_calculation && !directCall){
            //     setTimeout( () => {
            //         this.calculatePrice();
            //     },1500)
            // }
        },

        calcCrow(lat1, lon1, lat2, lon2) {
            var R = 6371; // km
            var dLat = this.toRad(lat2 - lat1);
            var dLon = this.toRad(lon2 - lon1);
            lat1 = this.toRad(lat1);
            lat2 = this.toRad(lat2);

            var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c;
            return d;
        },

        setCenterCoordinates() {
            var sumX = 0;
            var sumY = 0;
            var sumZ = 0;

            // for (var i=0; i<this.form.routes[this.journey_type].length; i++) {
            if (this.form.routes[this.journey_type]) {
                this.form.routes[this.journey_type].forEach((route) => {
                    var lat = this.degr2rad(route.latitude);
                    var lng = this.degr2rad(route.longitude);
                    // sum of cartesian coordinates
                    sumX += Math.cos(lat) * Math.cos(lng);
                    sumY += Math.cos(lat) * Math.sin(lng);
                    sumZ += Math.sin(lat);
                });
            }

            // }
            if (this.form.routes[this.journey_type]) {
                var avgX = sumX / this.form.routes[this.journey_type].length;
                var avgY = sumY / this.form.routes[this.journey_type].length;
                var avgZ = sumZ / this.form.routes[this.journey_type].length;

                // convert average x, y, z coordinate to latitude and longtitude
                var lng = Math.atan2(avgY, avgX);
                var hyp = Math.sqrt(avgX * avgX + avgY * avgY);
                var lat = Math.atan2(avgZ, hyp);

                this.center.lat = this.rad2degr(lat);
                this.center.lng = this.rad2degr(lng);
                // return ([rad2degr(lat), rad2degr(lng)]);
            }
        },
        rad2degr(rad) {
            return (rad * 180) / Math.PI;
        },
        degr2rad(degr) {
            return (degr * Math.PI) / 180;
        },
        // Converts numeric degrees to radians
        toRad(Value) {
            return (Value * Math.PI) / 180;
        },
        startDateTimeSelected($event) {
            this.form.end_time = this.form.return_time = this.form.return_end_time = this.returnMinDateTime = this.returnEndMinDateTime = '';

            var $selectedDate = $event;
            this.minDateTime = new Date($selectedDate);
            this.returnMinDateTime = new Date($selectedDate);
            this.returnEndMinDateTime = new Date($selectedDate);
        },
        endDateTimeSelected($event) {
            this.returnMinDateTime = this.returnEndMinDateTime = this.form.return_time = this.form.return_end_time = '';
            var $selectedDate = $event;
            this.returnMinDateTime = new Date($selectedDate);
            this.returnEndMinDateTime = new Date($selectedDate);
        },
        returnDateTimeSelected($event) {
            this.returnEndMinDateTime = this.form.return_end_time = '';
            var $selectedDate = $event;
            this.returnEndMinDateTime = new Date($selectedDate);
        },
        getAllRequestData() {
            this.$axios
                .all([this.updateRecord()])
                .then(() => {
                    this.getCompanies();
                    this.getVehicleTypes();
                    return this.VehicleTypeChanged(true);
                })
                .then(() => {
                    return this.getjourneyTypes();
                })

                .then(() => {
                    this.getBaseBranches();
                    return this.getOrderProducts();
                })
                .then(() => {
                    return this.getVehicleTypeCargoTypes();
                })
                .then(() => {
                    return this.getVehicles();
                })
                .then(() => {
                    return this.getDriverUsers();
                })
                .then(() => {
                    this.blockComponent = false;
                })
                .catch(() => {
                    //    this.getAllRequestData();
                })
                .finally(() => {
                    this.showRoutesDistanceAndTime(true);
                    if (this.form.price_auto_calculation) {
                        // setTimeout( () => {
                        //     // this.calculatePrice();
                        // },1000);
                    }
                });
        },

        async getOrderProducts() {
            this.orderProductsloading = true;
            await this.axios
                .post('getAllOrderProducts', { orderId: this.form.order_id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.form.orderProducts = response.data;
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => { });
        },
        async getDriverUsers() {
            var Vthis = this;
            this.loading = true;
            await this.axios
                .post('getRoleSpecificUsers', { role: 'Driver', dropdown: true })
                .then((response) => {
                    this.driverUsers = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => { });
        },
        async VehicleTypeChanged(directCall = false) {
            var Vthis = this;
            await this.axios
                .post('getAllVehicleTypes', { id: this.form.vehicle_type_id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        var vehicleType = response.data[0];
                        this.vehicleTypeData = vehicleType;

                        // if (this.bookingBeforeTime <= this.vehicleTypeData.urgent_booking_hours) {
                        //     this.form.urgent_booking = true;
                        // }

                        // // console.log(vehicleType);

                        // this.form.orderCargoValues = vehicleType.cargoGroupTypes;

                        // // this.defaultDistanceUnit = vehicleType.vehicleDistanceUnit;
                        // this.vehicleTypeCargoGroup = vehicleType.vehicle_type_group_name;
                        // this.vehicleCargoManifest = vehicleType.cargo_manifest_name;
                        // this.minOrderPrice = vehicleType.min_price;
                        // this.maxOrderPrice = vehicleType.max_price;
                        // this.orderPassengers = vehicleType.passengers;
                        // this.orderLuggage = vehicleType.luggage;

                        // this.setFocus('vehicleType12');
                        // this.$toast.removeAllGroups();
                        if (this.form.passangers > 0 && vehicleType.passengers && this.form.passangers > vehicleType.passengers) {
                            this.showMessage('The selected vehicle is not fit for this number of passangers. Please change it', 'warn', 5000);
                        }
                        if (this.form.luggage > 0 && vehicleType.luggage && this.form.luggage > vehicleType.luggage) {
                            this.showMessage('The selected vehicle is not fit for this number of luggage. Please change it', 'warn', 5000);
                        }

                        // if (this.form.price_auto_calculation && !directCall) {
                        //     this.form.per_distance_unit_price_return_journey = vehicleType.per_distance_unit_price;
                        //     this.form.per_distance_unit_price_first_journey = vehicleType.per_distance_unit_price;
                        //     if (this.distance['return']) {
                        //         this.form.return_journey_price = Number(this.distance['return']) * this.form.per_distance_unit_price_return_journey;
                        //     }
                        //     if (this.distance['first']) {
                        //         this.form.first_journey_price = Number(this.distance['first']) * this.form.per_distance_unit_price_first_journey;
                        //     }
                        //     this.calculatePrice();
                        // }

                        this.calculatePrice();
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => { });
        },
        async getVehicles() {
            if (this.form.vehicle_type_id) {
                var Vthis = this;
                Vthis.VehiclesLoading = true;
                await this.axios
                    .post('getAllVehicles', { vehicle_type: this.form.vehicle_type_id, dropdown: true })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            this.vehicles = response.data;
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.VehiclesLoading = false;
                    });
            }
        },
        async getVehicleTypeCargoTypes() {
            // if(this.form.vehicle_type_id){
            //     if(!this.form.orderCargoValues){
            //         this.form.orderCargoValues = [];
            //     }
            //     var Vthis = this;
            //     Vthis.VehiclesLoading = true;
            //     await this.axios.post('getAllVehicleTypes',{id:this.form.vehicle_type_id,dropdown:true})
            //     .then( (response) => {
            //         let responseStatus = this.printResponseResult(response,false);
            //         if(responseStatus == 200){
            //             if(response.data[0]){
            //                 if(Object.keys(this.form.orderCargoValues).length < 1){
            //                     this.form.orderCargoValues = response.data[0].cargoGroupTypes;
            //                 }
            //                 this.cargoGroupTypes = response.data[0].cargoGroupTypes;
            //             }
            //         }
            //     })
            //     .catch((error) => {
            //         this.printResponseResult(error);
            //     })
            //     .finally(() => {
            //         Vthis.VehiclesLoading = false;
            //     })
            // }
        },
        async getVehicleTypes() {
            var Vthis = this;
            Vthis.VehicleTypesLoading = true;
            await this.axios
                .post('getAllVehicleTypes', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.vehicleTypes = response.data;
                        if (this.vehicleTypes && Object.keys(this.vehicleTypes).length > 0) {
                            this.vehicleTypes.forEach((type) => {
                                if (this.form.vehicle_type_id == type.id) {
                                    setTimeout(() => {
                                        if (!this.form.per_distance_unit_price_first_journey) {
                                            this.form.per_distance_unit_price_first_journey = type.per_distance_unit_price;
                                        }
                                        if (!this.form.per_distance_unit_price_return_journey) {
                                            this.form.per_distance_unit_price_return_journey = type.per_distance_unit_price;
                                        }
                                        //Calculate Price
                                        if (this.distance['return']) {
                                            this.form.return_journey_price = Number(this.distance['return']) * this.form.per_distance_unit_price_return_journey;
                                        }
                                        if (this.distance['first']) {
                                            this.form.first_journey_price = Number(this.distance['first']) * this.form.per_distance_unit_price_first_journey;
                                        }
                                    }, 10);

                                    // this.defaultDistanceUnit = type.vehicleDistanceUnit;
                                }
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.VehicleTypesLoading = false;
                });
        },
        async getBaseBranches() {
            var Vthis = this;
            Vthis.BaseLocationsLoading = true;
            await this.axios
                .post('getAllBranches', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        if (response.data.length) {
                            response.data.forEach((record, index) => {
                                if (index == 0) {
                                    this.form.vehicle_base_branch_id = record.id;
                                }
                                if (record.company_id == this.user.company_id && record.is_headoffice == 1) {
                                    this.form.vehicle_base_branch_id = record.id;
                                }
                            });
                        }
                        this.branches = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.BaseLocationsLoading = false;
                });
        },

        async getjourneyTypes() {
            var Vthis = this;
            Vthis.JourneyTypeLoading = true;
            await this.axios
                .post('getAllJourneyTypes', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.journeyTypes = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.JourneyTypeLoading = false;
                });
        },

        async getCompanies() {
            var Vthis = this;
            Vthis.CompanyLoading = true;
            await this.axios
                .post('getAllCompanies', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        if (response.data.length) {
                            response.data.forEach((record) => {
                                if (record.id == this.user.company_id) {
                                    this.form.company_id = record.id;
                                }
                            });
                        }
                        this.companies = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.CompanyLoading = false;
                });
        },

        resetForm() {
            this.form.reset();
        },
        movePrevious() {
            this.$emit('loadPreviousTab', true);
        },
        moveNext() {
            this.$emit('loadNextTab', true);
        },
        async save() {
            this.form.order_id = this.recordId;
            var Vthis = this;
            this.isSaving = true;
            this.form.transportRoutes = Object.assign({}, this.form.routes);
            // console.log(this.form.routes);
            // this.form.transportRoutes = this.form.routes;
            await this.form
                .post(Vthis.$baseurl + 'api/saveOrderTransport')
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.send_quotation = false;

                        if (!this.recId) {
                            //  this.getRecords();
                        }
                        if (this.saveAndClose) {
                            this.saveAndClose = false;
                        }
                        // alert(this.activeTab);
                        if (this.activeTab > 0) {
                            this.$emit('loadNextTab', true);
                        }
                        if (checkUserCoreRole(['Customer'])) {
                            this.emitter.emit('orderCreated', response.data.order_id);
                        }

                        // this.activeTab = this.activeTab+1;
                        Vthis.showMessage('Saved successfully', 'success');
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false;
                });
        },
        loadForm() {
            this.resetForm();
        },
        closeForm() {
            this.loadGMap = false;
            this.loadMapAutoComplete = false;
            this.$emit('formClose', true);
            // this.$emit('closeOrderFrom',true);
        },

        async updateRecord() {
            if (this.recId > 0) {
                var Vthis = this;
                this.loading = true;
                await this.axios
                    .post('getAllOrderTransports', { id: 0, order_id: this.recordId })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            // response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            // response.data[0].arrival_time = new Date()

                            if (response.data[0] && Object.keys(response.data[0]).length) {
                                Vthis.form.fill(this.replaceNullValues(response.data[0]));
                            }

                            if (response.data['company_data']) {
                                Vthis.form.invoice_terms = response.data['company_data']['invoice_terms'] ? response.data['company_data']['invoice_terms'] : '';
                                Vthis.form.quotation_terms = response.data['company_data']['quotation_terms'] ? response.data['company_data']['quotation_terms'] : '';
                                Vthis.currency = response.data['company_data']['currency'] ? response.data['company_data']['currency'] : this.defaultCurrency;
                                Vthis.defaultDistanceUnit = response.data['company_data']['distance_unit'] ? response.data['company_data']['distance_unit'] : this.defaultDistanceUnit;
                            }
                            if (response.data[0]['company_data']) {
                                Vthis.form.invoice_terms = response.data[0]['company_data']['invoice_terms'] ? response.data[0]['company_data']['invoice_terms'] : '';
                                Vthis.form.quotation_terms = response.data[0]['company_data']['quotation_terms'] ? response.data[0]['company_data']['quotation_terms'] : '';
                                Vthis.currency = response.data[0]['company_data']['currency'] ? response.data[0]['company_data']['currency'] : this.defaultCurrency;
                                Vthis.defaultDistanceUnit = response.data[0]['company_data']['distance_unit'] ? response.data[0]['company_data']['distance_unit'] : this.defaultDistanceUnit;
                            }

                            if (response.data[0].price_auto_calculation) {
                                Vthis.form.price_auto_calculation = true;
                            } else {
                                Vthis.form.price_auto_calculation = false;
                            }

                            if (!response.data[0].routes) {
                                this.form.routes = [];
                            }

                            // this.loadRoutesMapView(0,false);

                            Vthis.distance['first'] = response.data[0].first_journey_distance != '' ? response.data[0].first_journey_distance.split(' ')[0] : 0;
                            Vthis.distance['return'] = response.data[0].first_journey_distance != '' ? response.data[0].return_journey_distance.split(' ')[0] : 0;

                            setTimeout(() => {
                                Vthis.pageLoadingCompleted = true;
                            }, 1000)
                            // Vthis.form.per_distance_unit_price_first_journey= (response.data[0].first_journey_price/Number(Vthis.distance['first'])).toFixed(10);
                            // Vthis.form.per_distance_unit_price_return_journey= (response.data[0].return_journey_price/Number(Vthis.distance['return'])).toFixed(10);
                            // Vthis.generateMarkers();
                            // Vthis.calculatePrice();
                        } else {
                            Vthis.distance['first'] = 0;
                            Vthis.distance['return'] = 0;
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.loading = false;
                    });
            }
        },

        setFocus(elementREF) {
            this.$refs[elementREF].$el.focus();
        },
    },
    watch: {
        'form.pickup_lift': {
            handler() {
                if (this.pageLoadingCompleted) {
                    this.calculatePrice();
                }
            },
        },
        'form.dropof_lift': {
            handler() {
                if (this.pageLoadingCompleted) {
                    this.calculatePrice();
                }
            },
        },
        'form.pickup_floor': {
            handler() {
                if (this.pageLoadingCompleted) {
                    this.calculatePrice();
                }
            },
        },
        'form.dropof_floor': {
            handler() {
                if (this.pageLoadingCompleted) {
                    this.calculatePrice();
                }
            },
        },
        'form.helpers': {
            handler() {
                if (this.pageLoadingCompleted) {
                    this.calculatePrice();
                }
            },
        },
        'form.start_time': {
            handler() {
                if (this.pageLoadingCompleted) {
                    this.calculatePrice();
                }
            },
        },
        'form.end_time': {
            handler() {
                if (this.pageLoadingCompleted) {
                    this.calculatePrice();
                }
            },
        },
        'form.return_time': {
            handler() {
                if (this.pageLoadingCompleted) {
                    this.calculatePrice();
                }
            },
        },
        'form.return_end_time': {
            handler() {
                if (this.pageLoadingCompleted) {
                    this.calculatePrice();
                }
            },
        },
        'form.total_traveling_price': {
            handler() {
                if (this.total_traveling_price) {
                    if (this.vehicleTypes) {
                        this.vehicleTypes.forEach((type) => {
                            if (type.id == this.form.vehicle_type_id && type.instant_price) {
                                if (this.total_traveling_price > 0 && type.min_price && this.total_traveling_price < type.min_price) {
                                    this.showMessage('The order cost is less than the minium order amount of the selected vehicle type. Please change it', 'warn', 5000);
                                }
                                if (this.total_traveling_price > 0 && type.max_price && this.total_traveling_price > type.max_price) {
                                    this.showMessage('The order cost is more than the maximum order amount of the selected vehicle type. Please change it', 'warn', 5000);
                                }
                            }
                        });
                    }
                }
            },
            deep: true,
        },

        'form.passangers': {
            handler() {
                if (this.form.vehicle_type_id) {
                    if (this.vehicleTypes) {
                        this.vehicleTypes.forEach((type) => {
                            if (type.id == this.form.vehicle_type_id) {
                                this.setFocus('vehicleType12');
                                this.$toast.removeAllGroups();
                                if (this.form.passangers > 0 && type.passengers && this.form.passangers > type.passengers) {
                                    this.showMessage('The selected vehicle is not fit for this number of passangers. Max limit is ' + type.passengers, 'warn', 5000);
                                }
                                if (this.form.luggage > 0 && type.luggage && this.form.luggage > type.luggage) {
                                    this.showMessage('The selected vehicle is not fit for this number of luggage. Max limit is ' + type.luggage, 'warn', 5000);
                                }
                            }
                        });
                    }
                }
            },
            deep: true,
        },
    },
};
</script>
 
